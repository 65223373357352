import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/outline";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import { axiosErr } from "@hilos/types/axios";
import { PublicUserInvitation } from "@hilos/types/private-schema";
import APIErrors from "src/components/base/APIErrors";
import { useInvitationsByUser } from "src/hooks/useUserInvitation";
import useHilosStore from "../../hooks/useHilosStore";
import { API_ROUTES, buildAPIRoute, buildRoute } from "../../router/router";
import Avatar from "../inbox/Avatar";
import AccountSelectionCodeModal from "./AccountSelectionCodeModal";

export default function AccountSelection() {
  const navigate = useNavigate();
  const invitations = useInvitationsByUser();
  const { session } = useHilosStore();
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});
  const [showCodeModal, setShowCodeModal] = useState(false);

  useEffect(() => {
    if (session && session.account) {
      navigate(buildRoute("dashboard"));
    }
  }, [session, navigate]);

  const handleRequestInvitationCode = async (invitationId) => {
    setBackendValidationErrors({});
    setBackendError("");
    setSubmitting(true);
    try {
      await axios.post(
        buildAPIRoute(API_ROUTES.PUBLIC_USER_REQUEST_INVITATION_CODE, {
          ":id": invitationId,
        })
      );
      setSuccess(true);
      setShowCodeModal(true);
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;
      Sentry.captureException(err);
      setSuccess(false);
      if (errorAxios?.response?.status === 400) {
        console.log("error", errorAxios);
        setBackendValidationErrors(errorAxios.response.data);
      } else {
        setBackendError("An error occurred, please try again.");
      }
    } finally {
      setSubmitted(true);
      setSubmitting(false);
      setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    }
  };

  return (
    <>
      <div className="border-top border-top-2 flex w-full h-full items-center justify-center border-hilos bg-gray-50">
        <div className="max-w-5xl h-full self-center">
          <div className="h-full overflow-y-scroll no-scrollbar flex">
            <div className="self-center">
              <APIErrors
                APIError={backendError}
                APIValidationErrors={backendValidationErrors}
                fieldTranslations={{}}
              />
              <div className="row text-center">
                <h1 className="mt-5 mb-1 text-2xl font-bold leading-7 text-gray-900">
                  <Trans i18nKey="settings:onboarding.welcome-to-hilos">
                    👋 Welcome to{" "}
                    <span className="bg-gradient-to-r from-hilos to-violet-400 bg-clip-text text-transparent">
                      hilos
                    </span>
                    !
                  </Trans>
                </h1>
                <p className="mb-5 text-sm text-gray-500">
                  {t(
                    "settings:onboarding.invitation",
                    "We found an invitation to join an existing team on Hilos."
                  )}
                </p>
              </div>

              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                {invitations.map((invitation: PublicUserInvitation) => (
                  <button
                    onClick={() => handleRequestInvitationCode(invitation.id)}
                    disabled={submitting}
                    type="button"
                    key={invitation.id}
                    className="text-left relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
                  >
                    <div className="flex-shrink-0">
                      <Avatar
                        name={invitation.account}
                        className="h-6 w-6 text-xs"
                      />
                    </div>
                    <div className="min-w-0 flex-1">
                      <p className="text-sm font-medium text-gray-900">
                        {t("settings:onboarding.join-team-1", "Join")}{" "}
                        <span className="font-medium">
                          {invitation.account}
                        </span>
                        {t("settings:onboarding.join-team-2", "'s team")}
                      </p>

                      <p className="truncate text-sm text-gray-500">
                        {t(
                          "settings:onboarding.join-team-invited-by",
                          "Invited by"
                        )}{" "}
                        {invitation.invited_by}
                      </p>
                    </div>
                  </button>
                ))}
                <div
                  key="new-account"
                  className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
                >
                  <div className="flex-shrink-0">
                    <PlusIcon className="h-5 w-5 mr-2" />
                  </div>
                  <div className="min-w-0 flex-1">
                    <Link
                      to={buildRoute("welcome")}
                      className="focus:outline-none"
                    >
                      <span className="absolute inset-0" aria-hidden="true" />
                      <p className="text-sm font-medium text-gray-900">
                        {t(
                          "settings:onboarding.create-account",
                          "Create new account"
                        )}
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AccountSelectionCodeModal
        show={showCodeModal}
        setShow={setShowCodeModal}
      />
    </>
  );
}
