import { useCallback, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { API_ROUTES, buildAPIRoute } from "src/router/router";
import { WhatsAppInfo } from "src/types/private-schema/models/WhatsAppInfo";
import { WhatsAppProfile } from "src/types/private-schema/models/WhatsAppProfile";

/**
 * Hook to retrieve and update WhatsApp business profile information for a channel
 * @param channelId The ID of the channel
 * @returns Object containing profile data, loading states, and update function
 */
export const useChannelProfile = (channelId: string) => {
  const queryClient = useQueryClient();
  const profileQueryKey = ["channel_profile", channelId];
  const infoQueryKey = ["channel_info", channelId];

  const fetchProfile = useCallback(async () => {
    const url = buildAPIRoute(API_ROUTES.CHANNEL_PROFILE_DETAIL, {
      ":id": channelId,
    });

    const { data } = await axios.get<WhatsAppProfile>(url, {
      headers: {
        "x-module": "channel",
      },
    });
    return data;
  }, [channelId]);

  const fetchInfo = useCallback(async () => {
    const url = buildAPIRoute(API_ROUTES.CHANNEL_INFO_DETAIL, {
      ":id": channelId,
    });

    const { data } = await axios.get<WhatsAppInfo>(url, {
      headers: {
        "x-module": "channel",
      },
    });
    return data;
  }, [channelId]);

  // Get the WhatsApp profile
  const {
    data: profile,
    isLoading: isLoadingProfile,
    isError: isProfileError,
    error: profileError,
    refetch: refetchProfile,
  } = useQuery<WhatsAppProfile>(profileQueryKey, fetchProfile, {
    enabled: !!channelId,
    retry: 1,
  });

  // Get the WhatsApp phone information
  const {
    data: info,
    isLoading: isLoadingInfo,
    isError: isInfoError,
    error: infoError,
    refetch: refetchInfo,
  } = useQuery<WhatsAppInfo>(infoQueryKey, fetchInfo, {
    enabled: !!channelId,
    retry: 1,
  });

  // Update the WhatsApp profile
  const {
    mutate: updateProfile,
    isLoading: isUpdating,
    isError: isUpdateError,
    error: updateError,
    isSuccess: isUpdateSuccess,
    reset: resetUpdateStatus,
  } = useMutation<WhatsAppProfile, Error, Partial<WhatsAppProfile>>(
    async (updatedProfile) => {
      const { data } = await axios.put<WhatsAppProfile>(
        buildAPIRoute(API_ROUTES.CHANNEL_PROFILE_DETAIL, {
          ":id": channelId,
        }),
        updatedProfile,
        {
          headers: {
            "x-module": "channel",
          },
        }
      );
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(profileQueryKey);
      },
    }
  );

  const isLoading = isLoadingProfile || isLoadingInfo;
  const isError = isProfileError || isInfoError;
  const error = profileError || infoError;

  const refetch = useCallback(() => {
    refetchProfile();
    refetchInfo();
  }, [refetchProfile, refetchInfo]);

  return {
    profileData: profile,
    infoData: info,
    isLoading,
    isError,
    error,
    refetch,
    updateProfile,
    isUpdating,
    isUpdateError,
    updateError,
    isUpdateSuccess,
    resetUpdateStatus,
  };
};

export default useChannelProfile;
