import { userAvatar } from "src/Helpers";

function FormatOptionLabelUser({ value, label, user }) {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <div className="mr-2">{userAvatar(user, "h-8 w-8")}</div>
        <div>
          <h4 className="font-medium">{label}</h4>
        </div>
      </div>
    </div>
  );
}

export default FormatOptionLabelUser;
