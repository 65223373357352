import { useTranslation } from "react-i18next";
import { GlobeAltIcon } from "@heroicons/react/outline";
import SwitchField from "src/components/Form/SwitchField";
import TextInputField from "src/components/Form/TextInputField";
import * as meta from "./Meta";

export default function ChannelProxyForm({ formik }) {
  const { t } = useTranslation();

  return (
    <div className="mt-10 sm:mt-4">
      <div className="md:grid md:grid-cols-1 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t("channel:advanced-configuration", "Advanced configuration")}
            </h3>
            <p className="mt-1 text-sm text-gray-600">
              {t(
                "channel:proxy-webhooks",
                "We can proxy new messages and message status callbacks to an additional webhook endpoint if you need to."
              )}
            </p>
          </div>
        </div>
        <div className="mt-5 md:col-span-1 md:mt-0 text-left">
          <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3">
                <SwitchField
                  label={t(meta.PROXY_FIELDS.proxy_requests.label)}
                  name={meta.PROXY_FIELDS.proxy_requests.key}
                  help={t(meta.PROXY_FIELDS.proxy_requests.help)}
                />
              </div>
              <div className="col-span-3">
                {formik.values && formik.values.proxy_requests && (
                  <TextInputField
                    placeholder={meta.PROXY_FIELDS.proxy_url.placeholder}
                    label={t(meta.PROXY_FIELDS.proxy_url.label)}
                    name={meta.PROXY_FIELDS.proxy_url.key}
                    help={meta.PROXY_FIELDS.proxy_url.help}
                    icon={GlobeAltIcon}
                    type="url"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
