import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useField } from "formik";
import { PaginatedResponse } from "@hilos/types/django/PaginatedResponse";
import { FlowListRead } from "@hilos/types/private-schema";
import SelectorField from "src/components/Form/SelectorField";
import useFlow from "src/hooks/useFlow";
import { API_ROUTES } from "src/router/router";

interface AvailableFlowSelectorProps {
  name: string;
  label: string;
  help: string;
  placeholder?: string;
}

export default function AvailableFlowSelector({
  name,
  label,
  help,
  placeholder = "",
  ...props
}: AvailableFlowSelectorProps) {
  const { t } = useTranslation();
  const [flowIdField] = useField<string | undefined>(name);
  const [currentFlowName] = useField<string | undefined>("name");
  const { flow } = useFlow(flowIdField?.value ?? "");
  const [flowsSearch, setFlows] = useState<FlowListRead[]>([]);

  const defaultOption = useMemo(() => {
    return [
      {
        value: flow?.id ?? "",
        label: flow?.name ?? "",
      },
      ...flowsSearch
        .filter((flowSearch) => currentFlowName.value !== flowSearch.name)
        .map((flowSearch) => ({
          value: flowSearch?.id ?? "",
          label: flowSearch?.name ?? "",
        })),
    ];
  }, [currentFlowName.value, flow?.id, flow?.name, flowsSearch]);

  const handleSearchFlow = useCallback(
    async (search: string) => {
      try {
        const { data } = await axios.get<PaginatedResponse<FlowListRead>>(
          API_ROUTES.FLOW_CREATE_LIST,
          {
            params: { page_size: 20, search },
          }
        );
        setFlows(data.results);
        if (data && data.results) {
          return data.results
            .filter((flow) => currentFlowName.value !== flow.name)
            .map((flow) => ({
              label: flow.name,
              value: flow.id,
            }));
        }
      } catch {}
      return [];
    },
    [currentFlowName.value]
  );

  return (
    <div className="flex-1 w-full">
      <SelectorField
        isClearable
        name={name}
        loadOptions={handleSearchFlow}
        options={defaultOption}
        label={label}
        help={help}
        placeholder={placeholder}
        noOptionsMessage={() => <>{t("type-to-search")}</>}
        {...props}
      />
    </div>
  );
}
