import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RadioGroup } from "@headlessui/react";
import {
  ChatAltIcon,
  DocumentSearchIcon,
  ViewGridAddIcon,
} from "@heroicons/react/outline";
import { useFormikContext } from "formik";
import { FlowData } from "@hilos/types/flow";
import MetaIcon from "src/components/icons/MetaIcon";
import {
  getTriggerVariables,
  getUpdatedVariablesWithExtraSources,
} from "src/helpers/variables";
import useHilosStore from "src/hooks/useHilosStore";
import { classNames } from "src/Helpers";
import { TriggerTypes } from "./constants/triggers";
import CampaignTrigger from "./triggers/CampaignTrigger";
import InboundMessageTrigger from "./triggers/InboundMessageTrigger";
import IntegrationsTrigger from "./triggers/IntegrationsTrigger";
import MarketingTrigger from "./triggers/MarketingTrigger";
import TriggerEmptyState from "./triggers/TriggerEmptyState";

function TriggerCategoryOption({ triggerCategory }) {
  const { t } = useTranslation();
  return (
    <RadioGroup.Option
      as={Fragment}
      key={triggerCategory.id}
      value={triggerCategory.id}
      aria-label={triggerCategory.id}
    >
      {({ active, checked }) => (
        <div
          className={classNames(
            active ? "border-hilos ring-2 ring-hilos" : "border-gray-300",
            "relative cursor-pointer rounded-lg bg-gray-50 hover:bg-gray-100 py-4 shadow-sm focus:outline-none flex"
          )}
        >
          <div className="flex-shrink-0 pl-2">
            <triggerCategory.icon
              className={classNames(
                checked ? "text-hilos" : "text-gray-400",
                "h-6 w-6 "
              )}
            />
          </div>
          <div className="text-left min-w-0 flex-1 ml-2 pr-2">
            <p
              className={classNames(
                checked ? "text-gray-900" : "text-gray-600",
                "text-sm font-medium truncate"
              )}
            >
              {t(triggerCategory.name)}
            </p>

            <p className="text-gray-500 text-sm">
              {t(triggerCategory.description)}
            </p>
          </div>

          <span
            className={classNames(
              checked ? "border-hilos" : "border-transparent",
              active ? "border" : "border-2",
              "pointer-events-none absolute -inset-px rounded-lg"
            )}
            aria-hidden="true"
          />
        </div>
      )}
    </RadioGroup.Option>
  );
}

const TRIGGER_CATEGORIES = [
  {
    id: "INBOUND MESSAGE",
    // i18n.t("flows:trigger.categories.inbound.title", "INBOUND MESSAGE")
    name: "flows:trigger.categories.inbound.title",
    // i18n.t("flows:trigger.categories.inbound.description", "Inbound message")
    description: "flows:trigger.categories.inbound.description",
    icon: ChatAltIcon,
    types: ["INBOUND_ANY_MESSAGE", "INBOUND_SPECIFIC_MESSAGE"],
  },
  {
    id: "OUTBOUND CAMPAIGNS",
    // i18n.t("flows:trigger.categories.outbound.title", "OUTBOUND CAMPAIGNS")
    name: "flows:trigger.categories.outbound.title",
    // i18n.t("flows:trigger.categories.outbound.description", "Outbound campaigns")
    description: "flows:trigger.categories.outbound.description",
    icon: DocumentSearchIcon,
    types: [
      "OUTBOUND_CAMPAIGN_CSV",
      "OUTBOUND_CAMPAIGN_SEGMENT",
      "OUTBOUND_ANY",
    ],
  },
  {
    id: "MARKETING",
    // i18n.t("flows:trigger.categories.marketing.title", "Marketing")
    name: "flows:trigger.categories.marketing.title",
    // i18n.t("flows:trigger.categories.marketing.description", "Meta Click 2 WhatsApp Ads")
    description: "flows:trigger.categories.marketing.description",
    icon: MetaIcon,
    types: ["META_C2WA"],
    feature_flag: "",
  },
  {
    id: "INTEGRATIONS",
    // i18n.t("flows:trigger.categories.integrations.title", "INTEGRATIONS")
    name: "flows:trigger.categories.integrations.title",
    // i18n.t("flows:trigger.categories.integrations.description", "Trigger from HubSpot, Zapier, etc")
    description: "flows:trigger.categories.integrations.description",
    icon: ViewGridAddIcon,
    types: [
      "INTEGRATIONS_HUBSPOT",
      "INTEGRATIONS_ZAPIER",
      "OUTBOUND_CAMPAIGN_API",
    ],
  },
];
export interface TriggerCategoryProps {
  handleTriggerTypeChange: (x: TriggerTypes) => void;
}

function FlowBuilderTrigger() {
  const { t } = useTranslation();
  const { session } = useHilosStore();
  const { values, setFieldValue } = useFormikContext<FlowData>();
  const [currentCategory, setCurrentCategory] = useState<string | null>();

  const handleTriggerCategoryChange = useCallback((nextCategory) => {
    setCurrentCategory(nextCategory);
  }, []);

  useEffect(() => {
    if (values.trigger_type) {
      TRIGGER_CATEGORIES.forEach((triggerCategory) => {
        if (
          triggerCategory.types.find((t) => t === values.trigger_type) !==
          undefined
        ) {
          setCurrentCategory(triggerCategory.id);
        }
      });
    }
  }, [values.trigger_type]);

  const handleTriggerChange = useCallback(
    (nextTriggerType: TriggerTypes, nextTriggerConfig: any) => {
      // Now add new variable fields depending on trigger_type
      const triggerVariables = getTriggerVariables(
        nextTriggerType,
        nextTriggerConfig
      );
      setFieldValue("trigger_type", nextTriggerType);
      setFieldValue("trigger_config", nextTriggerConfig);
      setFieldValue("flow_execution_variables", []);
      setFieldValue(
        "variables",
        getUpdatedVariablesWithExtraSources({
          triggerVariables,
          currentVariables: values.variables,
        })
      );
    },
    [setFieldValue, values.variables]
  );

  const handleTriggerTypeChange = useCallback(
    (nextTriggerType: TriggerTypes) => {
      handleTriggerChange(
        nextTriggerType,
        nextTriggerType === "INBOUND_SPECIFIC_MESSAGE"
          ? {
              message_content_to_match: "Text to match",
              match_exact_message_content: true,
              has_variables: false,
            }
          : {}
      );
    },
    [handleTriggerChange]
  );

  const handleTriggerConfigChange = useCallback(
    (nextTriggerConfig: any = {}) => {
      handleTriggerChange(values.trigger_type, {
        ...values.trigger_config,
        ...nextTriggerConfig,
      });
    },
    [handleTriggerChange, values.trigger_type, values.trigger_config]
  );

  return (
    <div className="overflow-y-auto w-full h-full flex">
      <aside className="w-80 flex-shrink-0 h-full">
        <div className="px-6 pb-4 pt-6">
          <h2
            className={classNames(
              currentCategory ? "text-gray-400" : "text-gray-900",
              "text-lg font-medium "
            )}
          >
            {t("flows:trigger.types-title", "Trigger types")}
          </h2>
          <p
            className={classNames(
              currentCategory ? "text-gray-400" : "text-gray-600",
              "mt-1 text-sm "
            )}
          >
            {t(
              "flows:trigger.types-description",
              "Select and configure how your flow will run"
            )}
          </p>
        </div>

        <nav className="min-h-0 flex-1 h-full px-4 py-4" aria-label="Directory">
          <RadioGroup
            value={currentCategory}
            onChange={handleTriggerCategoryChange}
            className="space-y-4"
          >
            {TRIGGER_CATEGORIES.map((triggerCategory) => (
              <>
                {triggerCategory.feature_flag ? (
                  <>
                    {session?.account.feature_flags.includes(
                      triggerCategory.feature_flag
                    ) && (
                      <TriggerCategoryOption
                        triggerCategory={triggerCategory}
                      />
                    )}
                  </>
                ) : (
                  <TriggerCategoryOption triggerCategory={triggerCategory} />
                )}
              </>
            ))}
          </RadioGroup>
        </nav>
      </aside>
      <main className="flex-1 h-full">
        <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
          <div className="gap-x-4 overflow-y-auto pb-4 pt-6">
            <div className="pl-6 pr-14">
              {currentCategory ? (
                <>
                  {currentCategory === "INBOUND MESSAGE" && (
                    <InboundMessageTrigger
                      handleTriggerTypeChange={handleTriggerTypeChange}
                      handleTriggerConfigChange={handleTriggerConfigChange}
                    />
                  )}
                  {currentCategory === "OUTBOUND CAMPAIGNS" && (
                    <CampaignTrigger
                      handleTriggerTypeChange={handleTriggerTypeChange}
                    />
                  )}
                  {currentCategory === "MARKETING" && (
                    <MarketingTrigger
                      handleTriggerTypeChange={handleTriggerTypeChange}
                    />
                  )}
                  {currentCategory === "INTEGRATIONS" && (
                    <IntegrationsTrigger
                      handleTriggerTypeChange={handleTriggerTypeChange}
                    />
                  )}
                </>
              ) : (
                <TriggerEmptyState />
              )}
            </div>
            {/* <div className="col-span-2">
              {currentCategory && <TriggerHelp />}
            </div> */}
          </div>
        </div>
      </main>
    </div>
  );
}

export default FlowBuilderTrigger;
