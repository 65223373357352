import { useTranslation } from "react-i18next";
import { FlowStepEdit } from "@hilos/types/private-schema";

interface FlowStepActiveCampaignContactUpsertDetailProps {
  step: FlowStepEdit;
}

export default function FlowStepActiveCampaignContactUpsertDetail({
  step,
}: FlowStepActiveCampaignContactUpsertDetailProps) {
  const { t } = useTranslation();

  return (
    <div className="space-y-4">
      <div>
        <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
          {t(
            "flow-executions:steps-detail.contact-update.basic-properties",
            "Basic properties to update"
          )}
        </h3>

        <ul>
          {step.firstname && (
            <li key="firstname">
              <dl className="flex space-x-2">
                <dt>{t("first-name")}:</dt>
                <dd className="grow text-gray-900 font-medium">
                  {step.firstname}
                </dd>
              </dl>
            </li>
          )}
          {step.lastname && (
            <li key="lastname">
              <dl className="flex space-x-2">
                <dt>{t("last-name")}:</dt>
                <dd className="grow text-gray-900 font-medium">
                  {step.lastname}
                </dd>
              </dl>
            </li>
          )}
          {step.email && (
            <li key="email">
              <dl className="flex space-x-2">
                <dt>{t("email")}:</dt>
                <dd className="grow text-gray-900 font-medium">{step.email}</dd>
              </dl>
            </li>
          )}
        </ul>
      </div>

      <div>
        <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
          {t(
            "flow-executions:steps-detail.contact-update.custom-properties",
            "Custom attributes to update"
          )}
        </h3>

        <ul>
          {step.contact_custom_properties?.map((customProp, idx) => (
            <li key={idx}>
              <dl className="flex space-x-2">
                <dt>{customProp.key}:</dt>
                <dd className="grow text-gray-900 font-medium">
                  {customProp.value}
                </dd>
              </dl>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
