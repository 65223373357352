import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import SelectorField from "src/components/Form/SelectorField";
import useActiveCampaignAppConfig from "src/hooks/useActiveCampaignAppConfig";
import { useActiveCampaignContactFields } from "src/hooks/useActiveCampaignContactFields";
import ActiveCampaignCTAInstallApp from "./ActiveCampaignCTAInstallApp";

interface FlowBuilderStepActiveCampaignContactGetProps {
  index: number;
}

function FlowBuilderStepActiveCampaignContactGet({
  index,
}: FlowBuilderStepActiveCampaignContactGetProps) {
  const { t } = useTranslation();
  const { data } = useActiveCampaignContactFields();
  const { data: activeCampaignSettings } = useActiveCampaignAppConfig();

  const activeCampaignContactFieldsOptions = useMemo(
    () =>
      data
        .map((property) => ({
          label: property.label,
          // Doing this so we can later extract the property label to use as a
          // variable so they're easier to use
          value: JSON.stringify({ id: property.name, label: property.label }),
        }))
        .sort((a, b) => a.label.length - b.label.length),
    [data]
  );

  return (
    <div className="mt-2">
      {!activeCampaignSettings?.api_key_ro && (
        <div className="my-4">
          <ActiveCampaignCTAInstallApp />
        </div>
      )}
      <SelectorField
        name={`steps.${index}.ac_custom_fields`}
        label={t(
          "flows:steps.activecampaign-contact-retreive.custom-properties.label",
          "Custom properties"
        )}
        help={t(
          "flows:steps.activecampaign-contact-retreive.custom-properties.help",
          "Select the custom properties you want to retrieve from ActiveCampaign"
        )}
        options={activeCampaignContactFieldsOptions}
        isMulti={true}
      />
    </div>
  );
}

export default FlowBuilderStepActiveCampaignContactGet;
