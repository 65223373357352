import { SVGProps } from "react";

function HilosIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 346 425"
      fill="currentColor"
      stroke="none"
      className="scale-110"
      {...props}
    >
      <path
        opacity="1"
        d="
M210.000458,304.026611 
	C209.999008,300.531891 210.031616,297.531494 209.987747,294.532196 
	C209.929688,290.562012 207.413544,288.733032 203.924973,288.027924 
	C192.422989,285.703003 180.922974,283.359131 169.384491,281.226685 
	C159.448822,279.390442 149.461548,277.833527 138.999542,276.069183 
	C138.999542,283.189667 138.999496,290.161255 138.999557,297.132843 
	C138.999649,308.965820 139.326630,320.809998 138.920456,332.628998 
	C138.363876,348.824371 132.626236,362.603119 119.052391,372.525818 
	C99.512581,386.809784 80.419937,401.724304 60.584652,415.579773 
	C42.349064,428.317810 11.669899,420.655182 7.966865,394.278564 
	C7.580593,391.527161 7.045173,388.758575 7.041287,385.997314 
	C6.982191,343.998688 7.049676,301.999817 6.972957,260.001251 
	C6.946792,245.677704 15.917347,230.316605 31.519455,227.026657 
	C37.371487,225.792664 43.787025,225.547745 49.678154,226.502457 
	C66.406204,229.213440 82.995262,232.781082 99.641602,235.998215 
	C102.834633,236.615311 106.039307,237.172226 110.000000,237.896057 
	C110.000000,233.911102 110.000015,230.021347 109.999985,226.131592 
	C109.999916,216.465210 109.904877,206.797409 110.051567,197.133255 
	C110.102493,193.778595 109.387001,192.476959 105.539711,191.803711 
	C86.912712,188.544144 68.378998,184.744614 49.832676,181.034210 
	C42.190254,179.505249 34.402527,178.322189 27.028969,175.915421 
	C14.777383,171.916397 7.538675,159.227020 7.157367,147.429398 
	C6.565457,129.115753 6.477735,110.749298 7.163177,92.442177 
	C7.735495,77.156448 14.213655,64.514610 27.072109,55.305317 
	C47.137070,40.934696 66.814034,26.022305 86.880173,11.653374 
	C97.610466,3.969640 115.267151,5.113915 125.669563,13.100608 
	C134.933182,20.212961 138.956451,29.675035 138.974396,41.010178 
	C139.039825,82.340813 139.000259,123.671608 139.000259,165.674606 
	C148.163956,167.445099 157.367462,169.216995 166.568344,171.002548 
	C178.252899,173.270096 189.946777,175.491837 201.613892,177.846085 
	C207.657913,179.065689 209.981461,177.344650 209.989517,171.070999 
	C210.020767,146.738449 209.796356,122.403297 210.093658,98.074341 
	C210.230804,86.851303 212.731628,76.090858 219.140289,66.476936 
	C224.504028,58.430538 232.533188,53.540958 240.035675,48.003937 
	C255.688461,36.451820 271.665741,25.317595 286.979675,13.334300 
	C302.209442,1.416891 332.519257,5.584587 338.444946,26.368141 
	C339.955292,31.665514 341.839264,37.141304 341.850769,42.538754 
	C342.056641,139.035385 342.040527,235.532623 341.961884,332.029633 
	C341.948273,348.731964 335.928741,362.590027 321.941559,372.657349 
	C301.909241,387.075623 282.211090,401.959076 262.143433,416.327118 
	C251.384445,424.030304 233.754440,422.915588 223.354080,414.914917 
	C214.113785,407.806671 210.047729,398.373199 210.021088,387.016876 
	C209.956619,359.518524 210.000336,332.019958 210.000458,304.026611 
M312.992157,46.021084 
	C312.653381,44.769375 312.386902,43.492558 311.962738,42.270489 
	C310.476227,37.987652 308.885468,37.590710 305.143585,40.319298 
	C288.251190,52.637360 271.538757,65.217209 254.350830,77.109245 
	C244.191559,84.138275 239.409271,92.696793 239.795380,105.364998 
	C240.510635,128.831650 240.337082,152.341934 239.901108,175.822296 
	C239.501129,197.364334 223.595367,213.212753 200.442551,209.775787 
	C179.998886,206.740997 159.784851,202.159180 139.018311,198.173859 
	C139.018311,212.584991 138.955399,226.871521 139.158051,241.154297 
	C139.171738,242.119949 141.060028,243.642426 142.291992,243.908676 
	C148.347504,245.217422 154.488876,246.121353 160.576004,247.292633 
	C177.216675,250.494629 193.762756,254.302429 210.501923,256.846313 
	C227.319397,259.402100 239.411102,274.791473 239.146576,290.148712 
	C238.641708,319.458954 238.999832,348.784027 239.000580,378.102966 
	C239.000610,379.435608 238.618683,380.930939 239.092697,382.067810 
	C240.158768,384.624695 241.284607,387.387177 243.198029,389.216919 
	C243.757019,389.751495 247.242813,387.685303 249.065536,386.366730 
	C265.743622,374.301422 282.160004,361.863953 299.053406,350.110931 
	C308.489227,343.546295 313.121094,335.428131 313.098785,323.793640 
	C312.922028,231.505966 312.999664,139.217819 312.992157,46.021084 
M36.000008,350.500000 
	C36.000072,360.657166 35.893913,370.816315 36.085087,380.969879 
	C36.125912,383.138245 37.027325,385.382904 37.924927,387.419312 
	C39.050449,389.972778 40.514088,390.428070 43.158989,388.472748 
	C59.829243,376.148712 76.572395,363.918427 93.474785,351.915314 
	C101.984856,345.871948 109.788422,339.424347 109.926651,327.966675 
	C110.157387,308.841431 109.989632,289.711365 109.989632,270.456573 
	C107.690056,269.931610 105.666321,269.407501 103.617798,269.011658 
	C84.903351,265.395325 66.178719,261.831390 47.469585,258.187836 
	C38.278526,256.397888 35.881214,259.643677 35.932350,267.577148 
	C36.108356,294.883789 36.000011,322.192230 36.000008,350.500000 
M44.642357,82.074890 
	C38.653233,86.594543 36.304718,93.077019 36.106308,100.106796 
	C35.739876,113.089584 35.966698,126.090248 36.019604,139.083160 
	C36.039284,143.916473 38.227413,147.038193 43.262600,147.977249 
	C62.653343,151.593567 82.025146,155.311340 101.412651,158.945190 
	C104.079437,159.445038 106.794861,159.685471 109.658806,160.068909 
	C109.812737,158.947556 109.977180,158.307678 109.977455,157.667725 
	C109.993248,120.853867 110.000710,84.040001 109.973656,47.226166 
	C109.972839,46.109295 109.704674,44.960602 109.379997,43.881229 
	C107.386200,37.252804 105.936333,36.872852 100.482254,40.993969 
	C90.048347,48.877853 79.528801,56.648640 69.027504,64.443047 
	C61.150181,70.289848 53.245770,76.100159 44.642357,82.074890 
z"
      />
    </svg>
  );
}

export default HilosIcon;
