import { ReactNode } from "react";
import { GroupBase } from "react-select";
import { ShareIcon } from "lucide-react";

function FormatGroupLabelVariable<Option>({
  // @ts-ignore
  icon: RenderIcon,
  label,
}: GroupBase<Option>): ReactNode {
  return (
    <div className="flex flex-nowrap py-1">
      <div className="rounded-full bg-hilos-light p-1">
        <RenderIcon
          className={`aria-hidden="true" h-4 w-4 text-hilos ${
            RenderIcon === ShareIcon ? "rotate-90" : ""
          }`}
        />
      </div>
      <div className="ml-2 self-center text-sm capitalize text-neutral-light">
        {label}
      </div>
    </div>
  );
}

export default FormatGroupLabelVariable;
