import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { WABAFlowDetail } from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "../router/router";

function useWhatsAppFlow(id: string | null) {
  const fetchWhatsAppFlow = useCallback(async () => {
    if (!id) return undefined;

    const { data } = await axios.get<WABAFlowDetail>(
      buildAPIRoute(API_ROUTES.WHATSAPP_FLOW_DETAIL, {
        ":id": id,
      })
    );
    return data;
  }, [id]);

  const { data } = useQuery(["waba_flow", id], fetchWhatsAppFlow);

  return data;
}

export default useWhatsAppFlow;
