import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import { FlowStepEdit } from "@hilos/types/private-schema";
import useWorkflowState from "src/hooks/useWorkFlowState";
import useWorkflow from "src/hooks/useWorkflow";
import { buildRoute } from "src/router/router";

interface FlowStepWorkflowTaskDetailProps {
  step: FlowStepEdit;
}

export default function FlowStepWorkflowTaskDetail({
  step,
}: FlowStepWorkflowTaskDetailProps) {
  const { t } = useTranslation();
  const workflow = useWorkflow(step.workflow);
  const state = useWorkflowState(step.state_to_move_to);

  if (!workflow || !state) {
    return null;
  }

  return (
    <div>
      <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
        {t(
          "flow-executions:steps-detail.workflow-task.settings",
          "CRM Workflow & state settings"
        )}
      </h3>

      <div className="flex">
        {t(
          "flow-executions:steps-detail.workflow-task.title",
          "In CRM Workflow"
        )}
        <Link
          to={buildRoute("workflow-board", { id: step.workflow })}
          target="_blank"
          className="flex items-center text-blue-500 font-medium ml-1"
        >
          {workflow?.name}
          <ExternalLinkIcon className="h-4 w-4 ml-1" />
        </Link>
        {t(
          "flow-executions:steps-detail.workflow-task.instructions",
          ", create a {{custom_name_for_task}} or move it to {{state_name}}",
          {
            custom_name_for_task: workflow?.custom_name_for_task,
            state_name: state.name,
          }
        )}
      </div>
    </div>
  );
}
