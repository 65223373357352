import { useContext } from "react";
import { createContext } from "react";
import type { CleanupFn } from "@atlaskit/pragmatic-drag-and-drop/types";
import { StoreApi, createStore, useStore } from "zustand";
import {
  WorkflowRead,
  WorkflowStateRead,
  WorkflowTaskRead,
} from "@hilos/types/private-schema";
import {
  TasksByState,
  getColumnsOfStates,
  getTasksByState,
  moveCard,
  updateFilters,
  updateSortBy,
  updateState,
  updateTask,
} from "./actions";
import { MoveCardParams } from "./board/Board";

export interface WorkflowTaskItem extends WorkflowTaskRead {
  updating?: boolean;
}

export type CardEntry = {
  element: HTMLElement;
};

export type ColumnEntry = {
  element: HTMLElement;
};

export interface WorkflowBoardFilters {
  search: string;
  assigned: number[];
  channels: number[];
}

export interface WorkflowBoardStore {
  instanceId: symbol;
  name: string;
  states: WorkflowStateRead[];
  tasks: Map<string, WorkflowTaskItem>;
  tasksByState: TasksByState;
  columns: string[];
  filters: WorkflowBoardFilters;
  sortBy: string;
  onMoveCard: (params: MoveCardParams) => void;
  onUpdateTask: (task: WorkflowTaskRead) => void;
  onUpdateState: (state: WorkflowStateRead) => void;
  onUpdateColumns: (columnIds: string[]) => void;
  onUpdateFilters: (filters: Partial<WorkflowBoardFilters>) => void;
  onUpdateSortBy: (sortBy: string) => void;
  registerCard: (args: { cardId: string; entry: CardEntry }) => CleanupFn;
  registerColumn: (args: { columnId: string; entry: ColumnEntry }) => CleanupFn;
}

export const WorkflowBoardContext =
  createContext<StoreApi<WorkflowBoardStore> | null>(null);

export const createWorkflowBoardStore = (
  workflowId: string,
  workflow: WorkflowRead,
  tasks: WorkflowTaskRead[]
) => {
  const states = workflow.states;
  const instanceId = Symbol("instance-id");
  const boardCards = new Map<string, CardEntry>();
  const boardColumns = new Map<string, ColumnEntry>();
  const columns = getColumnsOfStates(states);
  const tasksByState = getTasksByState(states);

  for (const task of tasks) {
    if (task.state && tasksByState[task.state]) {
      tasksByState[task.state].set(task.id, task);
    }
  }

  const registerCard = ({
    cardId,
    entry,
  }: {
    cardId: string;
    entry: CardEntry;
  }): CleanupFn => {
    boardCards.set(cardId, entry);
    return function cleanup() {
      boardCards.delete(cardId);
    };
  };

  const registerColumn = ({
    columnId,
    entry,
  }: {
    columnId: string;
    entry: ColumnEntry;
  }): CleanupFn => {
    boardColumns.set(columnId, entry);
    return function cleanup() {
      boardCards.delete(columnId);
    };
  };

  return createStore<WorkflowBoardStore>((set, get) => ({
    instanceId,
    name: workflow.name,
    states,
    tasks: new Map(tasks.map((task) => [task.id, task])),
    tasksByState,
    columns,
    filters: {
      search: "",
      assigned: [],
      channels: [],
    },
    sortBy: "last_state_updated_on",
    onMoveCard: (params: MoveCardParams) =>
      moveCard({ set, get, workflowId, params }),
    onUpdateTask: (task: WorkflowTaskRead) =>
      updateTask({ set, get, workflowId, task }),
    onUpdateState: (state: WorkflowStateRead) =>
      updateState({ set, get, workflowId, state }),
    onUpdateColumns: (columns: string[]) => set({ columns }),
    onUpdateFilters: (filters: Partial<WorkflowBoardFilters>) =>
      updateFilters({ set, get, workflowId, filters }),
    onUpdateSortBy: (sortBy: string) =>
      updateSortBy({ set, get, workflowId, sortBy }),
    registerCard,
    registerColumn,
  }));
};

export function useWorkflowBoardContext<T>(
  selector: (state: WorkflowBoardStore) => T = (state) => state as T
): T {
  const store = useContext(WorkflowBoardContext);
  if (!store)
    throw new Error("Missing WorkflowBoardContext.Provider in the tree");
  return useStore(store, selector);
}
