import useHilosStore from "src/hooks/useHilosStore";
import { getTranslationPayload as t } from "src/i18n";
import { AccountStatus } from "src/types/account";

interface FeatureSupportLink {
  [key: string]: {
    helpItem: string[];
    supportLink: string;
  };
}

interface VideoSupportLink {
  [key: string]: {
    videoTitle: string[];
    videoLink: string;
    condition?: () => boolean;
  }[];
}

function checkSession() {
  const { session } = useHilosStore.getState();
  return session;
}

export const videoSupportLinks: VideoSupportLink[] = [
  {
    "": [
      {
        videoTitle: t(
          "support:videos.root.sandbox.welcome",
          "Sandbox Tutorial: Welcome to Hilos"
        ),
        videoLink: "https://youtu.be/jADmk220Stk",
        condition: () => {
          return checkSession()?.account?.status
            ? checkSession()?.account?.status === AccountStatus.IN_SANDBOX
            : true;
        },
      },
      {
        videoTitle: t(
          "support:videos.root.sandbox.invite-team",
          "Sandbox Tutorial: Invite your team"
        ),
        videoLink: "https://youtu.be/qjlmGiKQUSU",
        condition: () => {
          return checkSession()?.account?.status
            ? checkSession()?.account?.status === AccountStatus.IN_SANDBOX
            : true;
        },
      },
    ],
  },
  {
    template: [
      {
        videoTitle: t(
          "support:videos.template.create-template",
          "Create your first Template"
        ),
        videoLink: "https://youtu.be/0Gi2JyV5LCA",
      },
    ],
  },
  {
    inbox: [
      {
        videoTitle: t(
          "support:videos.inbox.start-conversation",
          "Start your first conversation"
        ),
        videoLink: "https://youtu.be/WrVeS-7AKeI",
      },
      {
        videoTitle: t(
          "support:videos.inbox.conversation-window",
          "Understand the conversation window"
        ),
        videoLink: "https://youtu.be/xOA-_2BF8Is",
      },
      {
        videoTitle: t(
          "support:videos.inbox.understanding-tags",
          "Learn how to use conversation and contact tags"
        ),
        videoLink: "https://youtu.be/0Gi2JyV5LCA",
      },
      {
        videoTitle: t(
          "support:videos.inbox.organize-with-views",
          "Organize your inbox with views"
        ),
        videoLink: "https://youtu.be/N24z2jEzpcY",
      },
    ],
  },
  {
    "config/team-list": [
      {
        videoTitle: t(
          "support:videos.team-list.create-team",
          "Create your first team"
        ),
        videoLink: "https://youtu.be/ihsDvZiLDxc",
      },
    ],
  },
  {
    "config/account/hours": [
      {
        videoTitle: t(
          "support:videos.account-hours.set-working-hours",
          "Set your working hours"
        ),
        videoLink: "https://youtu.be/Rl8jKNkn1sI",
      },
    ],
  },
  {
    "config/account-members": [
      {
        videoTitle: t(
          "support:videos.account-members.invite-team",
          "Invite your team"
        ),
        videoLink: "https://youtu.be/fDJ9BouSfes",
      },
    ],
  },
  {
    "config/channel": [
      {
        videoTitle: t(
          "support:videos.channel.customize-profile",
          "Customize you WA Profile"
        ),
        videoLink: "https://youtu.be/Zu-fSTdXXEk",
      },
      {
        videoTitle: t(
          "support:videos.channel.payment-method",
          "Set up your WA Payment Method"
        ),
        videoLink: "https://youtu.be/HLqQx_q5lGo",
      },
    ],
  },
  {
    "config/channel/cloud-api/onboarding": [
      {
        videoTitle: t(
          "support:videos.channel.choose-a-number",
          "Choose a Number"
        ),
        videoLink: "https://youtu.be/Sw9hhGCIjFQ",
      },
      {
        videoTitle: t(
          "support:videos.channel.connect-whatsapp-business",
          "Connect a WhatsApp Business number"
        ),
        videoLink: "https://youtu.be/T6FU1iFTgDc",
      },
      {
        videoTitle: t(
          "support:videos.channel.migrate-from-bsp",
          "Migrate from another BSP"
        ),
        videoLink: "https://youtu.be/UcaLsNRH96Q",
      },
    ],
  },
];

export const featureSupportLinks: FeatureSupportLink[] = [
  {
    "config/channel": {
      helpItem: t("support:guides.channels", "Channel"),
      supportLink:
        "https://hilos.io/docs/en/user/using-hilos/channels/creating-a-channel",
    },
  },
  {
    "config/account-members": {
      helpItem: t("support:guides.members", "Team member"),
      supportLink:
        "https://hilos.io/docs/en/user/getting-started/inviting-your-team",
    },
  },
  {
    reports: {
      helpItem: t("support:guides.reports", "Reports"),
      supportLink:
        "https://hilos.io/docs/en/user/using-hilos/reports/inbox-reports",
    },
  },
  {
    "dev/external-apps": {
      helpItem: t("support:guides.integrations", "Integrations"),
      supportLink:
        "https://hilos.io/docs/en/user/using-hilos/integrations/HubSpot/hubspot",
    },
  },
  {
    "dev/api-keys": {
      helpItem: t("support:guides.developer", "Developer"),
      supportLink: "https://hilos.io/docs/developer/getting-started/auth",
    },
  },
  {
    broadcasts: {
      helpItem: t("support:guides.broadcasts", "Broadcasts"),
      supportLink:
        "https://hilos.io/docs/en/user/using-hilos/broadcasts/creating-a-broadcast",
    },
  },
  {
    template: {
      helpItem: t("support:guides.templates", "Templates"),
      supportLink:
        "https://hilos.io/docs/en/user/using-hilos/templates/templates",
    },
  },
  {
    contact: {
      helpItem: t("support:guides.contacts", "Contacts"),
      supportLink:
        "https://hilos.io/docs/en/user/using-hilos/contacts/creating-a-contact",
    },
  },
  {
    inbox: {
      helpItem: t("support:guides.inbox", "Inbox"),
      supportLink:
        "https://hilos.io/docs/en/user/using-hilos/inbox/starting-a-conversation",
    },
  },
  {
    flow: {
      helpItem: t("support:guides.flows", "Flows"),
      supportLink:
        "https://hilos.io/docs/en/user/using-hilos/flows/what-are-flows",
    },
  },
  {
    "config/team-list": {
      helpItem: t("support:guides.team", "Team"),
      supportLink:
        "https://hilos.io/docs/en/user/using-hilos/inbox/inbox-teams#what-are-teams",
    },
  },
];
