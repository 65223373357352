import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ArrowSmRightIcon, MailIcon, TagIcon } from "@heroicons/react/outline";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { Formik } from "formik";
import * as yup from "yup";
import { StripeCheckoutSession } from "@hilos/types/billing";
import { StripeProductAvailableList } from "@hilos/types/private-schema";
import TextInputField from "src/components/Form/TextInputField";
import StateButton from "src/components/StateButton";
import APIErrors from "src/components/base/APIErrors";
import useCleanTimeout from "src/hooks/useCleanTimeout";
import useHilosStore from "src/hooks/useHilosStore";
import { formatPrice } from "src/Helpers";
import { ERROR_MESSAGES } from "src/constants/errors";
import { API_ROUTES } from "src/router/router";
import { axiosErr } from "src/types/axios";

interface SubscriptionActionProps {
  product: StripeProductAvailableList;
}

export default function SubscriptionAction({
  product,
}: SubscriptionActionProps) {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});
  const timeout = useCleanTimeout();
  const { session } = useHilosStore();

  const initialValues = {
    promotion_code: "",
    product: product.id,
    billing_email: session?.account.billing_email || "",
  };

  const FIELDS = {
    promotion_code: {
      key: "promotion_code",
      label: t(
        "settings:billing.checkout.promotion_code.label",
        "Promotion Code"
      ),
      optional: true,
      placeholder: t(
        "settings:billing.checkout.promotion_code.placeholder",
        "MYPROMOCODE"
      ),
    },
    billing_email: {
      key: "billing_email",
      label: t(
        "settings:billing.checkout.billing_email.label",
        "Billing Email"
      ),
      help: t(
        "settings:billing.checkout.billing_email.help",
        "The email you'd like to receive all your invoices at."
      ),
    },
  };

  const onSubmit = async (formData, setSubmitting) => {
    setBackendValidationErrors({});
    setBackendError("");
    setSubmitting(true);

    //@ts-ignore
    const stripeKey: string =
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_PROD_STRIPE_PUBLIC_KEY
        : process.env.REACT_APP_DEV_STRIPE_PUBLIC_KEY;
    const stripe = await loadStripe(stripeKey);
    if (!stripe) {
      return;
    }

    try {
      const response = await axios.post<StripeCheckoutSession>(
        API_ROUTES.STRIPE_CHECKOUT_SESSION_CREATE,
        formData
      );
      setSubmitting(false);
      setSuccess(true);

      if (!response.data.sessionId) {
        setBackendError(
          t(
            "settings:billing.error-stripe",
            "Couldn't start a Stripe secure session, please try again later."
          )
        );
        return;
      }
      setSuccess(true);
      stripe
        .redirectToCheckout({
          sessionId: response.data.sessionId,
        })
        .then((res) => {
          console.log(res);
        });
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;
      setSuccess(false);
      if (errorAxios?.response?.status === 400) {
        console.log("error", errorAxios);
        setBackendValidationErrors(errorAxios.response.data);
      } else {
        setBackendError(
          t(
            "settings:billing.error-stripe",
            "Couldn't start a Stripe secure session, please try again later."
          )
        );
      }
      timeout.current = setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setSubmitted(true);
    }
  };

  return (
    <>
      <p className="text-lg font-medium leading-6 text-gray-900">
        {t("start-from", "Start from")}
      </p>
      <div className="mt-2 flex items-end justify-center text-5xl font-bold tracking-tight text-gray-900">
        <span className="text-hilos">
          {formatPrice(
            product.available_price &&
              (product.available_price.unit_amount || 0) / 100
          )}
        </span>
      </div>
      <div className="mt-2 flex items-end justify-center text-5xl font-bold tracking-tight text-gray-900">
        <span className="ml-2 text-xl font-medium tracking-normal text-gray-500">
          {product.available_price?.currency?.toLocaleUpperCase()}
          {t("per-month", "/month")}
        </span>
      </div>
      <p className="whitespace mt-4 text-sm">
        <Trans i18nKey="settings:terms-and-conditions">
          <a
            href="https://drive.google.com/file/d/1FwjBwzTQEFdOgcY7w-MbwXl_bdvCtBU-/view"
            rel="noreferrer"
            target="_blank"
            className="font-medium text-gray-500 underline"
          >
            Learn about our terms & conditions
          </a>{" "}
          and our{" "}
          <a
            href="https://drive.google.com/file/d/1qg1SsYcMw265LRGE7UsE24zZl-kxFksm/view"
            target="_blank"
            rel="noreferrer"
            className="font-medium text-gray-500 underline"
          >
            privacy policy
          </a>
          .
        </Trans>
      </p>
      <div className="mt-6">
        <Formik
          validationSchema={yup.object().shape({
            promotion_code: yup.string(),
            billing_email: yup
              .string()
              .email(t(ERROR_MESSAGES.INVALID_EMAIL))
              .required(t(ERROR_MESSAGES.REQUIRED)),
          })}
          onSubmit={(values, { setSubmitting }) =>
            onSubmit(values, setSubmitting)
          }
          initialValues={initialValues}
        >
          {(formik) => (
            <form
              noValidate
              onSubmit={formik.handleSubmit}
              className="space-y-4"
            >
              <APIErrors
                APIError={backendError}
                APIValidationErrors={backendValidationErrors}
                fieldTranslations={FIELDS}
              />

              <div className="space-y-2 text-left">
                <TextInputField
                  label={t(
                    FIELDS.billing_email.label,
                    FIELDS.billing_email.label
                  )}
                  help={t(FIELDS.billing_email.help, FIELDS.billing_email.help)}
                  name={FIELDS.billing_email.key}
                  type="email"
                  icon={MailIcon}
                />

                <TextInputField
                  placeholder={t(
                    FIELDS.promotion_code.placeholder,
                    FIELDS.promotion_code.placeholder
                  )}
                  label={t(
                    FIELDS.promotion_code.label,
                    FIELDS.promotion_code.label
                  )}
                  name={FIELDS.promotion_code.key}
                  optional={FIELDS.promotion_code.optional}
                  type="text"
                  icon={TagIcon}
                />
              </div>

              <StateButton
                isSubmitting={formik.isSubmitting}
                submitted={submitted}
                success={success}
                disabled={formik.isSubmitting}
                submittingText={t("processing")}
                successText={t("redirecting", "Redirecting...")}
                btnClasses="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-violet-400 px-5 py-3 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
                btnClassesSuccess="inline-flex items-center justify-center rounded-md border border-transparent bg-green-100 px-5 py-3 text-base font-medium text-green-800 shadow-sm hover:bg-green-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                btnClassesError="inline-flex items-center justify-center rounded-md border border-transparent bg-yellow-100 px-5 py-3 text-base font-medium text-yellow-800 shadow-sm hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2"
                initialText={
                  <>
                    {t("settings:billing.start-subscription")}
                    <ArrowSmRightIcon
                      className="ml-1 h-5 w-5"
                      aria-hidden="true"
                    />
                  </>
                }
              />
            </form>
          )}
        </Formik>
      </div>
      <div className="mt-4 text-sm">
        <Trans i18nKey="settings:cancel-anytime">
          <div className="font-medium text-gray-900">
            No minimum stay.{" "}
            <span className="font-normal text-gray-500">
              You're free to cancel anytime.
            </span>
          </div>
        </Trans>
      </div>
    </>
  );
}
