import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosErr } from "@hilos/types/axios";
import usePostHogHelpers from "src/hooks/usePostHogHelpers";
import logo from "../../assets/img/hilos_logo.png";
import LangToggle from "../../components/LangToggle";
import { getCredentials } from "../../helpers/session";
import useHilosStore from "../../hooks/useHilosStore";
import { buildRoute } from "../../router/router";
import { LoginForm } from "./LoginForm";

export default function Login() {
  const { t } = useTranslation();
  const { identifyUser } = usePostHogHelpers();

  const navigate = useNavigate();
  const location = useLocation();
  const { setCredentials } = useHilosStore();
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { session } = useHilosStore();

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location]
  );

  useEffect(() => {
    if (session?.account) {
      const next = searchParams.has("next") && searchParams.get("next");
      if (next) {
        navigate(next);
      } else {
        navigate(buildRoute("dashboard"));
      }
    }
  }, [navigate, searchParams, session]);

  const onSubmit = async (data) => {
    setBackendValidationErrors({});
    setBackendError("");
    setSuccess(false);
    setSubmitted(false);
    try {
      const credentials = await getCredentials(data);
      if (credentials) {
        setSuccess(true);
        setSubmitted(true);
        setCredentials(credentials, (user) => {
          identifyUser(user, "login");
          const next = searchParams.has("next") && searchParams.get("next");
          if (next) {
            navigate(next);
          } else {
            navigate(buildRoute("dashboard"));
          }
        });
      }
    } catch (error) {
      const axiosErr = error as axiosErr;
      if (
        axiosErr?.response &&
        axiosErr.response.status === 400 &&
        axiosErr.response.data
      ) {
        setBackendValidationErrors(axiosErr.response.data);
      } else {
        setBackendError("An error occurred, please try again.");
      }
      setSuccess(false);
      setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    }
  };

  return (
    <div className="flex flex-1 min-h-full">
      <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img className="h-12 w-auto" src={logo} alt="Workflow" />
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
              {t("login:title", "Welcome back!")}
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              {t(
                "login:subtitle",
                "Get back to automating your WhatsApp messages with us."
              )}
            </p>
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <LoginForm
                onSubmit={onSubmit}
                backendError={backendError}
                backendValidationErrors={backendValidationErrors}
                success={success}
                submitted={submitted}
              />
            </div>
            <div className="mt-5">
              <LangToggle />
            </div>
          </div>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
          alt=""
        />
      </div>
    </div>
  );
}
