import { useTranslation } from "react-i18next";
import { PhoneIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { useFormikContext } from "formik";
import SelectorField from "src/components/Form/SelectorField";
import SwitchField from "src/components/Form/SwitchField";
import TextInputField from "src/components/Form/TextInputField";
import * as meta from "./Meta";

interface ChannelFormProps {
  disablePhoneEditing?: boolean;
}

const formatOptionLabel = ({ label, value }) => (
  <div className="flex gap-2 items-center">
    <span
      className={classNames(
        "h-4 w-4 rounded-full",
        `bg-${value}-200`,
        `border-${value}-700`
      )}
    />
    {label}
  </div>
);

export default function ChannelForm({
  disablePhoneEditing = false,
}: ChannelFormProps) {
  const { t } = useTranslation();
  const formik = useFormikContext();

  const colorOptions = [
    { value: "green", label: t("channel:colors.green", "Green") },
    { value: "violet", label: t("channel:colors.violet", "Violet") },
    { value: "blue", label: t("channel:colors.blue", "Blue") },
    { value: "teal", label: t("channel:colors.teal", "Teal") },
    { value: "pink", label: t("channel:colors.pink", "Pink") },
    { value: "orange", label: t("channel:colors.orange", "Orange") },
    { value: "stone", label: t("channel:colors.stone", "Stone") },
    { value: "lime", label: t("channel:colors.lime", "Lime") },
    { value: "cyan", label: t("channel:colors.cyan", "Cyan") },
    { value: "sky", label: t("channel:colors.sky", "Sky") },
  ];

  const handleColorChange = (selectedOption) => {
    formik.setFieldValue(meta.FIELDS.channel_color.key, selectedOption.value);
  };

  return (
    <div className="space-y-6 bg-white px-4 py-5 sm:p-6 text-left">
      <div className="grid grid-cols-3 gap-6">
        <div className="col-span-3">
          <TextInputField
            label={t(meta.FIELDS.name.label)}
            name={meta.FIELDS.name.key}
            help={t(meta.FIELDS.name.help)}
            type="text"
          />
        </div>
        <div className="col-span-3">
          <TextInputField
            placeholder={meta.FIELDS.channel_id.placeholder}
            label={t(meta.FIELDS.channel_id.label)}
            name={meta.FIELDS.channel_id.key}
            help={t(meta.FIELDS.channel_id.help)}
            icon={PhoneIcon}
            disabled={disablePhoneEditing}
            type="tel"
          />
        </div>
        <div className="col-span-3">
          <SwitchField
            label={t(meta.FIELDS.is_default.label)}
            name={meta.FIELDS.is_default.key}
            help={t(meta.FIELDS.is_default.help)}
          />
        </div>
        <div className="col-span-3">
          <SelectorField
            label={t(meta.FIELDS.channel_color.label)}
            name={meta.FIELDS.channel_color.key}
            help={t(meta.FIELDS.channel_color.help)}
            options={colorOptions}
            formatOptionLabel={formatOptionLabel}
            onSelect={handleColorChange}
          />
        </div>
      </div>
    </div>
  );
}
