import { useTranslation } from "react-i18next";
import { FlowStepEdit } from "@hilos/types/private-schema";

interface FlowStepActiveCampaignAutomationEndDetailProps {
  step: FlowStepEdit;
}

export default function FlowStepActiveCampaignAutomationEndDetail({
  step,
}: FlowStepActiveCampaignAutomationEndDetailProps) {
  const { t } = useTranslation();

  return (
    <div>
      <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
        {t(
          "flow-executions:steps-detail.activecampaign.automation-end-name",
          "Automation to end for contact"
        )}
      </h3>

      <p className="text-blue-500 font-medium">{step.ac_automation_name}</p>
    </div>
  );
}
