import { Fragment, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import useHilosStore from "src/hooks/useHilosStore";
import { API_ROUTES, buildAPIRoute } from "src/router/router";
import StateButton from "./StateButton";

interface TermsAndConditionsAgreementProps {
  show: boolean;
}

export default function TermsAndConditionsAgreement({
  show,
}: TermsAndConditionsAgreementProps) {
  const { session } = useHilosStore();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleAccept = async () => {
    setIsSubmitting(true);

    try {
      await axios.patch(
        buildAPIRoute(API_ROUTES.USER_DETAIL, {
          ":id": session?.id,
        }),
        {
          accepted_terms: true,
          accepted_terms_at: new Date().toISOString(),
        }
      );
      setIsSubmitting(false);
      setSuccess(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => {}}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {t(
                      "terms:we-care-about-your-privacy",
                      "We care about your privacy"
                    )}
                  </Dialog.Title>
                  <div className="mt-2">
                    <Trans i18nKey="terms:terms-and-conditions">
                      <p className="text-sm text-gray-500">
                        We've recently updated our{" "}
                        <Link
                          className="text-hilos"
                          to="https://drive.google.com/file/d/1ZOdwFfp43sbjpSvjmXvPx7LfmiTzrv3W/view?usp=sharing"
                        >
                          Terms and conditions
                        </Link>{" "}
                        and{" "}
                        <Link
                          className="text-hilos"
                          to="https://drive.google.com/file/d/1e5ewzNdFZ8r-t1WmMoxZI3WE-ZEzH0dH/view?usp=sharing"
                        >
                          Privacy Policy
                        </Link>
                        . Please read these documents to understand how we
                        collect, use, and store your data. Please accept these
                        terms to continue using our services.
                      </p>
                    </Trans>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <StateButton
                  isSubmitting={isSubmitting}
                  submitted={success}
                  success={success}
                  btnClasses="w-full inline-flex items-center justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                  submittingText={t("accepting", "Accepting...")}
                  successText={t("accepted", "Accepted")}
                  initialText={<>{t("accept", "Accept")}</>}
                  onClick={handleAccept}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
