import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  ArrowSmDownIcon,
  ArrowSmUpIcon,
  InboxIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { FlowExecutionContactStatusEnum } from "@hilos/types/private-schema";
import FlowExecutionContactControlsButtons from "src/containers/flow/flow_execution_contact/FlowExecutionContactControlsButtons";
import useDateFnsFormatLocale from "src/hooks/useDateFnsFormatLocale";
import {
  useFlowExecutionContactDetail,
  useUpdateFlowExecutionContactDetailQuery,
} from "src/hooks/useFlowExecutionContactDetail";
import { formatPhone, userToString } from "../../../Helpers";
import HeaderNavigation from "../../../components/HeaderNavigation";
import { buildRoute } from "../../../router/router";
import FlowExecutionContactStatusBadge from "./FlowExecutionContactStatusBadge";
import FlowExecutionContactStepList from "./FlowExecutionContactStepList";

export default function FlowExecutionContactDetail() {
  const params = useParams();
  const { t } = useTranslation();
  const { formatWithLocale } = useDateFnsFormatLocale();

  const flowExecutionContactId = params.id as string;

  const flowExecutionContact = useFlowExecutionContactDetail({
    flowExecutionContactId,
  });

  const onFlowExecutionContactStatusUpdateSuccess =
    useUpdateFlowExecutionContactDetailQuery(flowExecutionContactId);

  if (!flowExecutionContact) {
    return null;
  }

  return (
    <>
      {flowExecutionContact && (
        <div className="h-screen overflow-y-auto bg-gray-50">
          <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
            <HeaderNavigation
              navPages={[
                {
                  name: t("flows:header.flows", "Flows"),
                  url: buildRoute("flow-list"),
                },
                {
                  name: `Flow ${flowExecutionContact.flow.name}`,
                  url: buildRoute("flow-detail", {
                    id: flowExecutionContact.flow.id,
                  }),
                },
                {
                  name: formatPhone(
                    flowExecutionContact.contact.phone ?? "",
                    true,
                    true
                  ),
                  url: buildRoute("flow-execution-contact-detail", {
                    id: flowExecutionContact.id,
                  }),
                },
              ]}
            />
            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="min-w-0 flex-1">
                <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                  <UserIcon className="mr-4 h-7 w-7" aria-hidden="true" />
                  {formatPhone(flowExecutionContact.contact.phone, true, true)}
                </h2>
              </div>
            </div>
          </div>

          <div className="my-6 grid grid-cols-1 grid-rows-2 gap-4 px-6 md:grid-cols-4 md:grid-rows-1">
            <div className="col-span-2 rounded-md border border-gray-200 bg-white px-4 py-5 shadow-sm sm:px-6">
              <div className="justify-left mt-2 flex items-center text-sm">
                <div className="h-10 mr-4 w-10 rounded-full flex items-center justify-center bg-gray-100">
                  <UserIcon
                    className=" h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="grow mr-10">
                  <div className="text-base font-medium">
                    {formatPhone(
                      flowExecutionContact.contact.phone,
                      true,
                      true
                    )}
                  </div>
                  <p>{userToString(flowExecutionContact.contact)}</p>
                  <FlowExecutionContactStatusBadge
                    status={
                      flowExecutionContact.status as FlowExecutionContactStatusEnum
                    }
                  />
                  {["CANCELED", "FAILED"].includes(
                    flowExecutionContact.status as string
                  ) && (
                    <p className="text-xs text-red-400">
                      {flowExecutionContact.status_reason}{" "}
                      {flowExecutionContact.status_triggered_by && (
                        <>
                          (
                          {userToString(
                            flowExecutionContact.status_triggered_by
                          )}
                          )
                        </>
                      )}
                    </p>
                  )}
                  <p className="text-gray-400">
                    {t("started-on", "Started on")}{" "}
                    {formatWithLocale(
                      new Date(flowExecutionContact.created_on as string),
                      "EEEE d MMMM - HH:mm"
                    )}
                  </p>
                </div>
                <div className="grow-0 space-y-4">
                  <Link
                    to={`/inbox/conversation/${flowExecutionContact.inbox_contact_id}/details`}
                    className="inline-flex justify-center w-full items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-violet-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
                  >
                    <InboxIcon className="h-5 w-5 text-white mr-1" />
                    {t(
                      "flow-executions:contact-see-in-inbox",
                      "Go to conversation"
                    )}
                  </Link>
                  <FlowExecutionContactControlsButtons
                    flowExecutionContactId={flowExecutionContactId}
                    flowExecutionContactStatus={
                      flowExecutionContact.status as FlowExecutionContactStatusEnum
                    }
                    onFlowExecutionContactStatusUpdateSuccess={
                      onFlowExecutionContactStatusUpdateSuccess
                    }
                  />
                </div>
              </div>
            </div>

            <div className="flex items-center rounded-md border border-gray-200 bg-white px-4 py-5 text-center shadow-sm sm:px-6">
              <div className="h-10 w-10 rounded-full flex items-center justify-center bg-green-100">
                <ArrowSmDownIcon
                  className=" h-5 w-5 text-green-600"
                  aria-hidden="true"
                />
              </div>
              <div className="text-left ml-4">
                <p className="text-3xl text-gray-900 font-bold">
                  {flowExecutionContact.execution_steps
                    ?.map(
                      (es) =>
                        es.messages.filter(
                          (m) => m.message.direction === "INBOUND"
                        ).length
                    )
                    .reduce((p, c) => p + c, 0)}
                </p>
                <p className="text-slate-400 font-medium text-xs">
                  {t("inbound-messages", "Inbound Messages")}
                </p>
              </div>
            </div>
            <div className="flex items-center rounded-md border border-gray-200 bg-white px-4 py-5 text-center shadow-sm sm:px-6">
              <div className="h-10 w-10 rounded-full flex items-center justify-center bg-blue-100">
                <ArrowSmUpIcon
                  className=" h-5 w-5 text-blue-600"
                  aria-hidden="true"
                />
              </div>
              <div className="text-left ml-4">
                <p className="text-3xl text-gray-900 font-bold">
                  {flowExecutionContact.execution_steps
                    ?.map(
                      (es) =>
                        es.messages.filter(
                          (m) => m.message.direction === "OUTBOUND"
                        ).length
                    )
                    .reduce((p, c) => p + c, 0)}
                </p>
                <p className="text-slate-400 font-medium text-xs">
                  {t("outbound-messages", "Outbound Messages")}
                </p>
              </div>
            </div>
          </div>

          <FlowExecutionContactStepList
            flowExecutionContact={flowExecutionContact}
          />
        </div>
      )}
    </>
  );
}
