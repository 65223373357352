import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { ContactIcon, UserPlus, UsersIcon, VariableIcon } from "lucide-react";
import SelectorField from "src/components/Form/SelectorField";
import SwitchField from "src/components/Form/SwitchField";
import useWorkflowStates from "src/hooks/useWorkflowStates";
import useWorkflows from "src/hooks/useWorkflows";
import { useStepField } from "../../../hooks/useStepField";
import FlowAdvancedOptionsDisclosure from "../../FlowBuilderAdvancedOptionsDisclosure";
import FormatOptionLabel from "../../FormatOptionLabel";
import FlowBuilderStepWorkflowTaskAssignmentTypes from "./FlowBuilderStepWorkflowTaskAssignmentTypes";

interface FlowBuilderStepWorkflowTaskProps {
  index: number;
}

function FlowBuilderStepWorkflowTask({
  index,
}: FlowBuilderStepWorkflowTaskProps) {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();
  const [workflow] = useStepField({
    index,
    name: "workflow",
  });
  const workflows = useWorkflows();
  const states = useWorkflowStates(workflow);

  const assignmentTypeOptions = [
    {
      value: "USER",
      label: t("flows:steps.workflow-task.assignment-type.user.label", "User"),
      icon: UserPlus,
      help: t(
        "flows:steps.workflow-task.assignment-type.user.description",
        "Assign user(s) directly."
      ),
    },
    {
      value: "TEAM",
      label: t("flows:steps.workflow-task.assignment-type.team.label", "Team"),
      icon: UsersIcon,
      help: t(
        "flows:steps.workflow-task.assignment-type.team.description",
        "Assign user(s) based on team members and their assignment strategies."
      ),
    },
    {
      value: "VARIABLE",
      label: t(
        "flows:steps.workflow-task.assignment-type.variable.label",
        "Variable"
      ),
      icon: VariableIcon,
      help: t(
        "flows:steps.workflow-task.assignment-type.variable.description",
        "Assign user(s) from variables of contact, flow or steps."
      ),
    },
    {
      value: "CONTACT_OWNER",
      label: t(
        "flows:steps.workflow-task.assignment-type.contact-owner.label",
        "Contact Owner"
      ),
      icon: ContactIcon,
      help: t(
        "flows:steps.workflow-task.assignment-type.contact-owner.description",
        "Assign user(s) based on the contact owner."
      ),
    },
  ];

  const handleSelectAssignmentType = useCallback(() => {
    setFieldValue(`steps.${index}.assign_to_users`, []);
    setFieldValue(`steps.${index}.assign_to_teams`, []);
    setFieldValue(`steps.${index}.assign_from_variable`, null);
  }, [setFieldValue, index]);

  const handleSelectWorkflow = useCallback(() => {
    setFieldValue(`steps.${index}.state_to_move_to`, null);
    setFieldValue(`steps.${index}.assignment_type`, "USER");
    handleSelectAssignmentType();
  }, [setFieldValue, index, handleSelectAssignmentType]);

  return (
    <div className="space-y-4">
      <SelectorField
        name={`steps.${index}.workflow`}
        options={workflows.results.map((workflow) => ({
          label: workflow.name,
          value: workflow.id,
        }))}
        label={t("flows:steps.workflow-task.workflow.label", "Workflow")}
        help={t(
          "flows:steps.workflow-task.workflow.help",
          "Select the workflow you'd like to create or update a task in."
        )}
        placeholder={t("type-to-search")}
        onSelect={handleSelectWorkflow}
      />

      {workflow && (
        <>
          <SelectorField
            name={`steps.${index}.state_to_move_to`}
            options={states.map((state) => ({
              label: state.name,
              value: state.id,
            }))}
            label={t(
              "flows:steps.workflow-task.state-to-move-to.label",
              "State to set this task to"
            )}
            help={t(
              "flows:steps.workflow-task.state-to-move-to.help",
              "If no tasks exist for this contact in this workflow, we'll create one, otherwise we'll move the existing task to this state."
            )}
            placeholder={t(
              "flows:steps.workflow-task.state-to-move-to.placeholder",
              "Type to search states"
            )}
          />
          <div className="border-t border-gray-200 pt-2 space-y-4">
            <SelectorField
              name={`steps.${index}.assignment_type`}
              label={t(
                "flows:steps.workflow-task.assignment-type.label",
                "Assignment type"
              )}
              options={assignmentTypeOptions}
              formatOptionLabel={FormatOptionLabel}
              onSelect={handleSelectAssignmentType}
            />
            <FlowBuilderStepWorkflowTaskAssignmentTypes index={index} />
            <FlowAdvancedOptionsDisclosure>
              <SwitchField
                name={`steps.${index}.replace_assignees`}
                label={t(
                  "flows:steps.workflow-task.replace-assignees.label",
                  "Replace assignees?"
                )}
                help={t(
                  "flows:steps.workflow-task.replace-assignees.help",
                  "If enabled, we will replace the users instead of adding them to the already assigned."
                )}
              />
            </FlowAdvancedOptionsDisclosure>
          </div>
        </>
      )}
    </div>
  );
}
export default FlowBuilderStepWorkflowTask;
