import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SearchIcon } from "@heroicons/react/outline";
import { DateTime } from "luxon";
import { WebhookSubscriptionLogBasic } from "@hilos/types/private-schema";
import Table from "src/components/Table/Table";
import { classNames } from "src/Helpers";
import { API_ROUTES, buildAPIRoute, buildRoute } from "src/router/router";
import WebhookSubscriptionLogDetail from "./WebhookSubscriptionLogDetail";

export default function WebhookLogs() {
  const { t } = useTranslation();
  const [selectedWebhook, setSelectedWebhook] = useState<
    WebhookSubscriptionLogBasic | undefined
  >(undefined);
  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:px-6 lg:px-8">
      <div
        className={
          selectedWebhook ? "col-span-2 md:mt-6" : "col-span-3 md:mt-6"
        }
      >
        <Table
          baseURL={buildAPIRoute(API_ROUTES.WEBHOOK_SUBSCRIPTION_LOG_LIST)}
          paginated={true}
          createObject={
            <>
              <Trans i18nKey="integrations:webhooks.no-webhooks-fired">
                <h4>Looks like no webhooks have been fired yet</h4>
                <p className="text-sm text-gray-500">
                  Add a webhook to listen to the events you want and make sure
                  it's active.
                </p>
                <Link
                  to={buildRoute("webhook-list")}
                  className="mt-2 inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-violet-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
                >
                  <SearchIcon
                    className="-ml-1 mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  Webhook Subscriptions
                </Link>
              </Trans>
            </>
          }
          tableHead={
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="pl-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("event")}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("integrations:webhooks.webhook-url", "Webhook URL")}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("integrations:webhooks.status-code", "Status Code")}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("timestamp")}
                </th>
              </tr>
            </thead>
          }
          tableRow={(webhook: WebhookSubscriptionLogBasic) => (
            <>
              <td
                className={classNames(
                  "whitespace-nowrap pl-4 py-3 text-sm text-gray-900",
                  webhook === selectedWebhook ? "bg-gray-50" : ""
                )}
              >
                <button
                  type="button"
                  onClick={(_) => setSelectedWebhook(webhook)}
                  className="text-left"
                >
                  <code className="text-indigo-500 block">{webhook.event}</code>
                  <code className="text-xs text-gray-500 font-mono block">
                    {webhook.id}
                  </code>
                </button>
              </td>
              <td
                className={classNames(
                  "px-4 py-3 text-sm text-gray-900",
                  webhook === selectedWebhook ? "bg-gray-50" : ""
                )}
              >
                <button
                  type="button"
                  onClick={(_) => setSelectedWebhook(webhook)}
                  className="text-left"
                >
                  <code className="text-indigo-500">{webhook.request_url}</code>
                </button>
              </td>
              <td
                className={classNames(
                  "whitespace-nowrap px-4 py-3 text-sm text-gray-900",
                  webhook === selectedWebhook ? "bg-gray-50" : ""
                )}
              >
                {webhook.succeeded ? (
                  <code className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-xs font-medium text-green-800">
                    {webhook.response_status}
                  </code>
                ) : (
                  <code className="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-xs font-medium text-red-800">
                    {(webhook.response_status as number) > 0
                      ? webhook.response_status
                      : "N/A"}
                  </code>
                )}
              </td>
              <td
                className={classNames(
                  "whitespace-nowrap pr-4 py-3 text-sm text-gray-500",
                  webhook === selectedWebhook ? "bg-gray-50" : ""
                )}
              >
                {DateTime.fromISO(webhook.timestamp as string).toLocaleString(
                  DateTime.DATETIME_SHORT
                )}
              </td>
            </>
          )}
          dataName={t("webhook-logs", "Webhook logs")}
          noResults={
            <>
              <Trans i18nKey="integrations:webhooks.check-docs">
                <h4>Looks like no webhooks have been fired yet.</h4>
                <p className="text-sm text-gray-500">
                  Register a new webhook endpoint to start. You can also read
                  our{" "}
                  <a
                    href="https://hilos.io/docs/apidocs"
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-500"
                  >
                    API docs
                  </a>{" "}
                  to see how to use these webhook endpoints.
                </p>
              </Trans>
            </>
          }
        ></Table>
      </div>
      {selectedWebhook && (
        <div className="mt-5 md:mt-6 border-t border-b border-gray-200 bg-white shadow sm:rounded-lg sm:border">
          <div className="sm:overflow-hidden sm:rounded-md bg-white px-4 py-5 sm:p-6">
            <WebhookSubscriptionLogDetail
              webhookSubscriptionBasic={selectedWebhook}
            />
          </div>
        </div>
      )}
    </div>
  );
}
