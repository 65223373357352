import { PropsWithChildren, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { XIcon } from "@heroicons/react/outline";
import { FieldArrayRenderProps } from "formik";
import SelectorField from "../../components/Form/SelectorField";
import {
  AVAILABLE_FILTER_FIELDS,
  FILTER_FIELDS_WITHOUT_COMPARISONS,
} from "./InboxMeta";

interface FilterFieldContainerProps {
  name: string;
  index: number;
  filter?: { field: string; operator: string };
  setFieldValue: (key: string, value: string | boolean | null) => void;
  arrayHelpers: FieldArrayRenderProps;
}

function FilterFieldContainer({
  name,
  index,
  filter,
  setFieldValue,
  arrayHelpers,
  children,
}: PropsWithChildren<FilterFieldContainerProps>) {
  const { t } = useTranslation();
  const filterOptions = useMemo(
    () =>
      Object.getOwnPropertyNames(AVAILABLE_FILTER_FIELDS)
        .filter((key) => key !== "channel")
        .map((key) => ({
          label: t(AVAILABLE_FILTER_FIELDS[key].label),
          value: key,
        })),
    [t]
  );

  const comparisonsOptions = useMemo(() => {
    if (
      !filter ||
      !filter.field ||
      FILTER_FIELDS_WITHOUT_COMPARISONS.includes(filter.field)
    ) {
      return [];
    }

    return Object.getOwnPropertyNames(
      AVAILABLE_FILTER_FIELDS[filter.field].operators
    ).map((c) => ({
      label: t(AVAILABLE_FILTER_FIELDS[filter.field].operators[c].label),
      value: c,
    }));
  }, [filter, t]);

  const handleFilterDelete = useCallback(() => {
    arrayHelpers.remove(index);
  }, [index, arrayHelpers]);

  if (filter && filter.field && !AVAILABLE_FILTER_FIELDS[filter.field]) {
    return null;
  }

  return (
    <li className="py-2">
      <div className="flex items-center justify-between space-x-4">
        <div className="flex-1 space-y-1 sm:flex sm:flex-col">
          <div className="w-full grow">
            <SelectorField
              options={filterOptions}
              singleValueStyles={{
                color: "#4f46e5",
                fontWeight: 600,
              }}
              name={`${name}.field`}
              onSelect={(option) => {
                if (FILTER_FIELDS_WITHOUT_COMPARISONS.includes(option.value)) {
                  setFieldValue(`${name}.value`, false);
                  setFieldValue(`${name}.operator`, "_eq");
                } else {
                  setFieldValue(`${name}.value`, "");
                  setFieldValue(`${name}.operator`, "");
                }
              }}
              placeholder={t(
                "inbox:labels.select-field",
                "Select a field to filter by"
              )}
            />
          </div>
          {filter && comparisonsOptions.length > 0 && (
            <div className="w-full grow">
              <SelectorField
                options={comparisonsOptions}
                name={`${name}.operator`}
                placeholder={t(
                  "inbox:labels.select-comparison",
                  "Select a comparison..."
                )}
                onSelect={(option) => {
                  if (
                    AVAILABLE_FILTER_FIELDS[filter.field].operators[
                      option.value
                    ]?.value === "bool"
                  ) {
                    setFieldValue(`${name}.value`, false);
                  }
                }}
              />
            </div>
          )}
          {children}
        </div>
        <button
          type="button"
          className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-200 text-gray-400 transition duration-150 ease-in-out focus:outline-none"
          onClick={handleFilterDelete}
        >
          <XIcon className="h-4 w-4 text-gray-500" />
        </button>
      </div>
    </li>
  );
}

export default FilterFieldContainer;
