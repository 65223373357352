import { Fragment } from "react";
import { userAvatar } from "src/Helpers";

function FormatOptionLabelTeam({ value, label, team }) {
  return (
    <div className="flex items-center justify-between">
      <div>
        <h4 className="font-medium">{label}</h4>
      </div>
      <div className="ml-2 flex justify-end -space-x-1 overflow-hidden">
        {team &&
          team.users.map((user) => (
            <Fragment key={user.id}>
              {userAvatar(
                user,
                "h-6 w-6 text-xs ring-2 ring-white group-hover:ring-gray-300"
              )}
            </Fragment>
          ))}
      </div>
    </div>
  );
}

export default FormatOptionLabelTeam;
