"use client";

import { SVGProps, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { Bell, ChevronRight, type LucideIcon } from "lucide-react";
import { usePostHog } from "posthog-js/react";
import HilosIcon from "src/components/icons/HilosIcon";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "src/components/ui/collapsible";
import {
  SidebarGroup,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubItem,
  SidebarSeparator,
  useSidebar,
} from "src/components/ui/sidebar";
import { hasItems } from "src/helpers/utils";
import useHilosStore from "src/hooks/useHilosStore";
import { classNames } from "src/Helpers";

interface NavigationSubItem {
  name: string;
  url: string;
  icon: LucideIcon;
}

interface NavigationItem {
  name: string;
  url: string;
  permission?: string;
  featureFlag?: string;
  icon: LucideIcon | ((props: SVGProps<SVGSVGElement>) => JSX.Element);
  isActive?: boolean;
  isMatchEnd?: boolean;
  expand?: boolean;
  items?: NavigationSubItem[];
}

function NavMainSubItemButton({
  name,
  icon: RenderIcon,
  url,
}: NavigationSubItem) {
  const [t] = useTranslation();
  const resolved = useResolvedPath(url);
  const isActive = useMatch({ path: resolved.pathname });

  return (
    <SidebarMenuButton
      asChild
      tooltip={t(name)}
      isActive={Boolean(isActive)}
      className="data-[active=true]:text-hilos"
    >
      <Link to={url}>
        <RenderIcon />
        <span>{t(name)}</span>
      </Link>
    </SidebarMenuButton>
  );
}

function NavMainItemButton({
  name,
  icon: RenderIcon,
  url,
  items,
  isMatchEnd,
  expand,
}: NavigationItem) {
  const [t] = useTranslation();
  const resolved = useResolvedPath(url);
  const isActive = useMatch({ path: resolved.pathname, end: isMatchEnd });
  const { state, setOpen } = useSidebar();

  const handleItemClick = (e: React.MouseEvent) => {
    if (state === "collapsed" && expand && hasItems(items)) {
      e.preventDefault();
      setOpen(true);
    }
  };

  if (hasItems(items)) {
    return (
      <CollapsibleTrigger asChild>
        <SidebarMenuButton
          tooltip={t(name)}
          isActive={Boolean(isActive)}
          className="group-data-[collapsible=icon]:data-[active=true]:text-hilos"
          onClick={handleItemClick}
        >
          <RenderIcon />
          <span>{t(name)}</span>
          <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
        </SidebarMenuButton>
      </CollapsibleTrigger>
    );
  }

  return (
    <SidebarMenuButton
      asChild
      tooltip={t(name)}
      isActive={Boolean(isActive)}
      className="data-[active=true]:text-hilos"
    >
      <Link to={url}>
        <RenderIcon />
        <span>{t(name)}</span>
      </Link>
    </SidebarMenuButton>
  );
}

function NavMainItemContent({ items }: Pick<NavigationItem, "items">) {
  const [t] = useTranslation();

  if (hasItems(items)) {
    return (
      <CollapsibleContent>
        <SidebarMenuSub>
          {(items as NavigationSubItem[]).map((subItem) => (
            <SidebarMenuSubItem key={t(subItem.name)}>
              <NavMainSubItemButton {...subItem} />
            </SidebarMenuSubItem>
          ))}
        </SidebarMenuSub>
      </CollapsibleContent>
    );
  }

  return null;
}

const home = {
  // i18n.t("navigation:home.title", "Home")
  name: "navigation:home.title",
  url: "/",
  icon: HilosIcon,
};

export function NavMain({
  items,
  onSwitchShowNotifications,
}: {
  items: NavigationItem[];
  onSwitchShowNotifications: () => void;
}) {
  const [t] = useTranslation();
  const { session, unreadNotificationCount } = useHilosStore();

  const [permissions = [], featureFlags = []] = useMemo(() => {
    if (!session) {
      return [];
    }
    return [session.permissions || [], session.account.feature_flags || []];
  }, [session]);

  return (
    <SidebarGroup>
      <SidebarMenu>
        <SidebarMenuItem>
          <NavMainItemButton {...home} />
        </SidebarMenuItem>
        <SidebarMenuItem>
          <SidebarMenuButton
            className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            onClick={onSwitchShowNotifications}
          >
            <Bell
              className={classNames(
                "size-4",
                unreadNotificationCount > 0 && "animate-wiggle"
              )}
            />
            <div className="grid flex-1 text-left text-sm leading-tight">
              <span className="truncate">
                {t("notifications.title", "Notifications")}
              </span>
            </div>
            {unreadNotificationCount > 0 && (
              <div className="mr-auto bg-hilos rounded-sm min-w-4 px-0.5 flex items-center justify-center">
                <span className="text-xs font-semibold text-white">
                  {unreadNotificationCount > 99
                    ? "99+"
                    : unreadNotificationCount}
                </span>
              </div>
            )}
          </SidebarMenuButton>
        </SidebarMenuItem>
        <SidebarSeparator className="mx-0" />
        {items.map((item) => (
          <Collapsible
            key={item.name}
            asChild
            defaultOpen={item.isActive}
            className={classNames(
              "group/collapsible",
              ((item.permission && !permissions.includes(item.permission)) ||
                (item.featureFlag &&
                  !featureFlags.includes(item.featureFlag))) &&
                "hidden"
            )}
          >
            <SidebarMenuItem>
              <>
                <NavMainItemButton {...item} />
                <NavMainItemContent items={item.items} />
              </>
            </SidebarMenuItem>
          </Collapsible>
        ))}
      </SidebarMenu>
    </SidebarGroup>
  );
}
