import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useField, useFormikContext } from "formik";
import { getAvailableChannelData } from "src/containers/config/channel/Meta";
import { useChannelAvailability } from "src/hooks/useChannel";
import { formatPhone } from "src/Helpers";
import { ChannelAvailabilityData, ChannelType } from "src/types/channel";
import SelectorField from "./SelectorField";

const formatOptionLabel = ({
  label,
  icon,
  channel_type,
  channel_id,
  channel_color,
  is_contact_default,
  is_account_default,
  is_last_used,
  t,
  readOnly = false,
}) => (
  <div className="flex items-center justify-between py-1">
    {channel_type === ChannelType.WHATSAPP && (
      <>
        {readOnly ? (
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className=" rounded-full mr-2 text-xs font-medium uppercase text-gray-400">
                <div>{icon}</div>
              </div>
              <h4 className="grow text-xs font-medium text-gray-500">
                {label}
              </h4>
              <div className="ml-2 shrink truncate text-xs text-gray-500">
                {formatPhone(channel_id, true, false)}
              </div>
            </div>
            {is_last_used && (
              <div className="ml-2 inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-xs font-medium text-gray-500">
                {t("channel:last-used", "Last used")}
              </div>
            )}
            {is_contact_default && (
              <div className="ml-2 inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-xs font-medium text-gray-500">
                {t("channel:contact-default", "Contact default")}
              </div>
            )}
            {is_account_default && (
              <div className="ml-2 inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-xs font-medium text-gray-500">
                {t("channel:account-default", "Account Default")}
              </div>
            )}
          </div>
        ) : (
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div
                className={`mr-2 rounded-full bg-${channel_color}-200 px-2 py-0.5 text-xs font-medium uppercase text-${channel_color}-700`}
              >
                {icon}
              </div>
              <h4 className="grow text-sm font-medium">{label}</h4>
              <div className="ml-2 shrink truncate text-sm text-gray-500">
                {formatPhone(channel_id, true, true)}
              </div>
            </div>
            {is_last_used && (
              <div className="ml-2 inline-flex items-center rounded-full bg-pink-100 px-3 py-0.5 text-xs font-medium text-pink-800">
                {t("channel:last-used", "Last used")}
              </div>
            )}
            {is_contact_default && (
              <div className="ml-2 inline-flex items-center rounded-full bg-blue-100 px-3 py-0.5 text-xs font-medium text-blue-800">
                {t("channel:contact-default", "Contact default")}
              </div>
            )}
            {is_account_default && (
              <div className="ml-2 inline-flex items-center rounded-full bg-cyan-100 px-3 py-0.5 text-xs font-medium text-cyan-800">
                {t("channel:account-default", "Account Default")}
              </div>
            )}
          </div>
        )}
      </>
    )}
  </div>
);

interface ChannelOption {
  icon: JSX.Element | null;
  channel_type: ChannelType;
  channel_id: string;
  label: string;
  value: number;
  is_contact_default: boolean;
  is_account_default: boolean;
  is_last_used: boolean;
}

interface ChannelSelectFieldProps {
  name: string;
  label: string;
  help?: string;
  contactId?: string;
  readOnly?: boolean;
  onChange?: (channel: ChannelOption) => void;
}
const ChannelSelectField = ({
  name,
  label,
  help,
  contactId = undefined,
  readOnly = false,
  onChange,
}: ChannelSelectFieldProps) => {
  const { t } = useTranslation();
  const { data: channels } = useChannelAvailability(contactId);
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field] = useField(name);

  const options = useMemo(() => {
    if (!channels) {
      return [];
    }
    return channels
      .filter(
        (channel_data: ChannelAvailabilityData) => channel_data.is_available
      )
      .map((channel_data: ChannelAvailabilityData) => {
        const channelData = getAvailableChannelData(channel_data.channel);
        return {
          icon: channelData ? (
            <channelData.icon className={readOnly ? `text-sm` : `text-lg`} />
          ) : null,
          channel_type: channel_data.channel.channel_type,
          channel_id: channel_data.channel.channel_id,
          label: channel_data.channel.name,
          value: channel_data.channel.id,
          is_contact_default: channel_data.is_contact_default,
          is_account_default: channel_data.is_account_default,
          is_last_used: channel_data.is_last_used,
          channel_color: channel_data.channel.channel_color,
        };
      });
  }, [channels, readOnly]);

  useEffect(() => {
    const is_last_used_default = options.find((option) => option.is_last_used);
    const is_contact_default = options.find(
      (option) => option.is_contact_default
    );
    const is_account_default = options.find(
      (option) => option.is_account_default
    );

    const defaultChannel = [
      is_last_used_default,
      is_contact_default,
      is_account_default,
    ]
      .filter((x) => x !== undefined)
      .flat(1);

    if (defaultChannel.length > 0 && !field.value) {
      setFieldTouched(name);
      //@ts-ignore
      setFieldValue(name, defaultChannel[0].value);
    }
  }, [options, field.value, name, setFieldTouched, setFieldValue]);

  if (readOnly) {
    return (
      //@ts-ignore
      formatOptionLabel({
        ...options.find((option) => option.value === field.value),
        t,
        readOnly: true,
      })
    );
  }
  return (
    <SelectorField
      name={name}
      options={options}
      label={label}
      help={help}
      onSelect={onChange}
      // @ts-ignore
      formatOptionLabel={(props) => formatOptionLabel({ ...props, t })}
    />
  );
};

export default ChannelSelectField;
