import { useTranslation } from "react-i18next";
import { FlowStepEdit } from "@hilos/types/private-schema";

interface FlowStepActiveCampaignAutomationStartDetailProps {
  step: FlowStepEdit;
}

export default function FlowStepActiveCampaignAutomationStartDetail({
  step,
}: FlowStepActiveCampaignAutomationStartDetailProps) {
  const { t } = useTranslation();

  return (
    <div>
      <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
        {t(
          "flow-executions:steps-detail.activecampaign.automation-start-name",
          "Automation to start for contact"
        )}
      </h3>

      <p className="text-blue-500 font-medium">{step.ac_automation_name}</p>
    </div>
  );
}
