import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  ArrowSmDownIcon,
  ArrowSmUpIcon,
  ShieldCheckIcon,
} from "@heroicons/react/outline";
import axios from "axios";
import { DateTime } from "luxon";
import { axiosErr } from "@hilos/types/axios";
import { UserList as UserListType } from "@hilos/types/private-schema";
import AdminWrap from "src/components/AdminWrap";
import PermissionsChecker from "src/components/PermissionsCheck";
import Table from "src/components/Table/Table";
import useHilosStore from "src/hooks/useHilosStore";
import { useRoles } from "src/hooks/useRole";
import { userAvatar, userToString } from "../../../Helpers";
import DeleteObject from "../../../components/DeleteObject";
import { API_ROUTES, buildAPIRoute, buildRoute } from "../../../router/router";
import UserInvitationV2 from "../invitation/UserInvitationV2";

export default function UserList() {
  const { session } = useHilosStore();
  const { t } = useTranslation();
  const [triggerReload, setTriggerReload] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);
  const roles = useRoles();

  const onDelete = async (
    formData,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.delete(
        buildAPIRoute(API_ROUTES.USER_DETAIL, {
          ":id": formData.obj.id,
        })
      );
      setDeleteSubmitted(false);
      setDeleteSuccess(true);
      setTriggerReload(!triggerReload);
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;
      if (errorAxios?.response?.status === 400) {
        console.log("error", errorAxios);
        setBackendValidationErrors(errorAxios.response.data);
      } else {
        setBackendError(
          t("error-general", "An error occurred, please try again.")
        );
      }
      setTimeout(() => {
        setDeleteSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setDeleteSubmitted(false);
    }
  };
  const toggleAdminRoleForUser = async (user) => {
    try {
      const nextRoleName = user.role.name === "Admin" ? "Agent" : "Admin";
      const nextRole = roles.find((role) => role.name === nextRoleName);

      if (nextRole) {
        await axios.patch(
          buildAPIRoute(API_ROUTES.USER_DETAIL, {
            ":id": user.id,
          }),
          { role: nextRole.id }
        );
        setTriggerReload(!triggerReload);
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (!session) {
    return null;
  }

  return (
    <div className="h-full bg-gray-50 sm:px-6 sm:pt-8 lg:px-8 space-y-6">
      <PermissionsChecker permission="change_account" showNotice={false}>
        {session.account.pricing_model_version === "v2" && <UserInvitationV2 />}
      </PermissionsChecker>

      <Table<UserListType>
        baseURL={buildAPIRoute(API_ROUTES.USER_LIST)}
        paginated={false}
        tableHead={
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              >
                {t("email")}
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              >
                {t("role", "Role")}
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              >
                {t("joined-on", "Joined on")}
              </th>
              {session.role.name === "Admin" && (
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                ></th>
              )}
            </tr>
          </thead>
        }
        tableRow={(user) => (
          <>
            <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-900">
              {session.account.pricing_model_version === "v1" &&
              session.role.name === "Admin" ? (
                <Link to={buildRoute("account-member-update", { id: user.id })}>
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      {userAvatar(user)}
                    </div>
                    <div className="ml-4">
                      {user.first_name || user.last_name ? (
                        <>
                          <div className="text-sm font-medium text-blue-500">
                            {userToString(user)}
                          </div>
                          <div className="text-sm text-gray-500">
                            {user.email}
                          </div>
                        </>
                      ) : (
                        <span className="font-medium text-blue-500">
                          {user.email}
                        </span>
                      )}
                    </div>
                  </div>
                </Link>
              ) : (
                <Link to={buildRoute("user-update", { id: user.id })}>
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      {userAvatar(user)}
                    </div>
                    <div className="ml-4">
                      {user.first_name || user.last_name ? (
                        <>
                          <div className="text-sm font-medium text-blue-500">
                            {userToString(user)}
                          </div>
                          <div className="text-sm text-gray-500">
                            {user.email}
                          </div>
                        </>
                      ) : (
                        <span className="font-medium text-blue-500">
                          {user.email}
                        </span>
                      )}
                    </div>
                  </div>
                </Link>
              )}
            </td>
            <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
              <span className="inline-flex rounded-full bg-indigo-100 px-2 text-xs font-semibold uppercase leading-5 text-indigo-800">
                {t(
                  `settings:permissions.role.${user.role.name.toLowerCase()}`,
                  user.role.name
                )}
              </span>
              {session.account.pricing_model_version === "v2" && (
                <PermissionsChecker
                  permission="change_account"
                  showNotice={false}
                >
                  <div>
                    <Link
                      to={buildRoute("user-update", { id: user.id })}
                      className="text-blue-500 text-xs px-2 underline"
                    >
                      {t("switch", "Switch")}
                    </Link>
                  </div>
                </PermissionsChecker>
              )}
            </td>
            <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
              <time className="text-sm text-gray-500" dateTime="2020-03-22">
                {DateTime.fromISO(user.date_joined).toLocaleString(
                  DateTime.DATETIME_SHORT
                )}
              </time>
            </td>
            <AdminWrap>
              <td className="flex items-center justify-end px-4 py-3">
                {session.role.name === "Admin" &&
                  session.account.pricing_model_version === "v1" && (
                    <Link
                      to={buildRoute("account-member-update", {
                        id: user.id,
                      })}
                      className="inline-flex items-center rounded-md border border-indigo-600 bg-white px-3 py-2 text-sm font-medium leading-4 text-indigo-600 shadow-sm hover:bg-indigo-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mr-2"
                    >
                      <ShieldCheckIcon
                        className="mr-2 h-4 w-4"
                        aria-hidden="true"
                      />
                      {t(
                        "settings:team.update-permissions",
                        "Update permissions"
                      )}
                    </Link>
                  )}
                {session.account.pricing_model_version === "v1" &&
                  user.id.toString() !== session.id.toString() && (
                    <>
                      <button
                        className="focus:ring-hilos-500 mr-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100"
                        type="button"
                        onClick={(_) => {
                          toggleAdminRoleForUser(user);
                        }}
                      >
                        {user.role.name === "Admin" ? (
                          <>
                            <ArrowSmDownIcon
                              className="mr-2 h-4 w-4 text-gray-400"
                              aria-hidden="true"
                            />
                            {t("turn-agent", "Turn agent")}
                          </>
                        ) : (
                          <>
                            <ArrowSmUpIcon
                              className="mr-2 h-4 w-4 text-gray-400"
                              aria-hidden="true"
                            />
                            {t("turn-manager", "Turn manager")}
                          </>
                        )}
                      </button>
                    </>
                  )}
                {user.id.toString() !== session.id.toString() && (
                  <DeleteObject
                    obj={user}
                    objDescription={userToString(user)}
                    actionDescription="After removing a team member, they won't be able to access your team's resources, but all conversations will still be assigned to them."
                    onDelete={onDelete}
                    deleteText={t(
                      "settings:team.remove-from-team",
                      "Remove from team"
                    )}
                    deleteSuccess={deleteSuccess}
                    deleteSubmitted={deleteSubmitted}
                  />
                )}
              </td>
            </AdminWrap>
          </>
        )}
        dataName={t("team-members", "Team members")}
        noResults={
          <>
            <Trans i18nKey="settings:team.no-results-with-filters">
              <h4>Couldn't find any team members with these filters.</h4>
              <p className="text-gray-500">Remove filters to try again.</p>
            </Trans>
          </>
        }
        triggerReload={triggerReload}
      />
    </div>
  );
}
