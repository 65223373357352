import { useCallback, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  PencilAltIcon,
  PlusSmIcon,
  TrashIcon,
  ViewGridIcon,
} from "@heroicons/react/outline";
import {
  QueryFunction,
  keepPreviousData,
  useQuery,
} from "@tanstack/react-query";
import {
  ColumnDef,
  ColumnFiltersState,
  PaginationState,
} from "@tanstack/react-table";
import axios from "axios";
import { format } from "date-fns";
import { CursorPageData } from "@hilos/types/hilos";
import { DataTable } from "src/components/DataTable/DataTable";
import DropdownMenu from "src/components/DropdownMenu";
import HeaderNavigation from "src/components/HeaderNavigation";
import { classNames, userToString } from "src/Helpers";
import { API_ROUTES, buildAPIRoute, buildRoute } from "../../router/router";
import { UserAvatarById } from "../inbox/UserAvatar";

interface WorkflowListItem {
  id: string;
  name: string;
  created_on: string;
  users: number[];
}

const fetchWorkflowList: QueryFunction<
  any,
  [string, PaginationState, ColumnFiltersState]
> = async ({ signal, queryKey }) => {
  const { pageIndex, pageSize } = queryKey[1];

  const params: any = {
    page_size: pageSize,
    page: Math.max(pageIndex + 1, 1),
  };

  for (const column of queryKey[2] || []) {
    if (column.id === "name") {
      params.search = column.value;
    }
  }

  const { data } = await axios.get<CursorPageData<WorkflowListItem>>(
    API_ROUTES.WORKFLOW_LIST_CREATE,
    { signal, params }
  );

  return data;
};

const getColumns = ({
  onDeleteWorkflow,
}: {
  onDeleteWorkflow: (id: string) => void;
}): ColumnDef<WorkflowListItem>[] => [
  {
    accessorKey: "name",
    header: () => (
      <Trans i18nKey="name" as="span">
        Name
      </Trans>
    ),
    cell: ({ row }) => (
      <div className="flex flex-col w-full">
        <Link
          className="text-sm font-medium text-blue-600"
          to={buildRoute("workflow-board", {
            id: row.original.id,
          })}
        >
          {row.getValue("name")}
        </Link>
        <span className="text-sm text-gray-500">
          <Trans i18nKey="created-on">Created on</Trans>{" "}
          <time dateTime={row.original.created_on}>
            {format(new Date(row.original.created_on), "d/M/yyyy, h:mm aa")}
          </time>
        </span>
      </div>
    ),
  },
  {
    accessorKey: "created_by",
    header: () => (
      <div className="flex justify-center items-center">
        <Trans i18nKey="created-by" as="span">
          Created by
        </Trans>
      </div>
    ),
    cell: ({ row }) => (
      <div className="flex justify-center items-center">
        <span className="text-sm text-gray-600">
          {userToString(row.getValue("created_by"))}
        </span>
      </div>
    ),
  },
  {
    accessorKey: "users",
    header: () => (
      <div className="flex justify-center items-center">
        <Trans i18nKey="users" as="span">
          Users
        </Trans>
      </div>
    ),
    cell: ({ row }) => (
      <div className="flex shrink-0 -space-x-1 overflow-hidden pl-1 justify-center items-center">
        {((row.getValue("users") || []) as number[])
          .filter((_, index) => index < 3)
          .map((userId) => (
            <UserAvatarById
              key={userId}
              id={userId}
              className="h-6 w-6 text-xs"
            />
          ))}
      </div>
    ),
  },
  {
    id: "actions",
    enableSorting: false,
    enableHiding: false,
    cell: ({ row }) => (
      <div className="w-0">
        <DropdownMenu
          menuItems={[
            (active) => (
              <Link
                className={classNames(
                  active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                  "flex w-full items-center px-4 py-2 text-sm"
                )}
                to={buildRoute("workflow-update", {
                  id: row.original.id,
                })}
              >
                <PencilAltIcon
                  className="-ml-0.5 mr-2 h-4 w-4"
                  aria-hidden="true"
                />
                <Trans i18nKey="update">Update</Trans>
              </Link>
            ),
            (active) => (
              <button
                className={classNames(
                  active ? "bg-red-100 text-red-800" : "text-gray-700",
                  "group flex w-full items-center px-4 py-2 text-sm"
                )}
                type="button"
                onClick={() => onDeleteWorkflow(row.original.id)}
              >
                <TrashIcon
                  className="-ml-0.5 mr-2 h-4 w-4"
                  aria-hidden="true"
                />
                <Trans i18nKey="delete">Delete</Trans>
              </button>
            ),
          ]}
        />
      </div>
    ),
  },
];

export default function WorkflowList() {
  const [t] = useTranslation();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isSuccess, isLoading, refetch } = useQuery({
    queryKey: ["workflow-list", pagination, columnFilters],
    queryFn: fetchWorkflowList,
    placeholderData: keepPreviousData,
  });

  const handleDeleteWorkflow = useCallback(
    async (id: string) => {
      await axios.delete(
        buildAPIRoute(API_ROUTES.WORKFLOW_DETAIL, {
          ":id": id,
        })
      );
      refetch();
    },
    [refetch]
  );

  const columns = useMemo(
    () => getColumns({ onDeleteWorkflow: handleDeleteWorkflow }),
    [handleDeleteWorkflow]
  );

  return (
    <div className="flex flex-1 h-full flex-col">
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("home"),
              url: buildRoute("dashboard"),
            },
            {
              name: t("workflows"),
              url: buildRoute("workflow-list"),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <ViewGridIcon
                className="mr-4 h-7 w-7 rotate-90"
                aria-hidden="true"
              />
              {t("workflows", "CRM Workflows")}
            </h2>
            <p className="mt-1 text-sm text-gray-600">
              {t(
                "workflows:description",
                "Manage the processes of your work team."
              )}
            </p>
          </div>
          <div className="mt-2 flex md:mt-5 lg:mt-0 lg:ml-4">
            <span className="md:ml-3">
              <Link
                to={buildRoute("workflow-create")}
                className="inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-violet-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
              >
                <PlusSmIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                {t("workflows:create-workflow", "Create CRM Workflow")}
              </Link>
            </span>
          </div>
        </div>
      </div>
      <div className="p-8">
        <DataTable
          data={data}
          isLoading={isLoading}
          isSuccess={isSuccess}
          columns={columns}
          state={{
            pagination,
            columnFilters,
          }}
          onPaginationChange={setPagination}
          onColumnFiltersChange={setColumnFilters}
        />
      </div>
    </div>
  );
}
