import { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PencilAltIcon, PlusSmIcon } from "@heroicons/react/outline";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import { DateTime } from "luxon";
import { usePostHog } from "posthog-js/react";
import OnboardingModuleModal from "src/containers/onboarding_module/OnboardingModuleModal";
import EmptyStateComponent from "src/components/EmptyStates/EmptyStateComponent";
import Table from "src/components/Table/Table";
import { isFeatureEnabled } from "src/helpers/utils";
import useHilosStore from "src/hooks/useHilosStore";
import { formatPhone } from "src/Helpers";
import DeleteObject from "../../components/DeleteObject";
import { API_ROUTES, buildAPIRoute, buildRoute } from "../../router/router";
import ContactPhoneStatusIndicator from "./ContactPhoneStatusIndicator";

export default function ContactList() {
  const { t } = useTranslation();
  const [triggerReload, setTriggerReload] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);
  const { session, updateSession } = useHilosStore();
  const [showHelpModal, setShowHelpModal] = useState(false);
  const posthog = usePostHog();

  const useCursorPagination = useMemo(
    () =>
      isFeatureEnabled({
        key: "use_cursor_pagination",
        session,
        posthog,
      }),
    [session, posthog]
  );
  const onDelete = async (
    formData,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.delete(
        buildAPIRoute(API_ROUTES.CONTACT_DETAIL, {
          ":id": formData.obj.id,
        })
      );
      setDeleteSubmitted(false);
      setDeleteSuccess(true);
      setTriggerReload(!triggerReload);
    } catch (err) {
      setDeleteSuccess(false);
      if (err.response.status === 400) {
        Sentry.captureException(err);
        setBackendValidationErrors(err.response.data);
      } else {
        setBackendError(
          t("errors.error-general", "An error occurred, please try again.")
        );
      }
      setTimeout(() => {
        setDeleteSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setDeleteSubmitted(false);
    }
  };

  const handleCloseHelpModal = useCallback(() => {
    if (!session?.visited_modules.includes("contacts")) {
      updateSession({
        visited_modules: [...(session?.visited_modules || []), "contacts"],
      });
    }
    setShowHelpModal(false);
  }, []);

  useEffect(() => {
    setShowHelpModal(
      session ? !session.visited_modules.includes("contacts") : false
    );
  }, []);

  return (
    <>
      <div className="min-h-screen bg-gray-50">
        <div className="h-full sm:px-6 sm:pt-8 lg:px-8">
          <Table
            baseURL={buildAPIRoute(
              useCursorPagination
                ? API_ROUTES.CONTACT_CREATE_LIST_V2
                : API_ROUTES.CONTACT_CREATE_LIST
            )}
            paginated={true}
            triggerReload={triggerReload}
            tableHead={
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t("phone", "Phone")}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t("first-name", "First Name")}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t("last-name", "Last Name")}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t("email", "Email")}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t("source", "Source")}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t("created-on", "Created On")}
                  </th>
                  <th scope="col" className="px-4 py-3"></th>
                </tr>
              </thead>
            }
            tableRow={(contact) => (
              <>
                <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-900">
                  <Link
                    className="justify-left flex items-center font-medium text-blue-500"
                    to={buildRoute("contact-detail", {
                      id: contact.id,
                    })}
                  >
                    {formatPhone(contact.phone, true, true)}
                    <ContactPhoneStatusIndicator
                      status={contact.phone_status}
                    />
                  </Link>
                </td>
                <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                  <span>{contact.first_name ? contact.first_name : "-"}</span>
                </td>
                <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                  <span>{contact.last_name ? contact.last_name : "-"}</span>
                </td>
                <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                  <span>{contact.email ? contact.email : "-"}</span>
                </td>
                <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                  <span className="text-muted">{contact.source}</span>
                </td>
                <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                  <time className="text-muted" dateTime="2020-03-22">
                    {DateTime.fromISO(contact.created_on).toLocaleString(
                      DateTime.DATETIME_SHORT
                    )}
                  </time>
                </td>

                <td className="flex items-center px-4 py-3">
                  <Link
                    className="focus:ring-hilos-500 mr-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100"
                    to={buildRoute("contact-update", {
                      id: contact.id,
                    })}
                  >
                    <PencilAltIcon
                      className="-ml-0.5 mr-2 h-4 w-4"
                      aria-hidden="true"
                    />
                    {t("update", "Update")}
                  </Link>
                  <DeleteObject
                    obj={contact}
                    objDescription={contact.phone}
                    actionDescription="When deleting this contact, messages already sent will still reach them."
                    onDelete={onDelete}
                    deleteSuccess={deleteSuccess}
                    deleteSubmitted={deleteSubmitted}
                  ></DeleteObject>
                </td>
              </>
            )}
            dataName={t("contact", "Contact")}
            noResults={
              <>
                <Trans i18nKey="contacts:no-contacts-with-filters">
                  <h4>Couldn't find any contacts with these filters.</h4>
                  <p className="text-muted">Remove filters to try again.</p>
                </Trans>
              </>
            }
            createObject={
              <EmptyStateComponent routeName="contacts" showInfo={true}>
                <Link
                  to={buildRoute("contact-create")}
                  className="mt-2 inline-flex items-center rounded-md border border-transparent bg-hilos px-4 py-2 text-sm font-medium text-white shadow-sm focus:ring-offset-2"
                >
                  <PlusSmIcon
                    className="-ml-1 mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  New contact
                </Link>
              </EmptyStateComponent>
            }
            pagination={useCursorPagination ? "cursor" : "page"}
          ></Table>
        </div>
      </div>
      <OnboardingModuleModal
        routeName="contacts"
        show={showHelpModal}
        handleClose={handleCloseHelpModal}
      />
    </>
  );
}
