import { useTranslation } from "react-i18next";
import { Reorder } from "framer-motion";
import { GripVerticalIcon, Trash2Icon } from "lucide-react";
import AvailableFlowSelector from "src/components/AvailableFlowSelector";
import SelectorField from "src/components/Form/SelectorField";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "src/components/ui/accordion";
import EditableTitleField from "../flow/builder/EditableTitleField";

interface WorkflowStateReorderItemProps {
  state: any;
  index: number;
  onDelete: () => void;
}

function WorkflowStateReorderItem({
  state,
  index,
  onDelete,
}: WorkflowStateReorderItemProps) {
  const [t] = useTranslation();

  return (
    <Reorder.Item value={state}>
      <AccordionItem value={state.id || state.temp_id} className="border-none">
        <div className="flex w-full rounded-md bg-gray-100">
          <div className="flex flex-col w-full">
            <div className="flex w-full items-center justify-between px-4">
              <div className="flex w-fit items-center text-gray-700">
                <AccordionTrigger className="flex w-6 items-center" />
                <EditableTitleField
                  name={`states.${index}.name`}
                  className="pr-2 font-semibold text-base outline-none bg-gray-100"
                  maxCharSize={19}
                />
              </div>
              <div className="flex items-center">
                <button
                  type="button"
                  className="items-center justify-center p-2 text-gray-700 focus:outline-none hover:text-red-700 focus:text-red-700"
                  onClick={onDelete}
                >
                  <Trash2Icon className="h-5 w-5 " />
                </button>
                <GripVerticalIcon className="text-gray-700 w-5" />
              </div>
            </div>
            <AccordionContent className="px-5 space-y-2">
              <div className="min-w-48 w-fit">
                <SelectorField
                  label={t(
                    "workflows:workflow-form.states.state_type.title",
                    "State Type"
                  )}
                  name={`states.${index}.state_type`}
                  options={["ACTIVE", "CLOSED"].map((stateType) => ({
                    label: stateType,
                    value: stateType,
                  }))}
                />
              </div>
              <div className="min-w-48 w-fit">
                <AvailableFlowSelector
                  name={`states.${index}.trigger_flow`}
                  label={t(
                    "workflows:workflow-form.states.trigger-flow.label",
                    "Trigger flow"
                  )}
                  help={t(
                    "workflows:workflow-form.states.trigger-flow.help",
                    "Select a flow that starts when changing to this state."
                  )}
                  placeholder={t("type-to-search")}
                />
              </div>
            </AccordionContent>
          </div>
        </div>
      </AccordionItem>
    </Reorder.Item>
  );
}

export default WorkflowStateReorderItem;
