import {
  ChatAltIcon,
  ChatIcon,
  ClockIcon,
  CodeIcon,
  LightningBoltIcon,
  LinkIcon,
  PencilIcon,
  QuestionMarkCircleIcon,
  ShareIcon,
  TagIcon,
  UserAddIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import { MenuIcon } from "@heroicons/react/solid";
import { KanbanSquareIcon } from "lucide-react";
import MetaIcon from "src/components/icons/MetaIcon";
import WhatsAppIcon from "src/components/icons/WhatsAppIcon";
import { getTranslationPayload as t } from "src/i18n";
import {
  ActionStepDescription,
  ActiveCampaignAutomationEndStepDescription,
  ActiveCampaignAutomationStartStepDescription,
  ActiveCampaignContactGetStepDescription,
  ActiveCampaignContactUpsertStepDescription,
  ConditionalStepDescription,
  ContactUpdateStepDescription,
  ConversationStepDescription,
  DelayStepDescription,
  GoToStepDescription,
  HubspotContactGetStepDescription,
  HubspotContactUpsertStepDescription,
  MenuStepDescription,
  MessageStepDescription,
  QuestionStepDescription,
  TagStepDescription,
  TeamStepDescription,
  TemplateStepDescription,
  TriggerFlowStepDescription,
  WACAPIStepDescription,
  WAFlowStepDescription,
} from "../components/FlowBuilderAddStepDescription";
import FlowBuilderStepAction from "../components/steps/action/FlowBuilderStepAction";
import ActiveCampaignIcon from "../components/steps/activecampaign/ActiveCampaignIcon";
import FlowBuilderStepActiveCampaignAutomation from "../components/steps/activecampaign/FlowBuilderStepActiveCampaignAutomation";
import FlowBuilderStepActiveCampaignContactGet from "../components/steps/activecampaign/FlowBuilderStepActiveCampaignContactGet";
import FlowBuilderStepActiveCampaignContactUpsert from "../components/steps/activecampaign/FlowBuilderStepActiveCampaignContactUpsert";
import FlowBuilderStepConditional from "../components/steps/conditional/FlowBuilderStepConditional";
import FlowBuilderStepConditionalMultiple from "../components/steps/conditional_multiple/FlowBuilderStepConditionalMultiple";
import FlowBuilderStepContactUpdate from "../components/steps/contact/FlowBuilderStepContactUpdate";
import FlowBuilderStepConversation from "../components/steps/conversation/FlowBuilderStepConversation";
import FlowBuilderStepDelay from "../components/steps/delay/FlowBuilderStepDelay";
import FlowBuilderStepGoTo from "../components/steps/goto/FlowBuilderStepGoTo";
import HubSpotIcon from "../components/steps/hubspot/HubspotIcon";
import FlowBuilderStepHubspotContactRetreive from "../components/steps/hubspot/contact/FlowBuilderStepHubspotContactRetreive";
import FlowBuilderStepHubspotContactUpsert from "../components/steps/hubspot/contact/FlowBuilderStepHubspotContactUpsert";
import FlowBuilderStepMenu from "../components/steps/menu/FlowBuilderStepMenu";
import FlowBuilderStepMessage from "../components/steps/message/FlowBuilderStepMessage";
import FlowBuilderStepQuestion from "../components/steps/question/FlowBuilderStepQuestion";
import FlowBuilderStepTag from "../components/steps/tag/FlowBuilderStepTag";
import FlowBuilderStepTeam from "../components/steps/team/FlowBuilderStepTeam";
import FlowBuilderStepTemplate from "../components/steps/template/FlowBuilderStepTemplate";
import FlowBuilderStepTemplateMenu from "../components/steps/template/FlowBuilderStepTemplateMenu";
import FlowBuilderStepTriggerFlow from "../components/steps/trigger/FlowBuilderStepTriggerFlow";
import FlowBuilderStepWhatsAppCAPI from "../components/steps/wa_capi/FlowBuilderStepWhatsAppCAPI";
import FlowBuilderStepWhatsAppFlow from "../components/steps/wa_flow/FlowBuilderStepWhatsAppFlow";
import FlowBuilderStepWorkflowTask from "../components/steps/workflow_task/FlowBuilderStepWorkflowTask";

export const STEP_TYPES = {
  TEMPLATE: {
    name: t("flows:step-types.template.name", "Template"),
    icon: ChatAltIcon,
    component: FlowBuilderStepTemplate,
    docsLink:
      "https://hilos.io/docs/user/using-hilos/flows/step-types#template",
    descriptionComponent: TemplateStepDescription,
    deprecated: true,
  },
  TEMPLATE_MENU: {
    name: t("flows:step-types.template.name", "Template"),
    icon: ChatAltIcon,
    component: FlowBuilderStepTemplateMenu,
    docsLink:
      "https://hilos.io/docs/user/using-hilos/flows/step-types#template",
    descriptionComponent: TemplateStepDescription,
    deprecated: false,
  },
  MESSAGE: {
    name: t("flows:step-types.message.name", "Message"),
    icon: ChatIcon,
    component: FlowBuilderStepMessage,
    docsLink: "https://hilos.io/docs/user/using-hilos/flows/step-types#message",
    descriptionComponent: MessageStepDescription,
  },
  QUESTION: {
    name: t("flows:step-types.question.name", "Question"),
    icon: QuestionMarkCircleIcon,
    component: FlowBuilderStepQuestion,
    docsLink:
      "https://hilos.io/docs/user/using-hilos/flows/steps/question-step",
    descriptionComponent: QuestionStepDescription,
  },
  MENU: {
    name: t("flows:step-types.menu.name", "Menu"),
    icon: MenuIcon,
    component: FlowBuilderStepMenu,
    docsLink: "https://hilos.io/docs/user/using-hilos/flows/steps/menu-step",
    descriptionComponent: MenuStepDescription,
  },
  WA_FLOW: {
    name: t("flows:step-types.wa-flow.name", "WhatsApp Flow"),
    icon: WhatsAppIcon,
    component: FlowBuilderStepWhatsAppFlow,
    docsLink:
      "https://hilos.io/docs/user/using-hilos/flows/steps/whatsapp-flow-step",
    descriptionComponent: WAFlowStepDescription,
  },
  WA_CAPI: {
    name: t("flows:step-types.wa-capi.name", "Meta Conversion API"),
    icon: MetaIcon,
    component: FlowBuilderStepWhatsAppCAPI,
    docsLink:
      "https://hilos.io/docs/user/using-hilos/flows/steps/whatsapp-capi-step",
    descriptionComponent: WACAPIStepDescription,
  },
  GO_TO: {
    name: t("flows:step-types.go-to.name", "Go to"),
    icon: LinkIcon,
    component: FlowBuilderStepGoTo,
    docsLink: "https://hilos.io/docs/user/using-hilos/flows/steps/go-to-step",
    descriptionComponent: GoToStepDescription,
  },
  CONDITIONAL: {
    name: t("flows:step-types.conditional.name", "Conditional"),
    icon: ShareIcon,
    component: FlowBuilderStepConditional,
    docsLink:
      "https://hilos.io/docs/user/using-hilos/flows/steps/conditional-step",
    descriptionComponent: ConditionalStepDescription,
    deprecated: true,
  },
  CONDITIONAL_MULTIPLE: {
    name: t("flows:step-types.conditional.name", "Conditional"),
    icon: ShareIcon,
    component: FlowBuilderStepConditionalMultiple,
    docsLink:
      "https://hilos.io/docs/user/using-hilos/flows/steps/conditional-step",
    descriptionComponent: ConditionalStepDescription,
  },
  ACTION: {
    name: t("flows:step-types.action.name", "Action"),
    icon: CodeIcon,
    component: FlowBuilderStepAction,
    docsLink: "https://hilos.io/docs/user/using-hilos/flows/steps/action-step",
    descriptionComponent: ActionStepDescription,
  },
  CONVERSATION: {
    name: t("flows:step-types.conversation.name", "Conversation"),
    icon: UserAddIcon,
    component: FlowBuilderStepConversation,
    docsLink:
      "https://hilos.io/docs/user/using-hilos/flows/steps/assignation-steps#assigning-to-a-user",
    descriptionComponent: ConversationStepDescription,
  },
  TEAM: {
    name: t("flows:step-types.team.name", "Team"),
    icon: UserGroupIcon,
    component: FlowBuilderStepTeam,
    docsLink:
      "https://hilos.io/docs/user/using-hilos/flows/steps/assignation-steps#assigning-to-a-team",
    descriptionComponent: TeamStepDescription,
  },
  TAG: {
    name: t("flows:step-types.tag.name", "Tag"),
    icon: TagIcon,
    component: FlowBuilderStepTag,
    docsLink: "https://hilos.io/docs/user/using-hilos/flows/steps/tag-step",
    descriptionComponent: TagStepDescription,
  },
  DELAY: {
    name: t("flows:step-types.delay.name", "Delay"),
    icon: ClockIcon,
    component: FlowBuilderStepDelay,
    docsLink: "https://hilos.io/docs/user/using-hilos/flows/steps/delay-step",
    descriptionComponent: DelayStepDescription,
  },
  CONTACT_UPDATE: {
    name: t("flows:step-types.contact-update.name", "Contact Update"),
    icon: PencilIcon,
    component: FlowBuilderStepContactUpdate,
    docsLink:
      "https://hilos.io/docs/user/using-hilos/flows/steps/contact-update",
    descriptionComponent: ContactUpdateStepDescription,
  },
  TRIGGER_FLOW: {
    name: t("flows:step-types.trigger-flow.name", "Trigger flow"),
    icon: LightningBoltIcon,
    component: FlowBuilderStepTriggerFlow,
    docsLink:
      "https://hilos.io/docs/user/using-hilos/flows/steps/trigger-flow-step",
    descriptionComponent: TriggerFlowStepDescription,
  },
  WORKFLOW_TASK: {
    name: t(
      "flows:step-types.workflow-task.name",
      "Create task in CRM Workflow"
    ),
    icon: KanbanSquareIcon,
    component: FlowBuilderStepWorkflowTask,
    docsLink:
      "https://hilos.io/docs/user/using-hilos/flows/steps/workflow-task-step",
    descriptionComponent: TriggerFlowStepDescription,
    featureFlag: "workflows",
  },
  ACTIVECAMPAIGN_AUTOMATION_START: {
    name: t(
      "flows:step-types.activecampaign-automation-start.name",
      "Start ActiveCampaign automation"
    ),
    icon: ActiveCampaignIcon,
    component: FlowBuilderStepActiveCampaignAutomation,
    docsLink:
      "https://hilos.io/docs/user/using-hilos/flows/steps/contact-update",
    descriptionComponent: ActiveCampaignAutomationStartStepDescription,
  },
  ACTIVECAMPAIGN_AUTOMATION_END: {
    name: t(
      "flows:step-types.activecampaign-automation-end.name",
      "End ActiveCampaign automation"
    ),
    icon: ActiveCampaignIcon,
    component: FlowBuilderStepActiveCampaignAutomation,
    docsLink:
      "https://hilos.io/docs/user/using-hilos/flows/steps/contact-update",
    descriptionComponent: ActiveCampaignAutomationEndStepDescription,
  },
  ACTIVECAMPAIGN_CONTACT_GET: {
    name: t(
      "flows:step-types.activecampaign-contact-get.name",
      "Get ActiveCampaign contact"
    ),
    icon: ActiveCampaignIcon,
    component: FlowBuilderStepActiveCampaignContactGet,
    docsLink:
      "https://hilos.io/docs/user/using-hilos/flows/steps/contact-update",
    descriptionComponent: ActiveCampaignContactGetStepDescription,
  },
  ACTIVECAMPAIGN_CONTACT_UPSERT: {
    name: t(
      "flows:step-types.activecampaign-contact-upsert.name",
      "Create/Update ActiveCampaign contact"
    ),
    icon: ActiveCampaignIcon,
    component: FlowBuilderStepActiveCampaignContactUpsert,
    docsLink:
      "https://hilos.io/docs/user/using-hilos/flows/steps/contact-update",
    descriptionComponent: ActiveCampaignContactUpsertStepDescription,
  },
  HUBSPOT_CONTACT_UPSERT: {
    name: t(
      "flows:step-types.hubspot-contact-upsert.name",
      "Sync HubSpot contact"
    ),
    icon: HubSpotIcon,
    component: FlowBuilderStepHubspotContactUpsert,
    docsLink:
      "https://hilos.io/docs/user/using-hilos/flows/steps/contact-update",
    descriptionComponent: HubspotContactUpsertStepDescription,
  },
  HUBSPOT_CONTACT_GET: {
    name: t(
      "flows:step-types.hubspot-contact-retreive.name",
      "Get HubSpot contact"
    ),
    icon: HubSpotIcon,
    component: FlowBuilderStepHubspotContactRetreive,
    docsLink:
      "https://hilos.io/docs/user/using-hilos/flows/steps/contact-update",
    descriptionComponent: HubspotContactGetStepDescription,
  },
};

export const STEP_TYPES_WITHOUT_NEXT = [
  "CONVERSATION",
  "TEAM",
  "GO_TO",
  "TRIGGER_FLOW",
  "WORKFLOW_TASK",
];

export const STEP_TYPES_AVAILABLE_PLAN_SCALE = ["TEAM", "TRIGGER_FLOW"];

export type StepTypes = keyof typeof STEP_TYPES;
