import { useState } from "react";
import { useTranslation } from "react-i18next";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MailIcon, PlusSmIcon, UsersIcon } from "@heroicons/react/outline";
import Tabs from "src/components/Tabs";
import useHilosStore from "src/hooks/useHilosStore";
import HeaderNavigation from "../../components/HeaderNavigation";
import { buildRoute } from "../../router/router";
import UserInvitationList from "./invitation/UserInvitationList";
import UserInvitationModal from "./invitation/UserInvitationModal";
import UserList from "./user/UserList";

export default function AccountUserList() {
  const { t } = useTranslation();
  const { session } = useHilosStore();

  const [open, setOpen] = useState(false);
  const [triggerReloadInvitations, setTriggerReloadInvitations] =
    useState(false);

  const reloadInvitations = () => {
    setTriggerReloadInvitations(!triggerReloadInvitations);
  };

  return (
    <div className="h-screen overflow-y-auto bg-gray-50">
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("settings"),
              url: buildRoute("config-account"),
            },
            {
              name: t("team-members", "Team members"),
              url: buildRoute("account-members"),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <FontAwesomeIcon
                icon={faUserFriends}
                className="mr-4 h-7 w-7"
                aria-hidden="true"
              />
              {t("team-members", "Team members")}
            </h2>
          </div>
          <div className="mt-2 flex md:mt-5 lg:mt-0 lg:ml-4">
            <span className="md:ml-3">
              {session?.account.pricing_model_version === "v1" && (
                <button
                  type="button"
                  className="inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-violet-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
                  onClick={(_) => setOpen(true)}
                >
                  <PlusSmIcon
                    className="-ml-1 mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  {t(
                    "settings:team.invite-team-members.label",
                    "Invite team members"
                  )}
                </button>
              )}
            </span>
          </div>
        </div>
      </div>

      <Tabs
        tabs={[
          {
            label: t("team-members", "Team members"),
            icon: UsersIcon,
          },
          {
            label: t("pending-invitations", "Pending Invitations"),
            icon: MailIcon,
          },
        ]}
        className="px-4 sm:px-6"
      >
        <UserList />
        <UserInvitationList
          setOpen={setOpen}
          reloadInvitations={reloadInvitations}
          reloadToggle={triggerReloadInvitations}
        />
      </Tabs>

      <UserInvitationModal
        open={open}
        setOpen={setOpen}
        reloadInvitations={reloadInvitations}
      />
    </div>
  );
}
