import { Fragment, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  AdjustmentsIcon,
  InformationCircleIcon,
  PencilAltIcon,
  PlusSmIcon,
} from "@heroicons/react/outline";
import axios from "axios";
import { DateTime } from "luxon";
import { axiosErr } from "@hilos/types/axios";
import { Role } from "@hilos/types/private-schema";
import HeaderNavigation from "src/components/HeaderNavigation";
import HelpDocsLink from "src/components/HelpDocsLink";
import PermissionsChecker from "src/components/PermissionsCheck";
import Table from "src/components/Table/Table";
import useHilosStore from "src/hooks/useHilosStore";
import { userAvatar } from "src/Helpers";
import DeleteObject from "../../../components/DeleteObject";
import { API_ROUTES, buildAPIRoute, buildRoute } from "../../../router/router";

export default function RoleList() {
  const { session } = useHilosStore();
  const { t } = useTranslation();
  const [triggerReload, setTriggerReload] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);

  const onDelete = async (
    formData,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.delete(
        buildAPIRoute(API_ROUTES.ROLE_DETAIL, {
          ":id": formData.obj.id,
        })
      );
      setDeleteSubmitted(false);
      setDeleteSuccess(true);
      setTriggerReload(!triggerReload);
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;
      if (errorAxios?.response?.status === 400) {
        console.log("error", errorAxios);
        setBackendValidationErrors(errorAxios.response.data);
      } else {
        setBackendError(
          t("error-general", "An error occurred, please try again.")
        );
      }
      setTimeout(() => {
        setDeleteSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setDeleteSubmitted(false);
    }
  };

  if (!session) {
    return null;
  }

  return (
    <div className="h-screen overflow-y-auto bg-gray-50">
      {session.account.pricing_model_version === "v2" &&
        session.account.pricing_plan_selected === "LAUNCH" && (
          <div className="rounded-md bg-blue-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon
                  className="h-5 w-5 text-blue-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-blue-700">
                  {t(
                    "settings:billing.launch.roles-notice",
                    "Add more roles and customize them when you upgrade to the Scale plan."
                  )}
                </p>
                <p className="mt-3 text-sm md:ml-6 md:mt-0">
                  <Link
                    to={buildRoute("config-account-subscription")}
                    className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                  >
                    {t("upgrade", "Upgrade")}
                    <span aria-hidden="true"> &rarr;</span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        )}
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("settings"),
              url: buildRoute("config-account"),
            },
            {
              name: t("team-members", "Team members"),
              url: buildRoute("account-members"),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <AdjustmentsIcon className="mr-4 h-7 w-7" aria-hidden="true" />
              {t("settings:roles.title", "Roles & Permissions")}
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              <Trans i18nKey="settings:roles.description">
                Here you can see and update the roles and permissions your users
                have.
                <HelpDocsLink
                  href="https://hilos.io/docs/user/using-hilos/templates/creating-a-template"
                  className="ml-1 text-blue-400"
                />
              </Trans>
            </p>
          </div>
          {session.account.pricing_model_version === "v2" &&
            session.account.pricing_plan_selected !== "LAUNCH" && (
              <div className="mt-2 flex md:mt-5 lg:mt-0 lg:ml-4">
                <span className="md:ml-3">
                  <PermissionsChecker
                    permission="add_role"
                    notice={
                      <button
                        className="focus:ring-hilos-500 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-4 text-gray-400 line-through shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 mr-2"
                        type="button"
                        disabled
                      >
                        <PlusSmIcon
                          className="-ml-0.5 mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        {t("settings:role.create-button", "New role")}
                      </button>
                    }
                  >
                    <Link
                      to={buildRoute("role-create")}
                      className="inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-violet-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2 mr-2"
                    >
                      <PlusSmIcon
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      {t("settings:role.create-button", "New role")}
                    </Link>
                  </PermissionsChecker>
                </span>
              </div>
            )}
        </div>
      </div>

      <div className="h-full bg-gray-50 sm:px-6 sm:pt-8 lg:px-8">
        <Table<Role>
          baseURL={buildAPIRoute(API_ROUTES.ROLE_LIST)}
          paginated={false}
          tableHead={
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("role", "Role")}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-right text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("users")}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("created-on", "Created on")}
                </th>
                {session.role.name === "Admin" && (
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                  ></th>
                )}
              </tr>
            </thead>
          }
          tableRow={(role) => (
            <>
              <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-900">
                <Link
                  to={buildRoute("role-update", { id: role.id })}
                  className="font-medium text-blue-500"
                >
                  {role.name}
                </Link>
              </td>
              <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                <div className="flex justify-end -space-x-1 overflow-hidden">
                  {role.users.map((user) => (
                    <Fragment key={user.id}>
                      {userAvatar(
                        user,
                        "h-6 w-6 text-xs ring-2 ring-white group-hover:ring-gray-300"
                      )}
                    </Fragment>
                  ))}
                </div>
              </td>
              <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                <time className="text-sm text-gray-500" dateTime="2020-03-22">
                  {DateTime.fromISO(role.created_on as string).toLocaleString(
                    DateTime.DATETIME_SHORT
                  )}
                </time>
              </td>
              <td className="flex items-center justify-end px-4 py-3">
                <PermissionsChecker
                  permission="change_role"
                  notice={
                    <button
                      className="focus:ring-hilos-500 mr-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-400 line-through shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100"
                      type="button"
                      disabled
                    >
                      <PencilAltIcon
                        className="-ml-0.5 mr-2 h-4 w-4"
                        aria-hidden="true"
                      />
                      {t("update", "Update")}
                    </button>
                  }
                >
                  <Link
                    to={buildRoute("role-update", { id: role.id })}
                    className="focus:ring-hilos-500 mr-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100"
                  >
                    <PencilAltIcon
                      className="mr-2 h-4 w-4"
                      aria-hidden="true"
                    />
                    {t("settings:role.update-role", "Update role")}
                  </Link>

                  {!["Admin", "Agent"].includes(session.role.name) && (
                    <DeleteObject
                      obj={role}
                      objDescription={role.name}
                      actionDescription={t(
                        "settings:role.delete-description",
                        "Removing this role will cause the users assigned to it to lose this role's permissions."
                      )}
                      onDelete={onDelete}
                      deleteText={t(
                        "settings:role.delete-button",
                        "Delete role"
                      )}
                      deleteSuccess={deleteSuccess}
                      deleteSubmitted={deleteSubmitted}
                    />
                  )}
                </PermissionsChecker>
              </td>
            </>
          )}
          dataName={t("roles", "Roles")}
          noResults={
            <>
              <Trans i18nKey="settings:role.no-results-with-filters">
                <h4>Couldn't find any roles with these filters.</h4>
                <p className="text-gray-500">Remove filters to try again.</p>
              </Trans>
            </>
          }
          triggerReload={triggerReload}
        />
      </div>
    </div>
  );
}
