import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ChannelAvailabilityData } from "@hilos/types/channel";
import Loading from "src/components/Loading";
import { getQueryFilters } from "src/helpers/inbox";
import useHilosStore from "src/hooks/useHilosStore";
import { useInboxContactCounts } from "src/hooks/useInboxContactCounts";
import { InboxContactViewData } from "src/hooks/useInboxContactViews";

interface ViewButtonProps {
  view: InboxContactViewData;
  isSelected: boolean;
  currentAvailableChannels: ChannelAvailabilityData[];
  allowQueryCountWithLimit: boolean;
  onClick: (id: string) => void;
  isTemporary?: boolean;
}

const ViewButton = memo(
  ({
    view,
    isSelected,
    currentAvailableChannels,
    allowQueryCountWithLimit,
    onClick,
    isTemporary = false,
  }: ViewButtonProps) => {
    const { t } = useTranslation();
    const { session } = useHilosStore();

    const filters = useMemo(() => {
      try {
        const inboxParams =
          typeof view.data === "string"
            ? JSON.parse(view.data as string)
            : view.data;
        const filters = getQueryFilters({
          session,
          inboxParams,
          currentAvailableChannels,
        });

        return filters;
      } catch (error) {
        console.error("Error parsing view data:", error);
        return null;
      }
    }, [session, view.data, currentAvailableChannels]);

    const { count, loading } = useInboxContactCounts({
      filters,
      session,
      isSelected,
      allowQueryCountWithLimit,
    });

    const inboxContactsCount = useMemo(() => {
      return count || 0;
    }, [count]);

    return (
      <button
        onClick={() => {
          onClick(view.id);
        }}
        className={`inline-flex text-nowrap items-center space-x-2 rounded-full px-3 py-1 text-sm flex-shrink-0 ${
          isSelected
            ? isTemporary
              ? "bg-indigo-100 text-indigo-700"
              : "bg-indigo-600 text-white"
            : "bg-gray-100 text-gray-700 hover:bg-gray-200"
        }`}
      >
        <span>{t(view.name, view.name)}</span>
        <span className="px-1.5 py-0.5 bg-opacity-10 bg-black rounded-full">
          {loading ? (
            <div className="w-4 h-4 overflow-hidden">
              <Loading showText={false} />
            </div>
          ) : inboxContactsCount > 9999 ? (
            "9999+"
          ) : (
            inboxContactsCount
          )}
        </span>
      </button>
    );
  }
);

ViewButton.displayName = "ViewButton";

export default ViewButton;
