import { TFunction } from "i18next";
import * as yup from "yup1";
import { FlowStepData } from "@hilos/types/flow";
import { ERROR_MESSAGES } from "src/constants/errors";
import { FlowStepBaseSchema } from "../flow";

export const FlowStepWorkflowTaskSchema = (t: TFunction) =>
  FlowStepBaseSchema(t).shape({
    workflow: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
    state_to_move_to: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
    assign_to_teams: yup
      .array()
      .nullable()
      .when("assignment_type", {
        is: (assignmentType) => assignmentType === "TEAM",
        then: (schema) =>
          schema
            .required(t(ERROR_MESSAGES.REQUIRED))
            .of(yup.number().required(t(ERROR_MESSAGES.REQUIRED)))
            .min(1, t(ERROR_MESSAGES.MIN_NUMBER)),
      }),
    assign_from_variable: yup
      .string()
      .nullable()
      .when("assignment_type", {
        is: (assignmentType) => assignmentType === "VARIABLE",
        then: (schema) => schema.required(t(ERROR_MESSAGES.REQUIRED)),
      }),
  });

export const FlowStepWorkflowTaskFormat = (step: FlowStepData) => {
  const nextStep = { ...step };

  if (!nextStep.workflow) {
    delete nextStep.state_to_move_to;
  }

  return nextStep;
};
