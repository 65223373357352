import { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  ArrowSmRightIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { Formik } from "formik";
import SwitchField from "src/components/Form/SwitchField";
import TextInputField from "src/components/Form/TextInputField";
import StateButton from "src/components/StateButton";
import APIErrors from "src/components/base/APIErrors";
import * as meta from "./Meta";

interface RoleFormProps {
  data: { name: string; permissions: any; can_be_deleted: boolean | undefined };
  formSubmit: (
    formData: any,
    setSubmitting: any,
    setBackendValidationErrors: any,
    setBackendError: any
  ) => void;
  success: boolean;
  submitted: boolean;
}

export default function RoleForm({
  data,
  formSubmit,
  success,
  submitted,
}: RoleFormProps) {
  const { t } = useTranslation();
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});

  const getPermissionsByCategory = useCallback((category) => {
    return Object.getOwnPropertyNames(meta.PERMISSIONS).filter(
      (perm) => meta.PERMISSIONS[perm].category === category
    );
  }, []);

  return (
    <Formik
      onSubmit={(values, { setSubmitting }) =>
        formSubmit(
          values,
          setSubmitting,
          setBackendValidationErrors,
          setBackendError
        )
      }
      enableReinitialize={true}
      initialValues={data}
    >
      {(formik) => (
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="my-4">
            <APIErrors
              APIError={backendError}
              APIValidationErrors={backendValidationErrors}
              fieldTranslations={meta.FIELDS}
            />
          </div>

          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <Trans i18nKey="settings:role.basic-info">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Basic Info
                  </h3>
                </Trans>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="shadow sm:overflow-hidden sm:rounded-md">
                {!data.can_be_deleted && (
                  <div className="rounded-md bg-blue-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <InformationCircleIcon
                          className="h-5 w-5 text-blue-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3 text-sm text-blue-700">
                        {t(
                          "settings:role.default-role-notice",
                          "This role is a default role, so you won't be able to change its name or permissions."
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className="space-y-4 bg-white px-4 py-5 sm:p-6">
                  <TextInputField
                    placeholder={t(meta.FIELDS.name.placeholder)}
                    label={t(meta.FIELDS.name.label)}
                    help={t(meta.FIELDS.name.help)}
                    name={meta.FIELDS.name.key}
                    disabled={!data.can_be_deleted}
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-10 sm:mt-4">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <Trans
                    i18nKey="settings:role.permissions"
                    components={{
                      0: (
                        // eslint-disable-next-line jsx-a11y/heading-has-content
                        <h3 className="text-lg font-medium leading-6 text-gray-900" />
                      ),
                      1: <p className="mt-1 text-sm text-gray-600" />,
                    }}
                  />
                </div>
              </div>
              <div className="mt-5 md:col-span-2 md:mt-0">
                <div className="shadow sm:overflow-hidden sm:rounded-md">
                  <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                    {Object.getOwnPropertyNames(meta.PERMISSION_CATEGORIES).map(
                      (category) => (
                        <div key={category}>
                          <h4 className="flex items-center text-tiny font-medium uppercase tracking-wider text-gray-500">
                            {meta.PERMISSION_CATEGORIES[category].icon}
                            {t(meta.PERMISSION_CATEGORIES[category].label)}
                          </h4>
                          <div className="space-y-2 mt-2">
                            {getPermissionsByCategory(category).map(
                              (permission) => (
                                <SwitchField
                                  name={`permissions.${permission}`}
                                  label={t(meta.PERMISSIONS[permission].label)}
                                  disabled={!data.can_be_deleted}
                                />
                              )
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <div className="text-right">
              <StateButton
                isSubmitting={formik.isSubmitting}
                submitted={submitted}
                success={success}
                btnClasses={`inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-violet-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2 ${
                  formik.errors &&
                  Object.getOwnPropertyNames(formik.errors).length > 0
                    ? "disabled:opacity-50 cursor-not-allowed"
                    : ""
                }`}
                disabled={
                  !data.can_be_deleted ||
                  (formik.errors &&
                    Object.getOwnPropertyNames(formik.errors).length > 0)
                }
                submittingText={t("updating")}
                successText={t("updated")}
                initialText={
                  <>
                    {t("update")}
                    <ArrowSmRightIcon
                      className="ml-1 h-5 w-5"
                      aria-hidden="true"
                    />
                  </>
                }
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}
