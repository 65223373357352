import { ChangeEvent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import debounce from "lodash/debounce";
import { WorkflowStateRead } from "@hilos/types/private-schema";
import { Input } from "src/components/ui/input";
import useAccount from "src/hooks/useAccount";
import { userToString } from "src/Helpers";
import {
  WorkflowBoardStore,
  useWorkflowBoardContext,
} from "./WorkflowBoardContext";
import { WorkflowBoardFilterOptions } from "./WorkflowBoardFilterOptions";
import { WorkflowBoardSort } from "./WorkflowBoardSort";

interface WorkflowBoardFiltersProps {
  states: WorkflowStateRead[];
}

export default function WorkflowBoardFilters({
  states,
}: WorkflowBoardFiltersProps) {
  const [t] = useTranslation();
  const { members } = useAccount();
  const { columns, sortBy, onUpdateColumns, onUpdateFilters, onUpdateSortBy } =
    useWorkflowBoardContext<WorkflowBoardStore>();

  const handleSearch = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      onUpdateFilters({ search: event.target.value || "" });
    }, 400),
    [onUpdateFilters]
  );

  const handleAssignedTo = useCallback(
    (assigned: number[]) => {
      onUpdateFilters({ assigned });
    },
    [onUpdateFilters]
  );

  return (
    <motion.div
      initial={{
        height: 0,
        opacity: 0,
        marginBottom: 0,
      }}
      animate={{
        height: "auto",
        opacity: 1,
        marginBottom: "1rem",
      }}
      exit={{
        height: 0,
        opacity: 0,
        marginBottom: 0,
      }}
      transition={{
        layout: { duration: 0.3 },
        height: { duration: 0.4 },
        opacity: { duration: 0.2 },
      }}
      layout
      className="flex items-center justify-start w-full flex-wrap gap-2 px-4 sm:px-6 lg:px-8 lg:flex-nowrap"
    >
      <Input
        placeholder={t(
          "workflows:filters.search.placeholder",
          "Filter tasks..."
        )}
        onChange={handleSearch}
        className="h-8 max-w-96 sm:w-60 focus-visible:outline-none focus-visible:ring-offset-0 focus-visible:ring-1 focus-visible:ring-zinc-400"
      />
      <WorkflowBoardFilterOptions
        title={t("workflows:filters.assigned-to.title", "Assigned to")}
        options={members.map((user) => ({
          value: user.id,
          label: userToString(user),
        }))}
        // @ts-ignore
        onUpdateSelected={handleAssignedTo}
      />
      <WorkflowBoardFilterOptions
        title={t("workflows:filters.states.title", "States")}
        options={states.map((state) => ({
          value: state.id,
          label: state.name || "",
        }))}
        selected={columns}
        minSelection={2}
        onUpdateSelected={onUpdateColumns}
      />
      <WorkflowBoardSort sortBy={sortBy} onUpdateSortBy={onUpdateSortBy} />
    </motion.div>
  );
}
