import { FlowExecutionStepReadDetail } from "@hilos/types/private-schema";
import HTTPResponseRenderResponse from "src/components/HTTPResponseRender/HTTPResponseRenderResponse";

interface FlowExecutionStepActiveCampaignContactGetDetailProps {
  executionStep: FlowExecutionStepReadDetail;
}

export default function FlowExecutionStepActiveCampaignContactGetDetail({
  executionStep,
}: FlowExecutionStepActiveCampaignContactGetDetailProps) {
  return (
    <HTTPResponseRenderResponse
      response={executionStep.execution_result.response}
    />
  );
}
