import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
} from "@heroicons/react/outline";
import { QUALITY_ENTITY_STATUSES, QUALITY_ENTITY_TYPES } from "./Meta";

interface EntityError {
  error_code: number;
  error_description: string;
  possible_solution?: string;
}

interface Entity {
  entity_type: string;
  id: string;
  can_send_message: string;
  errors?: EntityError[];
  additional_info?: string[];
}

interface HealthStatus {
  can_send_message: string;
  entities: Entity[];
}

interface ChannelQualityNoticesProps {
  healthStatus?: HealthStatus;
}

interface QualityNoticeItem {
  id: string;
  entityType: string;
  status: string;
  description: string;
  solution?: string;
}

const getStatusIcon = (status: string) => {
  switch (status) {
    case "BLOCKED":
      return <ExclamationCircleIcon className="h-5 w-5 text-red-500" />;
    case "LIMITED":
      return <ExclamationIcon className="h-5 w-5 text-yellow-500" />;
    case "AVAILABLE":
      return <CheckCircleIcon className="h-5 w-5 text-green-500" />;
    default:
      return <ExclamationIcon className="h-5 w-5 text-gray-500" />;
  }
};

const StatusCell = ({ status }: { status: string }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`inline-flex items-center rounded-full px-2.5 py-0.5 ${QUALITY_ENTITY_STATUSES[status].className}`}
    >
      {getStatusIcon(status)}
      <span className="ml-1 text-xs font-medium">
        {t(QUALITY_ENTITY_STATUSES[status].label)}
      </span>
    </div>
  );
};

export default function ChannelQualityNotices({
  healthStatus,
}: ChannelQualityNoticesProps) {
  const { t } = useTranslation();

  const noticeItems = useMemo(() => {
    if (!healthStatus?.entities?.length) {
      return [];
    }

    return healthStatus.entities.flatMap((entity) => {
      const baseItems: QualityNoticeItem[] = [];

      if (entity.errors?.length) {
        entity.errors.forEach((error) => {
          baseItems.push({
            id: `${entity.id}-${error.error_code}`,
            entityType: entity.entity_type,
            status: entity.can_send_message,
            description: error.error_description,
            solution: error.possible_solution,
          });
        });
      }

      if (entity.additional_info?.length) {
        entity.additional_info.forEach((info, index) => {
          baseItems.push({
            id: `${entity.id}-info-${index}`,
            entityType: entity.entity_type,
            status: entity.can_send_message,
            description: info,
          });
        });
      }

      if (baseItems.length === 0) {
        baseItems.push({
          id: entity.id,
          entityType: entity.entity_type,
          status: entity.can_send_message,
          description:
            entity.can_send_message === "AVAILABLE"
              ? t("channel:quality-notices.no-issues", "No issues detected")
              : t("channel:quality-notices.status-only", "Status: {{status}}", {
                  status: entity.can_send_message,
                }),
        });
      }

      return baseItems;
    });
  }, [healthStatus, t]);

  if (!healthStatus) {
    return (
      <div className="text-center p-6 text-gray-500">
        {t("channel:quality-notices.no-data", "No quality data available")}
      </div>
    );
  }

  return (
    <div className="mt-4 p-4">
      <div className="flex items-center mb-4">
        <h3 className="text-lg font-medium text-gray-900">
          {t(
            "channel:quality-notices.can-send-messages",
            "Message delivery status"
          )}
        </h3>
        <div
          className={`ml-3 inline-flex items-center rounded-full px-3 py-0.5 ${
            QUALITY_ENTITY_STATUSES[healthStatus.can_send_message].className
          }`}
        >
          {getStatusIcon(healthStatus.can_send_message)}
          <span className="ml-1 text-sm font-medium">
            {t(QUALITY_ENTITY_STATUSES[healthStatus.can_send_message].label)}
          </span>
        </div>
      </div>

      <div className="space-y-4 overflow-y-auto pr-2">
        {noticeItems.length === 0 ? (
          <div className="bg-white rounded-md p-4 text-center text-gray-500">
            {t(
              "channel:quality-notices.no-notices",
              "No quality notices found"
            )}
          </div>
        ) : (
          noticeItems.map((item) => (
            <div
              key={item.id}
              className="bg-white rounded-md p-4 border border-gray-200 shadow-sm"
            >
              <div className="flex justify-between items-center mb-3">
                <div className="font-medium text-gray-900">
                  <span className="font-bold">
                    {t(QUALITY_ENTITY_TYPES[item.entityType].label)}
                  </span>
                </div>
                <StatusCell status={item.status} />
              </div>
              <div className="border-t border-gray-200 pt-3">
                <div className="mb-2">
                  <span className="text-sm font-medium text-gray-500">
                    {t("channel:quality-notices.description", "Description")}:
                  </span>
                  <p className="mt-1 text-gray-900">{item.description}</p>
                </div>
                {item.solution && (
                  <div className="mt-3">
                    <span className="text-sm font-medium text-gray-500">
                      {t(
                        "channel:quality-notices.solution",
                        "Recommended Action"
                      )}
                      :
                    </span>
                    <p className="mt-1 text-gray-900">{item.solution}</p>
                  </div>
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}
