import { Dispatch, SetStateAction, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ArrowSmRightIcon } from "@heroicons/react/outline";
import { Formik } from "formik";
import { ConversationTag } from "@hilos/types/private-schema";
import * as meta from "src/containers/tags/ConversationTagMeta";
import TextInputField from "src/components/Form/TextInputField";
import StateButton from "src/components/StateButton";
import APIErrors from "src/components/base/APIErrors";

export type ConversationTagFormSubmit = (
  formData: ConversationTag,
  setSubmitting: Dispatch<SetStateAction<boolean>>,
  setBackendValidationErrors: Dispatch<SetStateAction<{}>>,
  setBackendError: Dispatch<SetStateAction<string>>
) => void;

export interface ConversationTagFormProps {
  conversationTag: ConversationTag;
  formSubmit: ConversationTagFormSubmit;
  success: boolean;
  submitted: boolean;
  action: "create" | "update";
}

export default function ConversationTagForm({
  conversationTag,
  formSubmit,
  success,
  submitted,
  action,
}: ConversationTagFormProps) {
  const { t } = useTranslation();
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});

  const onSubmit = (
    values: ConversationTag,
    setSubmitting: (boolean) => void
  ) => {
    formSubmit(
      values,
      setSubmitting,
      setBackendValidationErrors,
      setBackendError
    );
  };

  return (
    <Formik
      validationSchema={meta.schema}
      onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
      enableReinitialize={true}
      initialValues={conversationTag}
    >
      {(formik) => (
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="my-4">
            <APIErrors
              APIError={backendError}
              APIValidationErrors={backendValidationErrors}
              fieldTranslations={meta.FIELDS}
            />
          </div>

          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <Trans i18nKey="settings:conversation-tags.form.description">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Conversation Tag
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    Name your conversation tag
                  </p>
                </Trans>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="shadow sm:overflow-hidden sm:rounded-md">
                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3">
                      <TextInputField
                        label={t(meta.FIELDS.name.label)}
                        name={meta.FIELDS.name.key}
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <div className="text-right">
              <StateButton
                isSubmitting={formik.isSubmitting}
                submitted={submitted}
                success={success}
                btnClasses={`inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-violet-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2 ${
                  formik.errors &&
                  Object.getOwnPropertyNames(formik.errors).length > 0
                    ? "disabled:opacity-50 cursor-not-allowed"
                    : ""
                }`}
                disabled={
                  formik.errors &&
                  Object.getOwnPropertyNames(formik.errors).length > 0
                }
                submittingText={
                  action === "create" ? t("creating") : t("updating")
                }
                successText={action === "create" ? t("created") : t("updated")}
                initialText={
                  <>
                    {action === "create" ? t("create") : t("update")}
                    <ArrowSmRightIcon
                      className="ml-1 h-5 w-5"
                      aria-hidden="true"
                    />
                  </>
                }
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}
