import { ReactElement, useMemo } from "react";
import { classNames } from "../Helpers";

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  icon?: ReactElement;
  variant?: "outlined" | "contained" | "text";
  isSubmitting?: boolean;
}

function Button({
  icon,
  variant = "contained",
  isSubmitting,
  className,
  children,
  disabled,
  ...props
}: ButtonProps) {
  const btnClassName = useMemo(() => {
    const variantClassName = {
      outlined: [
        "border-gray-300 bg-transparent text-gray-300",
        `border-violet-400 bg-transparent text-violet-600 hover:border-hilos hover:text-hilos hover:bg-violet-50 active:bg-violet-100`,
      ],
      contained: [
        "border-transparent bg-gray-200 text-gray-500",
        "inline-flex transition-all bg-size-200 ease-in bg-left-top duration-300 border-transparent text-white bg-gradient-to-r from-hilos via-violet-400 to-hilos active:via-hilos active:to-violet-800 hover:bg-right-bottom",
      ],
      text: [
        "border-none bg-transparent text-gray-300",
        `border-none bg-transparent text-hilos hover:bg-violet-50 active:bg-violet-100`,
      ],
    }[variant];
    return classNames(
      "flex items-center justify-center rounded-md border px-4 py-2 text-sm font-medium",
      !disabled &&
        "focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-1",
      variantClassName && (disabled || isSubmitting)
        ? variantClassName[0]
        : variantClassName[1],
      className
    );
  }, [variant, disabled, isSubmitting, className]);

  return (
    <button className={btnClassName} disabled={disabled} {...props}>
      <>
        {children}
        {isSubmitting ? (
          <svg
            className="ml-1 -mr-1 h-4 w-4 animate-spin text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        ) : (
          icon
        )}
      </>
    </button>
  );
}
export default Button;
