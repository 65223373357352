import { Fragment, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  PencilAltIcon,
  PlusSmIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import axios from "axios";
import { axiosErr } from "@hilos/types/axios";
import { TeamData } from "@hilos/types/team";
import DeleteObject from "src/components/DeleteObject";
import HeaderNavigation from "src/components/HeaderNavigation";
import HelpDocsLink from "src/components/HelpDocsLink";
import Table from "src/components/Table/Table";
import { userAvatar, userToString } from "src/Helpers";
import { API_ROUTES, buildAPIRoute, buildRoute } from "src/router/router";
import TeamBadge from "./TeamBadge";

function TeamList() {
  const { t } = useTranslation();
  const [triggerReload, setTriggerReload] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);

  const onDelete = async (
    formData,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.delete(
        buildAPIRoute(API_ROUTES.TEAM_DETAIL, {
          ":id": formData.obj.id,
        })
      );
      setDeleteSubmitted(false);
      setDeleteSuccess(true);
      setTriggerReload(!triggerReload);
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;
      setDeleteSuccess(false);
      if (errorAxios?.response?.status === 400) {
        console.log("error", errorAxios);
        setBackendValidationErrors(errorAxios.response.data);
      } else {
        setBackendError(t("error-general"));
      }
      setTimeout(() => {
        setDeleteSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setDeleteSubmitted(false);
    }
  };

  return (
    <>
      <div className="h-screen overflow-y-auto bg-gray-50">
        {/* Header */}
        <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
          <HeaderNavigation
            navPages={[
              {
                name: t("home"),
                url: buildRoute("dashboard"),
              },
              {
                name: t("settings"),
                url: buildRoute("config-account"),
              },
              {
                name: t("team-list"),
                url: buildRoute("config-team-list"),
              },
            ]}
          />
          <div className="lg:flex lg:items-center lg:justify-between">
            <div className="min-w-0 flex-1">
              <Trans i18nKey="settings:team.team-description">
                <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                  <UserGroupIcon className="mr-2 h-7 w-7" aria-hidden="true" />
                  Teams
                </h2>
                <p className="mt-1 text-sm text-gray-600">
                  Organize your users into teams to make it easier to organise
                  your conversations.
                  <HelpDocsLink
                    href="https://hilos.io/docs/user/using-hilos/settings/teams"
                    className="ml-1 text-blue-400"
                  />
                </p>
              </Trans>
            </div>
            <div className="mt-2 flex md:mt-5 lg:mt-0 lg:ml-4">
              <span className="md:ml-3">
                <Link
                  to={buildRoute("config-team-create")}
                  className="inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-violet-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
                >
                  <PlusSmIcon
                    className="-ml-1 mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  {t("new-team")}
                </Link>
              </span>
            </div>
          </div>
        </div>

        <div className="min-h-screen bg-gray-50">
          <div className="h-full sm:px-6 sm:pt-8 lg:px-8">
            <Table
              baseURL={buildAPIRoute(API_ROUTES.TEAM_LIST_CREATE)}
              paginated={false}
              triggerReload={triggerReload}
              tableHead={
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("name")}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t(
                        "settings:team.assignment-strategy.label",
                        "Assignment strategy"
                      )}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-right text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("users")}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    ></th>
                  </tr>
                </thead>
              }
              tableRow={(team: TeamData) => (
                <>
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-900">
                    <Link
                      className="font-medium text-blue-600"
                      to={buildRoute("config-team-update", {
                        id: team.id,
                      })}
                    >
                      {team.name}
                    </Link>
                  </td>
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                    <TeamBadge assignmentStrategy={team.assignment_strategy} />
                    {team.assignment_strategy === "USE_DEFAULT" && (
                      <div className="text-xs">
                        {t("default-user")}:{" "}
                        {userToString(team.default_assignee)}
                      </div>
                    )}
                  </td>
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                    <div className="flex justify-end -space-x-1 overflow-hidden">
                      {team.users.map((user) => (
                        <Fragment key={user.id}>
                          {userAvatar(
                            user,
                            "h-6 w-6 text-xs ring-2 ring-white group-hover:ring-gray-300"
                          )}
                        </Fragment>
                      ))}
                    </div>
                  </td>
                  <td className="flex items-center px-4 py-3">
                    <Link
                      className="focus:ring-hilos-500 mr-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100"
                      to={buildRoute("config-team-update", {
                        id: team.id,
                      })}
                    >
                      <PencilAltIcon
                        className="-ml-0.5 mr-2 h-4 w-4"
                        aria-hidden="true"
                      />
                      {t("update")}
                    </Link>
                    <DeleteObject
                      obj={team}
                      objDescription={team.name}
                      actionDescription="Please make sure you're not using this team to assign inbox users in any flows."
                      onDelete={onDelete}
                      deleteSuccess={deleteSuccess}
                      deleteSubmitted={deleteSubmitted}
                    />
                  </td>
                </>
              )}
              dataName={t("settings:team.table-name", "Teams")}
              noResults={
                <>
                  <h4>Couldn't find any teams with these filters.</h4>
                  <p className="text-muted">Remove filters to try again.</p>
                </>
              }
              createObject={
                <>
                  <Trans i18nKey="settings:team.no-teams-yet">
                    <h4>Looks like you don't have any teams yet.</h4>
                    <p className="text-sm text-gray-500">
                      Why don't you create one?
                    </p>
                  </Trans>
                  <Link
                    to={buildRoute("config-team-create")}
                    className="mt-2 inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-violet-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
                  >
                    <PlusSmIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    {t("new-team")}
                  </Link>
                </>
              }
            ></Table>
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamList;
