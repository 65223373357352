import { useTranslation } from "react-i18next";
import SelectorField from "src/components/Form/SelectorField";
import useAccount from "src/hooks/useAccount";
import { useTeams } from "src/hooks/useTeam";
import { userToString } from "src/Helpers";
import { useStepField } from "../../../hooks/useStepField";
import useVariables from "../../../hooks/useVariables";
import FormatGroupLabelVariable from "../../FormatGroupLabelVariable";
import FormatOptionLabelTeam from "../../FormatOptionLabelTeam";
import FormatOptionLabelUser from "../../FormatOptionLabelUser";

interface FlowBuilderStepWorkflowTaskProps {
  index: number;
}

function FlowBuilderStepWorkflowTaskAssignmentTypes({
  index,
}: FlowBuilderStepWorkflowTaskProps) {
  const { t } = useTranslation();
  const { members } = useAccount();
  const teams = useTeams();
  const [assignmentType] = useStepField({
    index,
    name: "assignment_type",
  });
  const { options } = useVariables({
    currentStepIndex: index,
    allowedVariableTypes: ["step", "contact", "flow"],
    allowedVariableDataTypes: ["text"],
  });

  switch (assignmentType) {
    case "USER":
      return (
        <SelectorField
          isMulti
          isCreatable={false}
          name={`steps.${index}.assign_to_users`}
          label={t(
            "flows:steps.workflow-task.assign-to-users.label",
            "User(s) to assign this task to"
          )}
          placeholder={t(
            "flows:steps.workflow-task.assign-to-users.placeholder",
            "Type to search users"
          )}
          formatOptionLabel={FormatOptionLabelUser}
          options={members.map((user) => ({
            label: userToString(user),
            value: user.id,
            user: user,
          }))}
        />
      );
    case "TEAM":
      return (
        <SelectorField
          isMulti
          isCreatable={false}
          name={`steps.${index}.assign_to_teams`}
          formatOptionLabel={FormatOptionLabelTeam}
          options={teams.map((team) => ({
            label: team.name,
            value: team.id,
            team: team,
          }))}
          label={t(
            "flows:steps.workflow-task.assign-to-teams.label",
            "Team(s) to assign this task to"
          )}
          help={t(
            "flows:steps.workflow-task.assign-to-teams.help",
            "We'll auto assign a user from that team according to the team's assignment settings."
          )}
          placeholder={t(
            "flows:steps.workflow-task.assign-to-teams.placeholder",
            "Type to search teams"
          )}
        />
      );
    case "VARIABLE":
      return (
        <SelectorField
          name={`steps.${index}.assign_from_variable`}
          label={t(
            "flows:steps.workflow-task.assign-from-variable.label",
            "Get user from variable"
          )}
          help={t(
            "flows:steps.workflow-task.assign-from-variable.help",
            "It will be assigned using the value of the variable to find it by the user's email."
          )}
          placeholder={t(
            "flows:steps.workflow-task.assign-from-variable.placeholder",
            "Select variable"
          )}
          options={options}
          formatGroupLabel={FormatGroupLabelVariable}
        />
      );
    default:
      return null;
  }
}

export default FlowBuilderStepWorkflowTaskAssignmentTypes;
