import { useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { isBrowser, isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { captureException } from "@sentry/react";
import QRCode from "qrcode";
import AnchorTracked from "src/components/Posthog/AnchorTracked";
import HilosIcon from "src/components/icons/HilosIcon";
import useHilosStore from "src/hooks/useHilosStore";
import OnboardingInWAConfirm from "./OnboardingInWAConfirm";

export default function OnboardingInWA({ setOnboardingType }) {
  const { t, i18n } = useTranslation();
  const { session } = useHilosStore();
  const [currentQRCode, setCurrentQRCode] = useState(null);

  const urlWhatsAppCode =
    i18n.language && i18n.language.toLowerCase().indexOf("es") === 0
      ? `https://api.whatsapp.com/send?phone=${
          process.env.REACT_APP_SANDBOX_NUMBER
        }&text=Hola, quiero crear mi cuenta en Hilos con mi usuario: ${encodeURIComponent(
          session?.email as string
        )}`
      : `https://api.whatsapp.com/send?phone=${
          process.env.REACT_APP_SANDBOX_NUMBER
        }&text=Hi, I'd like to create my account in Hilos with my email: ${encodeURIComponent(
          session?.email as string
        )}`;

  useEffect(() => {
    const updateCurrentQRCode = async () => {
      try {
        const data = await QRCode.toDataURL(urlWhatsAppCode, {
          width: 170,
          margin: 2,
          color: {
            dark: "#111827",
            light: "#ffffff",
          },
        });
        setCurrentQRCode(data);
      } catch (error) {
        captureException(error);
      }
    };

    updateCurrentQRCode();
  }, [urlWhatsAppCode]);

  if (session && session.account) {
    return <OnboardingInWAConfirm />;
  }

  return (
    <div className="flex min-h-full justify-center">
      <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full sm:w-[300px] md:w-[500px]">
          {isBrowser && (
            <div className="text-center">
              <div className="w-2 mx-auto">
                <ConfettiExplosion
                  {...{
                    force: 0.6,
                    duration: 4000,
                    particleCount: 80,
                    width: 1600,
                  }}
                />
              </div>
              <h1 className="mt-5 mb-1 text-2xl font-bold leading-7 text-gray-900">
                🥳 {t("settings:onboarding.welcome", "Welcome to Hilos!")}
              </h1>
              <p className="flex-inline items-center justify-center mt-4 text-sm text-gray-500">
                <FontAwesomeIcon
                  icon={faWhatsapp as IconProp}
                  aria-hidden="true"
                  className="mr-1"
                />
                {t(
                  "settings:onboarding.help-scan",
                  "Scan this code on your phone to finish setting up your account in WhatsApp."
                )}
              </p>
              {currentQRCode ? (
                <div className="mt-10">
                  <div className="mx-auto relative w-[170px]">
                    <img
                      className="mx-auto rounded-lg p-3"
                      src={currentQRCode}
                      alt="qr-code"
                    />
                    <span className="absolute -bottom-10 -right-9 rounded-full bg-green-200">
                      <FontAwesomeIcon
                        icon={faWhatsapp as IconProp}
                        aria-hidden="true"
                        className="size-10 text-green-600 p-2"
                      />
                    </span>
                    <span className="absolute bottom-6 -right-5 rounded-full bg-green-200 opacity-90 w-6 h-6"></span>
                    <span className="absolute bottom-[3.2rem] -right-3 rounded-full bg-green-200 opacity-80 w-4 h-4"></span>
                    <span className="absolute bottom-[4.2rem] -right-1 rounded-full bg-green-200 opacity-70 w-3 h-3"></span>

                    <span className="absolute -top-7 -left-7 rounded-full bg-hilos">
                      <HilosIcon
                        aria-hidden="true"
                        className="size-12 text-white p-2"
                      />
                    </span>
                    <span className="absolute top-6 -left-5 rounded-full bg-hilos opacity-90 w-6 h-6"></span>
                    <span className="absolute top-[3.2rem] -left-3 rounded-full bg-hilos opacity-80 w-4 h-4"></span>
                    <span className="absolute top-[4.2rem] -left-1 rounded-full bg-hilos opacity-70 w-3 h-3"></span>
                  </div>
                </div>
              ) : (
                <div className="m-2 flex h-[170px] min-h-[170px] min-w-[170px] animate-pulse flex-col items-center rounded-lg p-3 text-center">
                  {t("loading")}
                </div>
              )}
              <button
                className="mt-12 pt-4 text-sm text-gray-500 underline"
                type="button"
                onClick={() => setOnboardingType("BROWSER")}
              >
                {t(
                  "settings:onboarding.continue-in-browser",
                  "Or continue in your browser"
                )}
              </button>
            </div>
          )}
          {isMobile && (
            <div className="text-center">
              <ConfettiExplosion
                {...{
                  force: 0.6,
                  duration: 4000,
                  particleCount: 80,
                  width: 1600,
                }}
              />
              <div className="flex items-center justify-center">
                <span className="mr-1 rounded-full bg-hilos opacity-70 w-3 h-3"></span>
                <span className="mr-1.5 rounded-full bg-hilos opacity-80 w-4 h-4"></span>
                <span className="mr-2 rounded-full bg-hilos opacity-90 w-6 h-6"></span>
                <span className="mr-3 right-8 rounded-full bg-hilos">
                  <HilosIcon
                    aria-hidden="true"
                    className="size-12 text-white p-2"
                  />
                </span>
              </div>

              <h1 className="mt-5 mb-1 text-2xl font-bold leading-7 text-gray-900">
                🥳 {t("settings:onboarding.welcome", "Welcome to Hilos!")}
              </h1>
              <p className="mt-4 text-sm text-gray-500">
                {t(
                  "settings:onboarding.help-button",
                  "Click on the button below to open WhatsApp and finish setting up your account."
                )}
              </p>
              <div className="mt-2">
                <div className="mx-auto w-[280px]">
                  <AnchorTracked
                    href={urlWhatsAppCode}
                    rel="noreferrer"
                    target="_blank"
                    className="spread-shadow-green mx-6 mt-4 mb-4 inline-block rounded-lg bg-green-600 py-4 px-5 text-sm font-semibold text-white self-center"
                    posthogEvent="sandbox send-whatspp clicked"
                  >
                    <FontAwesomeIcon
                      icon={faWhatsapp as IconProp}
                      aria-hidden="true"
                      className="mr-1 size-5"
                    />
                    {t("settings:onboarding.button", "Continue on WhatsApp")}
                  </AnchorTracked>
                </div>
              </div>

              <button
                className="mt-12 pt-4 text-sm text-gray-500 underline"
                type="button"
                onClick={() => setOnboardingType("BROWSER")}
              >
                {t(
                  "settings:onboarding.continue-in-browser",
                  "Or continue in your browser"
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
