import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { use } from "i18next";
import { classNames } from "src/Helpers";

const MAX_PAGES_TO_SHOW = 3;
const MAX_RESULTS_PER_PAGE = 20;

export default function TablePagination({
  searchParams,
  results,
  count,
  updateSearchParams,
  dataName,
  pagination = "page",
  nextCursor = "",
  previousCursor = "",
}) {
  const { t } = useTranslation();
  const useCursorPagination = pagination === "cursor";
  const currentPage = useCursorPagination
    ? 1
    : searchParams.page
    ? searchParams.page
    : 1;
  const lastPage = results ? Math.ceil(count / MAX_RESULTS_PER_PAGE) : 1;

  const nextPageEnabled = useMemo(() => {
    if (useCursorPagination) {
      return !!nextCursor;
    }
    return currentPage < lastPage;
  }, [currentPage, lastPage, nextCursor, useCursorPagination]);
  const handleNextPage = useCallback(() => {
    if (useCursorPagination) {
      updateSearchParams("cursor", nextCursor ?? "");
    } else {
      updateSearchParams("page", currentPage + 1);
    }
  }, [currentPage, updateSearchParams, nextCursor, useCursorPagination]);

  const previousPageEnabled = useMemo(() => {
    if (useCursorPagination) {
      return !!previousCursor;
    }
    return currentPage > 1;
  }, [currentPage, previousCursor, useCursorPagination]);

  const handlePreviousPage = useCallback(() => {
    if (useCursorPagination) {
      updateSearchParams("cursor", previousCursor ?? "");
    } else {
      updateSearchParams("page", currentPage - 1);
    }
  }, [currentPage, updateSearchParams, previousCursor, useCursorPagination]);

  const getButtons = () => {
    if (useCursorPagination) {
      return null;
    }
    const lowestPageToShow =
      currentPage - MAX_PAGES_TO_SHOW > 0 ? currentPage - MAX_PAGES_TO_SHOW : 1;
    const highestPageToShow =
      currentPage + MAX_PAGES_TO_SHOW <= lastPage
        ? currentPage + MAX_PAGES_TO_SHOW
        : lastPage;
    const buttons = [];
    for (let i = lowestPageToShow; i <= highestPageToShow; i++) {
      buttons.push(
        <button
          key={i}
          type="button"
          aria-current="page"
          className={`relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 md:inline-flex
            ${
              i === currentPage
                ? "z-10 border-indigo-500 bg-indigo-50 text-indigo-600"
                : "border-gray-300 bg-white text-gray-500 hover:bg-gray-50"
            }`}
          onClick={(_) => updateSearchParams("page", i)}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  return (
    <>
      <div className="flex items-center justify-between border-t border-gray-200 px-2 py-3 sm:px-4">
        <div className="flex flex-1 justify-between sm:hidden">
          <button
            type="button"
            onClick={handlePreviousPage}
            disabled={!previousPageEnabled}
            className={classNames(
              "relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-70",
              !previousPageEnabled && "cursor-not-allowed opacity-50",
              previousPageEnabled && "hover:bg-gray-50"
            )}
          >
            Previous
          </button>
          <button
            type="button"
            onClick={handleNextPage}
            disabled={!nextPageEnabled}
            className={classNames(
              "relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-70",
              !nextPageEnabled && "cursor-not-allowed opacity-50",
              nextPageEnabled && "hover:bg-gray-50"
            )}
          >
            Next
          </button>
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div className="text-xs font-light normal-case text-gray-500">
            {`${t(
              "showing-results",
              "Showing {{showing}} of {{count}} {{table}}",
              {
                showing: results.length,
                count: count,
                table: dataName?.toLowerCase(),
              }
            )}`}
          </div>
          <div>
            <nav
              className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
              aria-label="Pagination"
            >
              <button
                type="button"
                onClick={handlePreviousPage}
                disabled={!previousPageEnabled}
                className={classNames(
                  "relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500",
                  !previousPageEnabled && "cursor-not-allowed opacity-50",
                  previousPageEnabled && "hover:bg-gray-50"
                )}
              >
                <span className="sr-only">{t("previous")}</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              {getButtons()}
              <button
                type="button"
                onClick={handleNextPage}
                disabled={!nextPageEnabled}
                className={classNames(
                  "relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500",
                  !nextPageEnabled && "cursor-not-allowed opacity-50",
                  nextPageEnabled && "hover:bg-gray-50"
                )}
              >
                <span className="sr-only">{t("next")}</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}
