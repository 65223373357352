import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ClockIcon, FlaskConical } from "lucide-react";
import { SidebarMenuButton, SidebarMenuItem } from "src/components/ui/sidebar";
import useHilosStore from "src/hooks/useHilosStore";
import { buildRoute } from "src/router/router";

const AccountStatusNotice = () => {
  const { session } = useHilosStore();
  const { t } = useTranslation();

  if (!session) {
    return null;
  }

  if (session.account.status === "IN_SANDBOX") {
    return (
      <SidebarMenuItem>
        <SidebarMenuButton
          asChild
          className="h-auto max-h-40 justify-center items-start group-data-[collapsible=icon]:items-center hover:bg-blue-50 active:bg-sky-50"
        >
          <div className="rounded-md bg-blue-50 p-4 flex flex-col justify-between">
            <div className="flex flex-row gap-2">
              <FlaskConical
                aria-hidden="true"
                className="shrink-0 self-center size-4 text-blue-800"
              />
              <p className="group-data-[collapsible=icon]:hidden font-semibold text-blue-800">
                {t("navigation:sandbox.notice.title", "Sandbox mode")}
              </p>
            </div>
            <p className="group-data-[collapsible=icon]:hidden text-sm text-blue-700">
              {t(
                "navigation:sandbox.notice.description",
                "When you're done testing Hilos, connect your company's number here"
              )}
            </p>
            <p className="group-data-[collapsible=icon]:hidden mt-2 text-sm">
              <Link
                to={buildRoute("config-channel-cloud-api-onboarding")}
                className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
              >
                {t(
                  "navigation:sandbox.notice.connect-number",
                  "Connect number"
                )}
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </p>
          </div>
        </SidebarMenuButton>
      </SidebarMenuItem>
    );
  }

  if (
    !session.account.current_subscription &&
    session.account.status === "ACTIVE"
  ) {
    return (
      <SidebarMenuItem>
        <SidebarMenuButton
          asChild
          className={`h-auto justify-center items-start group-data-[collapsible=icon]:items-center hover:bg-${
            session.account.remaining_free_trial_days <= 3
              ? "red"
              : session.account.remaining_free_trial_days <= 5
              ? "orange"
              : "blue"
          }-50 active:bg-${
            session.account.remaining_free_trial_days <= 3
              ? "red"
              : session.account.remaining_free_trial_days <= 5
              ? "orange"
              : "blue"
          }-50`}
        >
          <div
            className={`rounded-md bg-${
              session.account.remaining_free_trial_days <= 3
                ? "red"
                : session.account.remaining_free_trial_days <= 5
                ? "orange"
                : "blue"
            }-50 p-4 flex flex-col justify-between`}
          >
            <div
              className={`flex flex-row gap-2 text-${
                session.account.remaining_free_trial_days <= 3
                  ? "red"
                  : session.account.remaining_free_trial_days <= 5
                  ? "orange"
                  : "blue"
              }-800 group-data-[collapsible=icon]:justify-center group-data-[collapsible=icon]:align-middle`}
            >
              <ClockIcon
                aria-hidden="true"
                className="shrink-0 self-center size-4 group-data-[collapsible=icon]:hidden"
              />
              <p className="group-data-[collapsible=icon]:block group-data-[collapsible=icon]:text-center hidden font-semibold">
                {session.account.remaining_free_trial_days}
              </p>
              <p className="group-data-[collapsible=icon]:hidden font-semibold">
                {session.account.remaining_free_trial_days === 1
                  ? t(
                      "settings:free-trial-days-remaining-notice.last-day.title",
                      "Tomorrow's your last free trial day."
                    )
                  : session.account.remaining_free_trial_days === 0
                  ? t(
                      "settings:free-trial-days-remaining-notice.ended.title",
                      "Your free trial has ended."
                    )
                  : session.account.remaining_free_trial_days > 5
                  ? t(
                      "settings:free-trial-days-remaining-notice.initial.title",
                      "Free trial"
                    )
                  : t(
                      "settings:free-trial-days-remaining-notice.almost-over.title",
                      "Your free trial is almost over."
                    )}
              </p>
            </div>
            <p
              className={`group-data-[collapsible=icon]:hidden text-sm text-${
                session.account.remaining_free_trial_days <= 3
                  ? "red"
                  : session.account.remaining_free_trial_days <= 5
                  ? "orange"
                  : "blue"
              }-700`}
            >
              {session.account.remaining_free_trial_days === 1
                ? t(
                    "settings:free-trial-days-remaining-notice.last-day.description",
                    "Don't forget to suscribe to continue using Hilos."
                  )
                : session.account.remaining_free_trial_days === 0
                ? t(
                    "settings:free-trial-days-remaining-notice.ended.description",
                    "Suscribe to Hilos to continue using it."
                  )
                : session.account.remaining_free_trial_days > 5
                ? t(
                    "settings:free-trial-days-remaining-notice.initial.description",
                    "You have {{ freeTrialDaysRemaining }} days left to try Hilos to its fullest.",
                    {
                      freeTrialDaysRemaining:
                        session.account.remaining_free_trial_days,
                    }
                  )
                : t(
                    "settings:free-trial-days-remaining-notice.almost-over.description",
                    "You have {{ freeTrialDaysRemaining }} more days in your free trial, after that you'll need to subscribe to continue using Hilos.",
                    {
                      freeTrialDaysRemaining:
                        session.account.remaining_free_trial_days,
                    }
                  )}
            </p>
            <p className="group-data-[collapsible=icon]:hidden mt-2 text-sm font-bold">
              <Link
                to={buildRoute("config-account-subscription")}
                className={`whitespace-nowrap font-medium text-${
                  session.account.remaining_free_trial_days <= 3
                    ? "red"
                    : session.account.remaining_free_trial_days <= 5
                    ? "orange"
                    : "blue"
                }-700 hover:text-${
                  session.account.remaining_free_trial_days <= 3
                    ? "red"
                    : session.account.remaining_free_trial_days <= 5
                    ? "orange"
                    : "blue"
                }-600`}
              >
                {t(
                  "settings:free-trial-days-remaining-notice.subscribe",
                  "Subscribe"
                )}
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </p>
          </div>
        </SidebarMenuButton>
      </SidebarMenuItem>
    );
  }

  return null;
};

export default AccountStatusNotice;
