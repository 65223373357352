import { FlowExecutionStepReadDetail } from "@hilos/types/private-schema";
import HTTPResponseRenderResponse from "src/components/HTTPResponseRender/HTTPResponseRenderResponse";

interface FlowExecutionStepActiveCampaignAutomationEndDetailProps {
  executionStep: FlowExecutionStepReadDetail;
}

export default function FlowExecutionStepActiveCampaignAutomationEndDetail({
  executionStep,
}: FlowExecutionStepActiveCampaignAutomationEndDetailProps) {
  return (
    <HTTPResponseRenderResponse
      response={executionStep.execution_result.response}
    />
  );
}
