"use client";

import { useTranslation } from "react-i18next";
import { ChevronLeft, ChevronRight } from "lucide-react";
import AccountStatusNotice from "src/containers/menu/AccountStatusNotice";
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "src/components/ui/sidebar";

export function NavFooter() {
  const { t } = useTranslation();

  const { toggleSidebar, isMobile } = useSidebar();

  return (
    <SidebarMenu>
      <AccountStatusNotice />
      <SidebarMenuItem>
        <SidebarMenuButton
          size="default"
          className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
          onClick={toggleSidebar}
        >
          <div className={"flex shrink-0 w-5 h-5 items-center justify-center"}>
            {isMobile ? (
              <ChevronRight />
            ) : (
              <ChevronLeft className="size-5 transition-transform duration-200 group-data-[collapsible=icon]:rotate-180" />
            )}
          </div>
          <div className="grid flex-1 text-left text-sm leading-tight">
            <span className="truncate">{t("collapse", "Collapse")}</span>
          </div>
        </SidebarMenuButton>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
