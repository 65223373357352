import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  ArrowSmRightIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";
import { DateTime } from "luxon";
import useHilosStore from "src/hooks/useHilosStore";
import { useStripeSubscription } from "src/hooks/useStripe";
import { capitalize, formatPrice } from "src/Helpers";
import { buildRoute } from "src/router/router";
import PaymentMethodList from "./PaymentMethodList";
import SubscriptionCurrentPeriodDetail from "./SubscriptionCurrentPeriodDetail";
import SubscriptionNextPeriodDetail from "./SubscriptionNextPeriodDetail";
import SubscriptionStatusBadge from "./SubscriptionStatusBadge";
import SubscriptionV2Cancel from "./SubscriptionV2Cancel";
import SubscriptionV2Resume from "./SubscriptionV2Resume";

export default function SubscriptionV2ActivePlan() {
  const { t } = useTranslation();
  const { subscription } = useStripeSubscription();
  const { session } = useHilosStore();

  if (!subscription) {
    return null;
  }

  return (
    <>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <Trans i18nKey="settings:current-subscription">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Your current subscription
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                You can see your plan detail, as well as change it or cancel
                your subscription here.
              </p>
            </Trans>
          </div>
        </div>
        <div className="mt-5 md:col-span-2 md:mt-0">
          <div className="shadow sm:overflow-hidden sm:rounded-md">
            {subscription.cancel_at_period_end && (
              <div className="rounded-md bg-yellow-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-yellow-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-yellow-800">
                      {t(
                        "settings:billing.subscription-cancel-at-period-end.title",
                        "Your subscription will be canceled at period end."
                      )}
                    </h3>
                    <div className="mt-2 text-sm text-yellow-700 space-y-1">
                      <p>
                        <strong className="font-medium">
                          {subscription.canceled_by.email}
                        </strong>{" "}
                        {t(
                          "settings:billing.subscription-cancel-at-period-end.description",
                          "requested your subscription to be canceled at the end of the current period."
                        )}
                      </p>
                      <p>
                        {t(
                          "settings:billing.subscription-cancel-at-period-end.description-2",
                          "You can use your account normally until {{periodEnd}}. After that date you won't be able to send messages without choosing a new plan but all your messaging history will be preserved.",
                          {
                            periodEnd: DateTime.fromISO(
                              subscription.current_period_end as string
                            ).toLocaleString(DateTime.DATE_SHORT),
                          }
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="space-y-4 bg-white px-4 py-5 sm:p-6">
              <div className="space-y-2 text-left">
                {subscription.items.length < 1 ? (
                  <>
                    <Trans i18nKey="settings:billing.subscription-active">
                      <h2
                        id="plan-heading"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Subscription active! Couldn't load subscription
                        information, please{" "}
                        <a
                          className="flex items-center"
                          href="https://api.whatsapp.com/send?phone=+525592257050&text=Hi!%20I%20need%20help%20with%20hilos."
                        >
                          {" "}
                          contact us{" "}
                        </a>{" "}
                        if this error persists.
                      </h2>
                    </Trans>
                  </>
                ) : (
                  <>
                    <div>
                      <h2
                        id="plan-heading"
                        className="flex items-center text-gray-900"
                      >
                        <CheckCircleIcon className="mr-2 h-5 w-5 text-green-500" />
                        {t(
                          "settings:billing.currently-subscribed-to",
                          "You're currently subscribed to the {{plan}} plan.",
                          {
                            plan: capitalize(
                              session?.account.pricing_plan_selected
                            ),
                          }
                        )}
                      </h2>
                      <p className="flex items-center text-sm text-gray-500">
                        {t(
                          "settings:billing.current-subscription",
                          "Your subscription is currently"
                        )}
                        <span className="ml-1">
                          <SubscriptionStatusBadge
                            status={subscription.status}
                          />
                        </span>
                      </p>

                      <div className="flex items-center flex-row space-x-2 mt-3">
                        <Link
                          to={buildRoute(
                            "config-account-subscription-change-plan"
                          )}
                          className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        >
                          {t(
                            "settings:billing.see-change-plan",
                            "See/change my plan"
                          )}
                        </Link>
                        {subscription.cancel_at_period_end ? (
                          <SubscriptionV2Resume />
                        ) : (
                          <SubscriptionV2Cancel />
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>

              {subscription.status !== "active" && (
                <div className="text-sm text-gray-500">
                  {subscription.last_invoice ? (
                    <div className="text-gray-900">
                      <Trans i18nKey="settings:billing.invoice-pending">
                        <p>
                          Your last invoice of{" "}
                          <span className="font-semibold">
                            {formatPrice(subscription.last_invoice.total / 100)}
                          </span>{" "}
                          {subscription.last_invoice.currency} is still pending.
                        </p>
                        <a
                          href={
                            subscription.last_invoice.stripe_invoice_url || "#"
                          }
                          className="mt-4 inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-violet-400 px-5 py-3 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
                        >
                          Pay now <ArrowSmRightIcon className="ml-1 h-5 w-5" />
                        </a>
                      </Trans>
                    </div>
                  ) : (
                    t(
                      "settings:billing.invoice-pending-2",
                      "Your last invoice is still pending."
                    )
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <PaymentMethodList />

      <div className="space-y-6">
        <div className="grid grid-cols-1 items-start gap-6 sm:grid-cols-3">
          <SubscriptionCurrentPeriodDetail subscription={subscription} />
          <div className="sm:col-span-2">
            <SubscriptionNextPeriodDetail />
          </div>
        </div>
      </div>
    </>
  );
}
