import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  ArrowSmRightIcon,
  BadgeCheckIcon,
  CashIcon,
  ChatIcon,
  CheckCircleIcon,
  CheckIcon,
  CogIcon,
  ExclamationCircleIcon,
  SupportIcon,
  UserIcon,
  XIcon,
} from "@heroicons/react/outline";
import HeaderNavigation from "src/components/HeaderNavigation";
import Tabs from "src/components/Tabs";
import { useChannel } from "src/hooks/useChannel";
import useChannelProfile from "src/hooks/useChannelProfile";
import useHilosStore from "src/hooks/useHilosStore";
import { getTranslationPayload as tp } from "src/i18n";
import { buildRoute } from "src/router/router";
import { ChannelStatus } from "src/types/channel";
import ChannelCloudAPIUpdate from "./ChannelCloudAPIUpdate";
import ChannelQualityNotices from "./ChannelQualityNotices";
import ChannelStatusBadge from "./ChannelStatusBadge";
import ChannelWhatsAppTutorialCards from "./ChannelWhatsAppTutorialCards";
import * as meta from "./Meta";
import {
  HEALTH_STATUS,
  PROFILE_MESSAGING_TIERS,
  PROFILE_VERTICALS,
} from "./Meta";
import ProfileEditForm from "./ProfileEditForm";

export const CLOUD_API_LINKS = [
  {
    title: tp(
      "channel:whatsapp-links.payment-settings",
      "Go to WA Payment Settings"
    ),
    href: "https://business.facebook.com/billing_hub/payment_settings",
    icon: (
      <CashIcon className="h-5 w-5 text-gray-500 mr-2" aria-hidden="true" />
    ),
  },
  {
    title: tp(
      "channel:whatsapp-links.business-verification",
      "Business Verification"
    ),
    href: "https://business.facebook.com/settings/security",
    icon: (
      <CheckCircleIcon
        className="h-5 w-5 text-gray-500 mr-2"
        aria-hidden="true"
      />
    ),
  },
  {
    title: tp(
      "channel:whatsapp-links.meta-cloud-api-support",
      "Contact WA Support"
    ),
    href: "https://business.facebook.com/direct-support",
    icon: (
      <SupportIcon className="h-5 w-5 text-gray-500 mr-2" aria-hidden="true" />
    ),
  },
];

export default function ChannelCloudAPIDetail() {
  const { t } = useTranslation();
  const params = useParams();
  const channelId = params.id as string;
  const { channel, updateChannel } = useChannel(channelId);
  const { session } = useHilosStore();
  const {
    profileData,
    infoData,
    updateProfile,
    isUpdating,
    isUpdateSuccess,
    resetUpdateStatus,
  } = useChannelProfile(channelId);

  const channelInfo = useMemo(() => {
    return meta.AVAILABLE_CHANNELS.find(
      (chInfo) => chInfo.provider === channel?.channel_provider
    );
  }, [channel]);

  const handleDisableChannel = useCallback(() => {
    updateChannel({
      status: ChannelStatus.INACTIVE,
    });
  }, [updateChannel]);

  const handleEnableChannel = useCallback(() => {
    updateChannel({
      status: ChannelStatus.ACTIVE,
    });
  }, [updateChannel]);

  if (!channel || !channelInfo) {
    return null;
  }

  return (
    <div className="h-screen overflow-y-auto bg-gray-50">
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: "Home",
              url: buildRoute("dashboard"),
            },
            {
              name: "Settings",
              url: buildRoute("config-account"),
            },
            {
              name: "WhatsApp Accounts",
              url: buildRoute("config-channel"),
            },
            {
              name: channel?.name,
              url: buildRoute("config-channel-cloud-api-details", {
                id: channelId,
              }),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <ChatIcon className="mr-2 h-7 w-7" aria-hidden="true" />
              {t("channel:update-channel-title", "Channel {{channel}}", {
                channel: channel.name,
              })}
            </h2>
          </div>
        </div>
      </div>

      <div className="mt-4 ml-2">
        <div className="py-4 px-4 sm:px-6 lg:px-8">
          <p className="mt-2 ml-2 flex items-center text-xl font-bold leading-7 text-gray-900 sm:truncate">
            {t("channel:channel-details.info-title", "About your channel")}
          </p>
        </div>

        <div className="mx-10 grid grid-cols-1 sm:grid-cols-3 lg:gap-6 items-start">
          <div className="flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow">
            <div className="flex flex-1 flex-col items-center p-6">
              <div className="text-xs font-medium uppercase tracking-wider text-gray-500">
                <span>{channel.name}</span>
              </div>
              <div className="flex flex-row gap-3">
                <span
                  className={`flex mt-3 items-center rounded-full bg-${channel.channel_color}-100 px-2 py-1 text-xs font-medium text-${channel.channel_color}-800`}
                >
                  <channelInfo.icon className="mr-1 text-lg" />
                  {channelInfo.type}
                </span>
                {channel.id === session?.account.default_channel && (
                  <span className="flex mt-3 items-center rounded-full bg-blue-100 px-2 py-1 text-xs font-medium text-blue-800">
                    <CheckCircleIcon className="-ml-0.5 mr-1 h-5 aspect-square" />
                    {t("channel:default-channel", "Default channel")}
                  </span>
                )}
              </div>
              <div className="flex flex-1 flex-col text-left space-y-3">
                {channel.status === "INACTIVE" && channel.connection_error_msg && (
                  <div className="rounded-md bg-yellow-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationCircleIcon
                          className="h-5 w-5 text-yellow-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-yellow-800">
                          {t(
                            "channel:connection-failed",
                            "Channel connection failed"
                          )}
                        </h3>
                        <div className="mt-2 text-sm text-yellow-700">
                          <p>{channel.connection_error_msg}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {channel.status !== "INACTIVE" && infoData && profileData && (
                <>
                  <img
                    src={
                      profileData?.profile_picture ||
                      "https://hilos-media.s3.us-east-2.amazonaws.com/public-media/default+profile+picture.png"
                    }
                    alt="WhatsApp Profile"
                    className="mt-3 aspect-square rounded-full object-cover w-24 h-24"
                  />

                  <p className="font-bold text-xl">{channel.name}</p>
                  <p>{infoData?.display_phone_number}</p>

                  <div className="grid grid-cols-2 gap-3 mt-3">
                    <div className="flex flex-col justify-start items-center bg-slate-100 px-4 py-2 rounded-md">
                      <p className="text-slate-600 text-sm">Hilos Status</p>
                      <ChannelStatusBadge status={channel.status} />
                    </div>
                    <div className="flex flex-col justify-start items-center bg-slate-100 px-4 py-2 rounded-md">
                      <p className="text-slate-600 text-sm">Meta Status</p>
                      <div className="w-fit items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800">
                        {t(
                          meta.META_CHANNEL_STATUS[
                            infoData?.status || "channel:unknown"
                          ]?.label || "channel:unknown-status"
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col justify-start items-center bg-slate-100 px-4 py-2 rounded-md">
                      <p className="text-slate-600 text-sm">Channel Quality</p>
                      <div className="w-fit items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800">
                        {t(
                          HEALTH_STATUS[infoData?.quality_score?.score]
                            ?.label || "channel:unknown"
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col justify-start items-center bg-slate-100 px-4 py-2 rounded-md">
                      <p className="text-slate-600 text-sm">
                        {t(
                          "channel.channel-details.messaging-tier.title",
                          "Messaging tier"
                        )}
                      </p>
                      <p className="text-slate-600 font-bold text-xs">
                        {t(
                          PROFILE_MESSAGING_TIERS[
                            infoData?.messaging_limit_tier || "channel:unknown"
                          ]?.label
                        )}
                      </p>
                    </div>

                    {profileData && (
                      <div className="col-span-2 divide-y-3 divide-slate-400 bg-slate-100 rounded-md grid grid-cols-1 text-sm text-left text-slate-600 py-2 px-4">
                        <div>
                          {t(PROFILE_VERTICALS[profileData.vertical].label)}
                        </div>
                        <div>{profileData?.description}</div>
                        <div>{profileData?.about}</div>
                        <div>{profileData?.address}</div>
                        <div className="text-green-600">
                          {profileData?.email}
                        </div>
                        {profileData?.websites?.map((website) => (
                          <div key={website} className="text-green-600">
                            {website}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            {CLOUD_API_LINKS.map((cLink) => (
              <a
                href={cLink.href}
                key={cLink.href}
                target="_blank"
                rel="noreferrer"
                className="focus:outline-none"
              >
                <div className="group relative order-last divide-gray-200 p-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 sm:col-span-3 md:col-span-3 lg:order-none lg:col-span-2 last:rounded-b-lg flex items-center  justify-between ">
                  <h3 className="text-sm text-left flex items-center">
                    {cLink.icon}
                    {t(cLink.title)}
                  </h3>
                  <ArrowSmRightIcon className="h-5 w-5 text-gray-400" />
                </div>
              </a>
            ))}

            {channel.status === ChannelStatus.ACTIVE && (
              <button
                type="button"
                onClick={handleDisableChannel}
                className="group relative order-last divide-gray-200 p-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 sm:col-span-3 md:col-span-3 lg:order-none lg:col-span-2 last:rounded-b-lg flex items-center justify-between hover:bg-red-50"
              >
                <h3 className="text-sm text-left flex items-center text-red-700">
                  <XIcon
                    className="h-5 w-5 text-red-500 mr-2"
                    aria-hidden="true"
                  />
                  {t(
                    "channel:channel-details.disable-button",
                    "Deactivate channel"
                  )}
                </h3>
              </button>
            )}

            {channel.status === ChannelStatus.INACTIVE &&
              !channel.connection_error_msg && (
                <button
                  type="button"
                  onClick={handleEnableChannel}
                  className="group relative order-last divide-gray-200 p-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 sm:col-span-3 md:col-span-3 lg:order-none lg:col-span-2 last:rounded-b-lg flex items-center justify-between hover:bg-green-50"
                >
                  <h3 className="text-sm text-left flex items-center text-green-700">
                    <CheckIcon
                      className="h-5 w-5 text-green-500 mr-2"
                      aria-hidden="true"
                    />
                    {t(
                      "channel:channel-details.enable-button",
                      "Activate channel"
                    )}
                  </h3>
                </button>
              )}
          </div>

          <div className="col-span-2 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow">
            <div>
              <Tabs
                tabs={[
                  {
                    label: t(
                      "channel:channel-details.tabs.profile",
                      "WhatsApp Profile"
                    ),
                    icon: UserIcon,
                  },
                  {
                    label: t(
                      "channel:channel-details.tabs.settings",
                      "Channel Settings"
                    ),
                    icon: CogIcon,
                  },
                  {
                    label: t(
                      "channel:channel-details.tabs.quality",
                      "Channel Quality"
                    ),
                    icon: BadgeCheckIcon,
                  },
                ]}
              >
                {/* I added this validation because if not, sandbox users would be able to edit Hilos Sandbox Profile */}
                {!channel.is_sandbox && channel.status !== "INACTIVE" && (
                  <ProfileEditForm
                    profile={profileData}
                    onSubmit={(updatedProfile) => {
                      updateProfile(updatedProfile);
                      setTimeout(() => {
                        resetUpdateStatus();
                      }, 5000);
                    }}
                    isSubmitting={isUpdating}
                    isSuccess={isUpdateSuccess}
                  />
                )}

                <ChannelCloudAPIUpdate channelId={channelId} />
                <ChannelQualityNotices healthStatus={infoData?.health_status} />
              </Tabs>
            </div>
          </div>
        </div>

        <ChannelWhatsAppTutorialCards />
      </div>
    </div>
  );
}
