import { useState } from "react";
import WhatsAppIcon from "src/components/icons/WhatsAppIcon";

const WidgetQuickReply = ({ children }: { children: string }) => (
  <button
    style={{
      cursor: "pointer",
      padding: "8px 16px",
      border: "none",
      borderRadius: "3rem",
      fontSize: "0.85rem",
      backgroundColor: "#F2F2F2",
      color: "rgb(51, 65, 85)",
    }}
  >
    {children}
  </button>
);

interface WidgetPreviewProps {
  formValues: {
    button_1_text: string;
    button_2_text: string;
    button_3_text: string;
    bg_color: string;
    icon_color: string;
    padding: number;
    position: "BOTTOM_RIGHT" | "BOTTOM_LEFT" | "TOP_RIGHT" | "TOP_LEFT";
    default_text: string;
  };
}

const WidgetPreview = ({ formValues }: WidgetPreviewProps) => {
  const {
    button_1_text,
    button_2_text,
    button_3_text,
    bg_color,
    default_text,
    padding,
    position,
    icon_color,
  } = formValues;
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div
      id="hilos-widget-wrapper"
      style={{
        position: "absolute",
        boxSizing: "border-box",
        [position.startsWith("BOTTOM") ? "bottom" : "top"]: 0,
        padding: `${padding}px`,
        width: `clamp(200px, calc(100% - ${padding * 2}px), 400px)`,
        color: "rgba(51, 65, 85, 1)",
        zIndex: 1000,
        display: "flex",
        flexDirection: position.startsWith("TOP") ? "column-reverse" : "column",
        [position.endsWith("LEFT") ? "left" : "right"]: 0,
        alignItems: position.endsWith("LEFT") ? "flex-start" : "flex-end",
        fontFamily: '"inter-variable", sans-serif',
      }}
    >
      {isOpen && (
        <div
          id="hilos-widget-window"
          style={{
            borderTop: `10px solid ${bg_color}`,
            display: "flex",
            width: "100%",
            flexDirection: "column",
            gap: "0.5rem",
            alignItems: "left",
            backgroundColor: "white",
            boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
            borderRadius: "0.75rem",
            padding: "20px 16px 12px 16px",
            position: "relative",
            [position.startsWith("TOP") ? "top" : "bottom"]: "15px",
            justifyContent: "center",
          }}
        >
          <div
            id="hilos-quick-replies-row"
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "0.75rem",
              marginBottom: "0.5rem",
            }}
          >
            {button_1_text && (
              <WidgetQuickReply>{button_1_text}</WidgetQuickReply>
            )}
            {button_2_text && (
              <WidgetQuickReply>{button_2_text}</WidgetQuickReply>
            )}
            {button_3_text && (
              <WidgetQuickReply>{button_3_text}</WidgetQuickReply>
            )}
          </div>
          <div
            id="hilos-main-row"
            style={{
              display: "flex",
              gap: "0.75rem",
            }}
          >
            <div
              id="hilos-default-text"
              style={{
                display: "flex",
                fontSize: "0.85rem",
                color: "#737373",
                border: "1px solid rgba(209, 213, 219, 1)",
                alignItems: "center",
                justifyContent: "flex-start",
                borderRadius: "6rem",
                width: "80%",
                height: "auto",
                padding: "0.75rem 1rem",
              }}
            >
              {default_text}
            </div>
            <button
              id="hilos-send-button"
              style={{
                width: "48px",
                height: "48px",
                borderRadius: "50%",
                border: "none",
                padding: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                backgroundColor: bg_color,
              }}
            >
              <svg
                style={{
                  width: "20px",
                  height: "20px",
                }}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className="widget-icon"
              >
                <path
                  fill={icon_color}
                  d="m13.761 12.01l-10.76-1.084L3 4.074a1.074 1.074 0 0 1 1.554-.96l15.852 7.926a1.074 1.074 0 0 1 0 1.92l-15.85 7.926a1.074 1.074 0 0 1-1.554-.96v-6.852z"
                />
              </svg>
            </button>
          </div>
          <div id="hilos-attribution-row" style={{ margin: "3px auto 0" }}>
            <a
              id="hilos-widget-attribution"
              href="https://hilos.io"
              target="_blank"
              rel="noreferrer"
              style={{
                all: "unset",
                fontSize: "0.7rem",
                textDecoration: "none",
                color: bg_color,
                borderRadius: "0.25rem",
                padding: "6px 8px",
                cursor: "pointer",
              }}
            >
              Powered by <strong style={{ fontWeight: 600 }}>Hilos.io</strong>
            </a>
          </div>
        </div>
      )}
      <div
        id="hilos-widget-button"
        style={{
          position: "relative",
          bottom: 0,
          backgroundColor: bg_color,
          width: "54px",
          height: "54px",
          borderRadius: "50%",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <WhatsAppIcon
          style={{
            width: "24px",
            height: "24px",
            color: icon_color,
          }}
        />
      </div>
    </div>
  );
};

export default WidgetPreview;
