import {
  ChatAltIcon,
  ChatIcon,
  ClockIcon,
  CodeIcon,
  LightningBoltIcon,
  MenuIcon,
  PencilIcon,
  QuestionMarkCircleIcon,
  ShareIcon,
  TagIcon,
  UserAddIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import * as yup from "yup";
import { FlowStepContactUpdateMeta } from "src/containers/flow/form/steps/contact_update/Meta";
import { FlowStepDelayMeta } from "src/containers/flow/form/steps/delay/Meta";
import WhatsAppIcon from "src/components/icons/WhatsAppIcon";
import { ERROR_MESSAGES } from "../../constants/Form";
import ActiveCampaignIcon from "./builder/components/steps/activecampaign/ActiveCampaignIcon";
import HubSpotIcon from "./builder/components/steps/hubspot/HubspotIcon";
import { FlowStepActionMeta } from "./form/steps/action/Meta";
import { FlowStepConditionalMeta } from "./form/steps/conditional/Meta";
import { FlowStepConversationMeta } from "./form/steps/conversation/Meta";
import { FlowStepMenuMeta } from "./form/steps/menu/Meta";
import { FlowStepMessageMeta } from "./form/steps/message/Meta";
import { FlowStepQuestionMeta } from "./form/steps/question/Meta";
import { FlowStepTagMeta } from "./form/steps/tag/Meta";
import { FlowStepTeamConversationMeta } from "./form/steps/team/Meta";
import { FlowStepTemplateMeta } from "./form/steps/template/Meta";
import { FlowStepTriggerFlowMeta } from "./form/steps/trigger_flow/Meta";

export interface StepTypeData {
  value: string;
  label: string;
  icon: JSX.Element;
  help: string;
}

export const STEP_TYPES: StepTypeData[] = [
  {
    value: "TEMPLATE",
    label: "Template",
    icon: <ChatAltIcon className="h-5 w-5" aria-hidden="true" />,
    help: "Sends a WhatsApp template",
  },
  {
    value: "TEMPLATE_MENU",
    label: "Template Menu",
    icon: <ChatAltIcon className="h-5 w-5" aria-hidden="true" />,
    help: "Sends a WhatsApp template",
  },
  {
    value: "MESSAGE",
    label: "Message",
    icon: <ChatIcon className="h-5 w-5" aria-hidden="true" />,
    help: "Sends a single message",
  },
  {
    value: "QUESTION",
    label: "Question",
    icon: <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />,
    help: "Asks a question, validates and saves the response",
  },
  {
    value: "MENU",
    label: "Menu",
    icon: <MenuIcon className="h-5 w-5" aria-hidden="true" />,
    help: "Present a menu with choices",
  },
  {
    value: "CONDITIONAL",
    label: "Conditional",
    icon: <ShareIcon className="h-5 w-5" aria-hidden="true" />,
    help: "Selects next step based on a condition",
  },
  {
    value: "ACTION",
    label: "Action",
    icon: <CodeIcon className="h-5 w-5" aria-hidden="true" />,
    help: "Make an HTTP request",
  },
  {
    value: "CONVERSATION",
    label: "Conversation",
    icon: <UserAddIcon className="h-5 w-5" aria-hidden="true" />,
    help: "Start an Inbox conversation and assign it to one or more agents",
  },
  {
    value: "TEAM",
    label: "Assign to team",
    icon: <UserGroupIcon className="h-5 w-5" aria-hidden="true" />,
    help: "End this flow and assign the conversation to a user from a team",
  },
  {
    value: "TAG",
    label: "Tag",
    icon: <TagIcon className="h-5 w-5" aria-hidden="true" />,
    help: "Assign tags to the contact or conversation to be able filter them in the inbox",
  },
  {
    value: "DELAY",
    label: "Delay",
    icon: <ClockIcon className="h-5 w-5" aria-hidden="true" />,
    help: "Wait some time before continuing the flow",
  },
  {
    value: "CONTACT_UPDATE",
    label: "Contact Update",
    icon: <PencilIcon className="h-5 w-5" aria-hidden="true" />,
    help: "Update the contact properties",
  },
  {
    value: "TRIGGER_FLOW",
    label: "Trigger Flow",
    icon: <LightningBoltIcon className="h-5 w-5" aria-hidden="true" />,
    help: "Connect this flow to another one",
  },
  {
    value: "HUBSPOT_CONTACT_UPSERT",
    label: "HubSpot Contact",
    icon: <HubSpotIcon className="h-5 w-5" aria-hidden="true" />,
    help: "Update or create a contact in HubSpot",
  },
  {
    value: "HUBSPOT_CONTACT_GET",
    label: "HubSpot Contact",
    icon: <HubSpotIcon className="h-5 w-5" aria-hidden="true" />,
    help: "Get contact in HubSpot",
  },
  {
    value: "WA_FLOW",
    label: "WhatsApp Flow",
    icon: <WhatsAppIcon className="h-5 w-5" aria-hidden="true" />,
    help: "Trigger a WhatsApp Flow",
  },
  {
    value: "ACTIVECAMPAIGN_AUTOMATION_START",
    label: "Start ActiveCampaign Automation",
    icon: <ActiveCampaignIcon className="h-5 w-5" aria-hidden="true" />,
    help: "",
  },
  {
    value: "ACTIVECAMPAIGN_AUTOMATION_END",
    label: "Leave ActiveCampaign Automation",
    icon: <ActiveCampaignIcon className="h-5 w-5" aria-hidden="true" />,
    help: "",
  },
  {
    value: "ACTIVECAMPAIGN_CONTACT_GET",
    label: "Get ActiveCampaign Contact",
    icon: <ActiveCampaignIcon className="h-5 w-5" aria-hidden="true" />,
    help: "",
  },
  {
    value: "ACTIVECAMPAIGN_CONTACT_UPSERT",
    label: "Create/Update ActiveCampaign Contact",
    icon: <ActiveCampaignIcon className="h-5 w-5" aria-hidden="true" />,
    help: "",
  },
];

export const STEP_COMPONENT_META = {
  TEMPLATE: FlowStepTemplateMeta,
  MESSAGE: FlowStepMessageMeta,
  QUESTION: FlowStepQuestionMeta,
  MENU: FlowStepMenuMeta,
  CONDITIONAL: FlowStepConditionalMeta,
  ACTION: FlowStepActionMeta,
  CONVERSATION: FlowStepConversationMeta,
  TEAM: FlowStepTeamConversationMeta,
  TAG: FlowStepTagMeta,
  DELAY: FlowStepDelayMeta,
  CONTACT_UPDATE: FlowStepContactUpdateMeta,
  TRIGGER_FLOW: FlowStepTriggerFlowMeta,
};

export const getSchemaForStep = (stepType) => {
  return STEP_COMPONENT_META[stepType].getSchema();
};
export const getInitialValuesForStep = (step, stepType) => {
  return STEP_COMPONENT_META[stepType].getInitialValues(step);
};
export const getValidatorForStep = (stepType) => {
  return STEP_COMPONENT_META[stepType].validate;
};
export const getFormatterForSave = (stepType) => {
  return STEP_COMPONENT_META[stepType].formatForSave;
};

export const FlowStepBase = {
  getSchema: function () {
    return yup.object().shape({
      name: yup.string().required(ERROR_MESSAGES.required),
      step_type: yup.string().required(ERROR_MESSAGES.required),
    });
  },
};

export const TemplateMeta = {
  getInitialValues: function () {
    return {
      headerURL: "",
      headerVar: "",
      locationHeaderVars: {
        name: "",
        address: "",
        latitude: "",
        longitude: "",
      },
      bodyVars: [],
      buttonVars: [],
    };
  },
  FIELDS: {
    whatsapp_template: {
      key: "whatsapp_template",
      label: "WhatsApp template to use",
      help: "",
    },
    whatsapp_template_variables: {
      key: "whatsapp_template_variables",
      headerURL: {
        key: "headerURL",
      },
      headerVar: {
        key: "headerVar",
      },
      bodyVars: {
        key: "bodyVars",
      },
      buttonVars: {
        key: "buttonVars",
      },
    },
  },
};
