import { captureException } from "@sentry/react";
import axios from "axios";
import { create } from "zustand";
import { HilosStore, InboxParams, SessionData } from "@hilos/types/hilos";
import {
  PLATFORM,
  hasNotificationDevice,
  registerPushyNotification,
  unregisterServiceWorkers,
} from "../helpers/notifications";
import { getUser, setCookieString } from "../helpers/session";
import { API_ROUTES } from "../router/router";

const INITIAL_DEFAULT_INBOX_PARAMS: InboxParams = {
  view: null,
  search: null,
  ordering: "-last_message_on",
  filters: [],
};

const useHilosStore = create<HilosStore>((set, get) => ({
  session: null,
  unreadNotificationCount: 0,
  version: null,
  isNetworkOnline: false,
  hasNetworkError: false,
  hasPendingUpdate: false,
  inboxParams: { ...INITIAL_DEFAULT_INBOX_PARAMS },
  setSession: (nextSession) => {
    const { session: prevSession } = get();

    if (
      !nextSession ||
      !prevSession ||
      prevSession.token_access !== nextSession.token_access
    ) {
      set({ session: nextSession });
      set({ unreadNotificationCount: nextSession.unread_notifications_count });
    }
  },
  setCredentials: async (credentials = null, callback = null) => {
    const token = (credentials && credentials.token_access) || null;
    const user = await getUser(token);
    if (token && user) {
      const session = { ...user, ...credentials } as unknown as SessionData;

      localStorage.setItem("session", JSON.stringify(session));

      try {
        switch (PLATFORM) {
          case "ANDROID":
            // @ts-ignore
            window.UtilityJSBridge.getPushyToken();
            break;
          case "IOS":
            // @ts-ignore
            window.webkit.messageHandlers.UtilityJSBridge.postMessage(
              "getPushyToken"
            );
            break;
          case "BROWSER":
            const hasNotificationDeviceRegistered = await hasNotificationDevice(
              token
            );

            if (!hasNotificationDeviceRegistered) {
              await registerPushyNotification(token);
            }
            break;
          default:
            break;
        }
      } catch (error) {
        captureException(error);
      }

      setCookieString(token);
      set({ session });
      if (callback) {
        callback(user);
      }
    } else {
      unregisterServiceWorkers();
      localStorage.clear();
      setCookieString();
      set({ session: null });
    }
  },
  updateSession: async (data) => {
    const { session: prevSession } = get();

    if (prevSession && data) {
      const response = await axios.patch(API_ROUTES.ME, data);
      if (response && response.data) {
        const nextSession = {
          ...prevSession,
          ...response.data,
        };
        localStorage.setItem("session", JSON.stringify(nextSession));
        set({ session: nextSession });
      }
    }
  },
  reloadSession: async () => {
    const { session: prevSession } = get();

    if (prevSession && prevSession.token_access) {
      const user = await getUser(prevSession.token_access);
      const nextSession = {
        ...prevSession,
        ...user,
      } as unknown as SessionData;
      localStorage.setItem("session", JSON.stringify(nextSession));
      set({ session: nextSession });
    }
  },
  setUnreadNotificationCount: (unreadNotificationCount) =>
    set({ unreadNotificationCount }),
  setVersion: (version = null) => set({ version }),
  setPendingUpdate: (hasPendingUpdate = true) => set({ hasPendingUpdate }),
  setIsNetworkOnline: (isNetworkOnline = false) => set({ isNetworkOnline }),
  setHasNetworkError: (hasNetworkError = false) => set({ hasNetworkError }),
  setInboxParams: (nextInboxParams: Partial<InboxParams>) =>
    set(({ inboxParams: prevInboxParams }) => ({
      inboxParams: {
        ...prevInboxParams,
        ...nextInboxParams,
      },
    })),
  resetSession: () =>
    set({
      session: null,
      inboxParams: { ...INITIAL_DEFAULT_INBOX_PARAMS },
    }),
  resetInboxParams: () =>
    set({ inboxParams: { ...INITIAL_DEFAULT_INBOX_PARAMS } }),
}));

export default useHilosStore;
