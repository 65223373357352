import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ViewGridIcon } from "@heroicons/react/outline";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import HeaderNavigation from "src/components/HeaderNavigation";
import Loading from "src/components/Loading";
import { API_ROUTES, buildAPIRoute, buildRoute } from "../../router/router";
import WorkflowForm from "./WorkflowForm";

async function fetchWorkflowData({ queryKey }) {
  const { data } = await axios.get(
    buildAPIRoute(API_ROUTES.WORKFLOW_DETAIL, {
      ":id": queryKey[1],
    })
  );

  return data;
}

export default function WorkflowUpdate() {
  const [t] = useTranslation();
  const { id } = useParams();

  const { data, isLoading } = useQuery({
    queryKey: ["workflow-detail", id],
    queryFn: fetchWorkflowData,
  });

  return (
    <div className="flex flex-col h-screen overflow-y-auto bg-gray-50">
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("home"),
              url: buildRoute("dashboard"),
            },
            {
              name: t("workflows"),
              url: buildRoute("workflow-list"),
            },
            {
              name: t("workflows:update-workflow", "Update CRM Workflow"),
              url: buildRoute("workflow-update", { id }),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <ViewGridIcon className="mr-2 h-7 w-7" aria-hidden="true" />
              {t("workflows:update-workflow", "Update CRM Workflow")}
            </h2>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto mt-6 max-w-3xl">
          {isLoading ? <Loading /> : <WorkflowForm workflow={data} />}
        </div>
      </div>
    </div>
  );
}
