import { motion } from "framer-motion";
import WhatsAppIcon from "src/components/icons/WhatsAppIcon";
import { useChannel } from "src/hooks/useChannel";
import { classNames } from "src/Helpers";
import { ChannelData } from "src/types/channel";
import { SimpleChannel } from "src/types/private-schema";

interface ChannelBadgeProps {
  channelId?: string;
  channel?: ChannelData | SimpleChannel;
}

function ChannelBadgeCompact({ channel, channelId }: ChannelBadgeProps) {
  if (channelId) {
    return <ChannelBadgeById id={channelId} />;
  }
  return <ChannelBadgeByChannel channel={channel} />;
}

function ChannelBadgeById({ id }: { id: string }) {
  const { channel } = useChannel(id);
  return <ChannelBadgeByChannel channel={channel} />;
}

const badgeVariants = {
  compact: {
    opacity: 0,
    width: 0,
    transition: { duration: 0.2 },
  },
  expanded: {
    opacity: 1,
    width: "auto",
    transition: {
      duration: 0.2,
      delay: 0.5,
    },
  },
};

function ChannelBadgeByChannel({
  channel,
}: Pick<ChannelBadgeProps, "channel">) {
  if (!channel) {
    return null;
  }

  return (
    <motion.div className="relative flex items-center">
      <motion.div
        initial="compact"
        whileHover="expanded"
        animate="compact"
        className={classNames(
          `h-5 rounded-full flex items-center justify-start bg-${channel.channel_color}-200 text-${channel.channel_color}-700 px-1.5`
        )}
      >
        <WhatsAppIcon className="h-3.5 w-3.5" />

        <motion.div variants={badgeVariants} className="overflow-hidden">
          <span
            className={classNames(
              "flex items-center ml-1.5 text-xs font-medium whitespace-nowrap"
            )}
          >
            {channel.name || channel.channel_type}
          </span>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default ChannelBadgeCompact;
