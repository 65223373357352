import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { BookMarked, LifeBuoy, Lightbulb, Youtube } from "lucide-react";
import i18n from "src/i18n";
import { featureSupportLinks, videoSupportLinks } from "./SupportWidgetMeta";

interface SupportLinkInfo {
  feature: string;
  supportLink: string;
}

interface SupportWidgetLinkProps {
  feature: string;
  supportLink: string;
  icon: React.ReactNode;
}
interface SupportWidgetVideoProps {
  videoTitle: string[];
  videoLink: string;
  notSeen: boolean;
  onClick: (videoLink: string) => void;
}

const SupportWidgetLink = React.memo(
  ({ feature, supportLink, icon }: SupportWidgetLinkProps) => {
    return (
      <a
        target="_blank"
        href={supportLink}
        rel="noreferrer nofollow"
        className="rounded-lg flex flex-col text-xs text-center justify-start gap-2 bg-gray-100 hover:bg-indigo-500 hover:text-white items-center p-2"
      >
        {icon}
        {feature}
      </a>
    );
  }
);

const VideoWidgetLink = React.memo(
  ({ videoTitle, videoLink, notSeen, onClick }: SupportWidgetVideoProps) => {
    const { t } = useTranslation();

    return (
      <div className="relative flex-1">
        <button
          className="w-full rounded-lg flex flex-row gap-3 disabled:bg-gray-200 bg-gray-100 hover:bg-indigo-500 hover:text-white items-center px-4 py-2 disabled:hover:text-gray-400"
          onClick={() => {
            window.open(videoLink, "_blank");
            onClick && onClick(videoLink);
          }}
        >
          {notSeen && (
            <span className="absolute right-0 top-0 h-2 w-2 bg-red-500 rounded-full animate-ping" />
          )}
          <Youtube className="w-8 h-8" />
          <p className="flex-1 text-left">{t(videoTitle || "")}</p>
        </button>
      </div>
    );
  }
);

const SupportWidget = React.memo(() => {
  const { t } = useTranslation();
  const { language } = i18n;
  const [widgetState, setWidgetState] = useState({
    isOpen: false,
    isReversed: false,
  });
  const [hasNewContent, setHasNewContent] = useState(false);
  const location = useLocation();

  const supportLinksMap = Object.fromEntries(
    Object.entries(featureSupportLinks).flatMap(([supportItem, links]) =>
      Object.entries(links).map(([key, value]) => [`/${key}`, value])
    )
  );

  const videoLinksMap = Object.fromEntries(
    Object.entries(videoSupportLinks).flatMap(([supportItem, links]) =>
      Object.entries(links).map(([key, value]) => [`/${key}`, value])
    )
  );

  const { feature, supportLink } = useMemo((): SupportLinkInfo => {
    const path = location.pathname;
    const match = Object.keys(supportLinksMap).find((key) =>
      path.startsWith(key)
    );
    return match
      ? {
          feature: t(supportLinksMap[match].helpItem),
          supportLink: supportLinksMap[match].supportLink,
        }
      : {
          feature: t("support:guides.default"),
          supportLink: "https://hilos.io/docs/en/user/using-hilos",
        };
  }, [location.pathname, supportLinksMap, t]);

  const videoLinks = useMemo(() => {
    const path = location.pathname;
    const sortedKeys = Object.keys(videoLinksMap).sort(
      (a, b) => b.length - a.length
    );
    const match = sortedKeys.find((key) => path.startsWith(key));
    const videosForPath = match ? videoLinksMap[match] : [];
    return videosForPath.filter((vid) => {
      return vid.condition ? vid.condition() : true;
    });
  }, [location.pathname, videoLinksMap]);

  const handleDragEnd = useCallback(
    (position: number) =>
      setWidgetState({
        ...widgetState,
        isReversed: position < window.innerHeight / 2,
      }),
    [widgetState]
  );

  const onClick = useCallback(() => {
    const storedVideos = JSON.parse(
      localStorage.getItem("knownContent") || "{}"
    );
    videoLinks.forEach((video) => {
      if (video.videoLink) {
        storedVideos[video.videoLink] = true;
      }
    });
    localStorage.setItem("knownContent", JSON.stringify(storedVideos));
    setHasNewContent(false);
    setWidgetState({
      ...widgetState,
      isOpen: !widgetState.isOpen,
    });
  }, [widgetState, videoLinks]);

  const handleVideoClick = useCallback(
    (videoLink: string) => {
      const storedVideos = JSON.parse(
        localStorage.getItem("seenVideos") || "{}"
      );
      storedVideos[videoLink] = true;
      localStorage.setItem("seenVideos", JSON.stringify(storedVideos));
      setHasNewContent(
        videoLinks.some(
          (video) => video.videoLink && !storedVideos[video.videoLink]
        )
      );
    },
    [videoLinks]
  );

  useEffect(() => {
    const storedVideos = JSON.parse(
      localStorage.getItem("knownContent") || "{}"
    );
    const unseen = videoLinks.some((video) => {
      return video.videoLink && !storedVideos[video.videoLink];
    });
    setHasNewContent(unseen);
  }, [videoLinks]);

  return (
    <>
      {!isMobile && (
        <motion.div
          drag="y"
          dragConstraints={{
            top: -window.innerHeight + 100,
            bottom: 0,
          }}
          onDragEnd={(event, info) => handleDragEnd(info.point.y)}
          className="absolute bottom-5 right-5 z-10 flex gap-4 flex-col items-end"
          whileDrag={{
            pointerEvents: "none",
          }}
        >
          {widgetState.isOpen && (
            <div
              className={`absolute w-80 flex flex-col gap-2 p-4 bg-white text-gray-400 rounded-lg shadow-md text-sm ring-1 ring-gray-900/5 ${
                widgetState.isReversed ? "top-full mt-2" : "bottom-full mb-2"
              }`}
            >
              <div className="flex flex-col gap-2">
                {videoLinks.length > 0 ? (
                  videoLinks.map((video, index) => {
                    const storedVideos = JSON.parse(
                      localStorage.getItem("seenVideos") || "{}"
                    );
                    const notSeen = !storedVideos[video.videoLink];
                    return (
                      <VideoWidgetLink
                        key={index}
                        videoTitle={video.videoTitle}
                        videoLink={video.videoLink}
                        onClick={handleVideoClick}
                        notSeen={notSeen}
                      />
                    );
                  })
                ) : (
                  <button
                    className="rounded-lg flex flex-row gap-3 disabled:bg-gray-200 bg-gray-100 hover:bg-indigo-500 hover:text-white items-center px-4 py-2 disabled:hover:text-gray-400"
                    disabled
                  >
                    <span className="icon-[octicon--video-24] w-8 h-8"></span>
                    <p className="flex-1 text-left">
                      {t(
                        "support:videos.channel.coming-soon",
                        "More videos coming soon"
                      )}
                    </p>
                  </button>
                )}
              </div>
              <div className="grid grid-cols-3 gap-2">
                <SupportWidgetLink
                  feature={t("support:feature-docs", {
                    feature: feature || "Hilos",
                  })}
                  supportLink={supportLink}
                  icon={<BookMarked className="w-8 h-8" />}
                />
                <SupportWidgetLink
                  feature={t("navigation:support.chat", "Chat with support")}
                  supportLink={
                    language && language.toLowerCase().indexOf("es") === 0
                      ? "https://api.whatsapp.com/send?phone=525592257050&text=Hola!%20Necesito%20ayuda%20con%20Hilos."
                      : "https://api.whatsapp.com/send?phone=+525592257050&text=Hi!%20I%20need%20help%20with%20Hilos."
                  }
                  icon={<LifeBuoy className="w-8 h-8" />}
                />
                <SupportWidgetLink
                  feature={t("navigation:support.feedback", "Feedback")}
                  supportLink={"https://hilos.canny.io/feedback"}
                  icon={<Lightbulb className="w-8 h-8" />}
                />
              </div>
            </div>
          )}
          <button
            onClick={onClick}
            className={`rounded-full aspect-square font-bold text-lg h-10 shadow-md bg-white text-indigo-500 text-md ${
              widgetState.isReversed ? "order-first" : "order-last"
            }`}
          >
            {hasNewContent && (
              <span className="absolute right-0 top-0 h-2 w-2 bg-red-500 rounded-full animate-ping" />
            )}
            ?
          </button>
        </motion.div>
      )}
    </>
  );
});

export default SupportWidget;
