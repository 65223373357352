import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FilterIcon, PlusSmIcon, SearchIcon } from "@heroicons/react/outline";
import { InboxContactViewData } from "@hilos/hooks/useInboxContactViews";
import { InboxParams } from "@hilos/types/hilos";
import Loading from "src/components/Loading";
import { hasItems } from "src/helpers/utils";
import useHilosStore from "src/hooks/useHilosStore";
import AgentAvailabilityToggle from "./AgentAvailabilityToggle";
import { InboxParamsValues } from "./InboxParamsForm";
import ViewsToggle from "./ViewsToggle";

interface ConversationListHeaderProps {
  search: string;
  inboxParams: InboxParams;
  totalConversations: number;
  totalConversationsLoading: boolean;
  isPageVisible: boolean;
  allowQueryCountWithLimit: boolean;
  setSearch: (search: string) => void;
  setIsUpdatingFilters: (searching: boolean) => void;
  onInboxParams: (params: Partial<InboxParams>) => void;
  onSwitchShowFilters: () => void;
  onShowNewConversation: () => void;
  onChangeFilters: (params: InboxParamsValues) => void;
  onDisableQueryCountWithLimit: () => void;
  showViews: boolean;
  currentView: InboxContactViewData | null;
  onToggleViews: () => void;
}

function ConversationListHeader({
  search,
  inboxParams,
  totalConversations,
  totalConversationsLoading,
  isPageVisible,
  allowQueryCountWithLimit,
  setSearch,
  setIsUpdatingFilters,
  onInboxParams,
  onSwitchShowFilters,
  onShowNewConversation,
  onChangeFilters,
  onDisableQueryCountWithLimit,
  showViews,
  currentView,
  onToggleViews,
}: ConversationListHeaderProps) {
  const { t } = useTranslation();
  const { session } = useHilosStore();

  const handleChangeSearchParams = useCallback(
    (value: string) => {
      if (value && value.length < 3) {
        setIsUpdatingFilters(false);
      } else {
        onInboxParams({ search: value || null });
      }
    },
    [onInboxParams, setIsUpdatingFilters]
  );

  const handleChangeSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const nextSearch = event.target.value || "";
      setSearch(nextSearch);
      setIsUpdatingFilters(true);
      handleChangeSearchParams(nextSearch);
    },
    [setSearch, setIsUpdatingFilters, handleChangeSearchParams]
  );

  return (
    <>
      <div className="flex flex-col justify-center space-y-3 bg-white py-2 px-4 pt-3">
        <div className="flex items-center space-x-2">
          <div className="flex grow items-baseline space-x-2">
            <h2
              className="text-lg font-medium text-gray-900"
              data-tour="inbox-title"
            >
              {t("inbox", "Inbox")}
            </h2>
            {totalConversationsLoading || !isPageVisible ? (
              <div className="w-5">
                <Loading className="h-3 w-3 text-gray-400" showText={false} />
              </div>
            ) : (
              <p className="text-sm font-medium text-gray-500">
                {allowQueryCountWithLimit ? (
                  <button
                    disabled={totalConversations < 1000}
                    onClick={onDisableQueryCountWithLimit}
                  >
                    {/* t("inbox:conversation_interval", "(1000-inf)[+999 chats];") */}
                    {t("inbox:conversation_interval", {
                      count: totalConversations,
                      postProcess: "interval",
                    })}
                  </button>
                ) : (
                  /* t("inbox:conversation_one", "{{count}} chat") */
                  /* t("inbox:conversation_other", "{{count}} chats") */
                  t("inbox:conversation", { count: totalConversations })
                )}
              </p>
            )}
          </div>
          {session?.account.feature_flags.includes(
            "require_agent_availability"
          ) && <AgentAvailabilityToggle />}

          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 bg-gradient-to-r from-hilos to-violet-400 px-3 py-1.5 text-sm font-medium text-white shadow-sm hover:bg-hilos focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
            onClick={onShowNewConversation}
          >
            <PlusSmIcon className="h-4 w-4" aria-hidden="true" />
          </button>
        </div>
      </div>
      <form onSubmit={(ev) => ev.preventDefault()} className="bg-white pt-2">
        <div className="w-42 flex grow-0 flex-row items-center bg-white">
          <div className="pointer-events-none absolute left-0 pl-3">
            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            className="block w-full border-0 border-transparent pl-9 focus:ring-0 sm:text-sm"
            type="search"
            placeholder={t("search", "Search...")}
            value={search}
            onChange={handleChangeSearch}
          />
          <ViewsToggle currentView={currentView} onToggle={onToggleViews} />
          <button
            onClick={onSwitchShowFilters}
            className="flex items-center px-3"
            data-tour="filter-button"
          >
            <FilterIcon
              className="h-5 w-5 cursor-pointer text-gray-400"
              aria-hidden="true"
            />
            {hasItems(inboxParams.filters) && (
              <div className="pointer-events-none absolute right-1 mb-5 flex h-4 w-4 min-w-min grow items-center justify-center rounded-full bg-indigo-500 px-1">
                <span className="text-xs font-medium text-indigo-100">
                  {inboxParams.filters.length > 9
                    ? "9+"
                    : inboxParams.filters.length}
                </span>
              </div>
            )}
          </button>
        </div>
      </form>
    </>
  );
}

export default ConversationListHeader;
