import { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Formik } from "formik";
import StateButton from "src/components/StateButton";
import APIErrors from "src/components/base/APIErrors";
import { useChannel } from "src/hooks/useChannel";
import useHilosStore from "src/hooks/useHilosStore";
import { transformNullsToUndefined } from "src/Helpers";
import { API_ROUTES, buildAPIRoute } from "src/router/router";
import { axiosErr } from "src/types/axios";
import { ChannelProvider, ChannelType } from "src/types/channel";
import ChannelForm from "./ChannelForm";
import ChannelProxyForm from "./ChannelProxyForm";
import * as meta from "./Meta";
import MetaCloudAPIForm from "./MetaCloudAPIForm";
import * as cloudAPIMeta from "./MetaCloudAPISettingsMeta";

interface ChannelCloudAPIUpdateProps {
  channelId: string;
}

const ChannelCloudAPIUpdate: React.FC<ChannelCloudAPIUpdateProps> = ({
  channelId,
}) => {
  const { t } = useTranslation();
  const { channel } = useChannel(channelId);
  const { session, reloadSession } = useHilosStore();
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});

  const onSubmit = async (formValues, setSubmitting) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.patch(
        buildAPIRoute(API_ROUTES.CHANNEL_DETAIL, { ":id": channelId }),
        formValues
      );
      setSubmitting(false);
      setSuccess(true);
      reloadSession();
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;
      setSuccess(false);
      if (errorAxios?.response?.status === 400) {
        console.error("Validation error:", errorAxios.response.data);
        setBackendValidationErrors(errorAxios.response.data);
      } else {
        setBackendError(
          t(
            "channel:update.error",
            "Couldn't update your channel information. Please try again later."
          )
        );
      }
      setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setSubmitted(true);
    }
  };

  if (!channel) {
    return (
      <div className="text-center py-4">
        <p>{t("channel:loading", "Loading channel information...")}</p>
      </div>
    );
  }

  const initialValues = (() => {
    if (!channel) {
      return {
        ...meta.getInitialValues(
          ChannelType.WHATSAPP,
          ChannelProvider.META_CLOUD_API
        ),
        settings: cloudAPIMeta.getInitialValues(),
      };
    }
    const initialVals = { ...channel };
    initialVals.is_default = channel.id === session?.account.default_channel;
    transformNullsToUndefined(initialVals);
    return initialVals;
  })();

  return (
    <div className="space-y-6">
      <Formik
        validationSchema={meta.getSchema(channel.channel_provider)}
        onSubmit={(values, { setSubmitting }) =>
          onSubmit(values, setSubmitting)
        }
        enableReinitialize={true}
        initialValues={initialValues}
      >
        {(formik) => (
          <form
            className="space-y-6 p-6"
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <div className="space-y-3">
              <APIErrors
                APIError={backendError}
                APIValidationErrors={backendValidationErrors}
                fieldTranslations={meta.FIELDS}
              />

              <div className="px-4 py-3">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {t("channel:settings.basic_title", "Basic Information")}
                </h3>
                <div className="mt-5">
                  <ChannelForm />
                </div>
              </div>

              {channel.channel_provider === "META_CLOUD_API" && (
                <div className="px-4 py-3">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    {t(
                      "channel:meta-cloud-api-settings",
                      "Meta Cloud API Settings"
                    )}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    {t(
                      "channel:meta-cloud-api-settings-description",
                      "Here you can see and change your channel configuration."
                    )}
                  </p>
                  <div className="mt-5">
                    <MetaCloudAPIForm />
                  </div>
                </div>
              )}

              <ChannelProxyForm formik={formik} />

              <div className="flex justify-end mr-3">
                <StateButton
                  isSubmitting={formik.isSubmitting}
                  submitted={submitted}
                  success={success}
                  submittingText={t(
                    "channel:update.submitting",
                    "Updating channel..."
                  )}
                  successText={t("channel:update.success", "Channel updated!")}
                  initialText={
                    <>
                      <span className="fe fe-save mr-2"></span>
                      {t("save-changes", "Save Changes")}
                    </>
                  }
                />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ChannelCloudAPIUpdate;
