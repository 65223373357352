import { FlowExecutionStepReadDetail } from "@hilos/types/private-schema";
import HTTPResponseRenderResponse from "src/components/HTTPResponseRender/HTTPResponseRenderResponse";

interface FlowExecutionStepActiveCampaignContactUpsertDetailProps {
  executionStep: FlowExecutionStepReadDetail;
}

export default function FlowExecutionStepActiveCampaignContactUpsertDetail({
  executionStep,
}: FlowExecutionStepActiveCampaignContactUpsertDetailProps) {
  return (
    <HTTPResponseRenderResponse
      response={executionStep.execution_result.response}
    />
  );
}
