import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ActiveCampaignAutomations } from "@hilos/types/private-schema";
import { useStepField } from "src/containers/flow/builder/hooks/useStepField";
import SelectorField from "src/components/Form/SelectorField";
import useActiveCampaignAppConfig from "src/hooks/useActiveCampaignAppConfig";
import useActiveCampaignAutomations from "src/hooks/useActiveCampaignAutomations";
import ActiveCampaignCTAInstallApp from "./ActiveCampaignCTAInstallApp";

interface FlowBuilderStepActiveCampaignAutomationProps {
  id: string;
  index: number;
}

function FlowBuilderStepActiveCampaignAutomation({
  id,
  index,
}: FlowBuilderStepActiveCampaignAutomationProps) {
  const [t] = useTranslation();
  const { acAutomations } = useActiveCampaignAutomations();
  const { data: activeCampaignAppConfig } = useActiveCampaignAppConfig();

  const [ACAutomationSelected, setACAutomationSelected] = useStepField({
    index,
    name: "ac_automation_selected",
  });
  const [ACAutomationAction] = useStepField({
    index,
    name: "ac_automation_action",
  });
  const [_, setACAutomationName] = useStepField({
    index,
    name: "ac_automation_name",
  });

  const formatOptionLabel = ({ value, label, automation }) => {
    return (
      <div className="py-2">
        <div className="flex items-center justify-between">
          {automation && (
            <>
              <div>
                <h4 className="break-all font-medium">{label}</h4>
              </div>
            </>
          )}
        </div>
        <div className="truncate text-xs space-x-1">
          {automation &&
            automation.categories?.map((category) => (
              <span
                className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-600 ring-1 ring-inset ring-indigo-500/10"
                key={category}
              >
                {category}
              </span>
            ))}
        </div>
      </div>
    );
  };

  const acAutomationOptions = useMemo(() => {
    return acAutomations.map((f: ActiveCampaignAutomations) => {
      return {
        label: f.name,
        value: f.id,
        automation: f,
      };
    });
  }, [acAutomations]);

  const handleACAutomationChange = useCallback(
    async (nextSelectedAutomationId) => {
      const acAutomationDetails = acAutomationOptions.find(
        (el) => el.value === nextSelectedAutomationId
      );
      setACAutomationSelected(acAutomationDetails?.automation);
      setACAutomationName(acAutomationDetails?.automation.name);
    },
    [setACAutomationSelected, acAutomationOptions, setACAutomationName]
  );

  return (
    <div className="space-y-2">
      {!activeCampaignAppConfig?.api_key_ro && (
        <div className="my-4">
          <ActiveCampaignCTAInstallApp />
        </div>
      )}
      <SelectorField
        label={
          ACAutomationAction === "START"
            ? t(
                "flows:steps.activecampaign.automation-start.label",
                "ActiveCampaign automation to start"
              )
            : t(
                "flows:steps.activecampaign.automation-end.label",
                "ActiveCampaign automation to end"
              )
        }
        name={`steps.${index}.ac_automation_id`}
        options={acAutomationOptions}
        //@ts-ignore
        formatOptionLabel={formatOptionLabel}
        onSelect={(option) => {
          //@ts-ignore
          handleACAutomationChange(option.value);
        }}
      />

      {ACAutomationSelected && (
        <img
          src={ACAutomationSelected.screenshot}
          alt="screenshot"
          className="w-full"
        />
      )}
    </div>
  );
}

export default FlowBuilderStepActiveCampaignAutomation;
