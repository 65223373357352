import { useTranslation } from "react-i18next";
import { FlowStepEdit } from "@hilos/types/private-schema";

interface FlowStepActiveCampaignContactGetDetailProps {
  step: FlowStepEdit;
}

export default function FlowStepActiveCampaignContactGetDetail({
  step,
}: FlowStepActiveCampaignContactGetDetailProps) {
  const { t } = useTranslation();

  return (
    <div className="space-y-4">
      <div>
        <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
          {t(
            "flow-executions:steps-detail.hubspot-contact-get.custom-properties",
            "Properties to retreive"
          )}
        </h3>

        <div className="gap-2">
          {step.ac_custom_fields.map((field) => (
            <span
              className="inline-flex items-center rounded-full bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-600 ring-1 ring-inset ring-indigo-500/10"
              key={field}
            >
              {JSON.parse(field).label}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}
