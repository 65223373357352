export default function ActiveCampaignIcon({
  className,
}: React.ComponentProps<"svg">) {
  return (
    <img
      className={className}
      src="/images/integrations/AC_Glyph_Blue Cropped.png"
      alt=""
    />
  );
}
