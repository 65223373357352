import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { ActiveCampaignAutomations } from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "../router/router";

function useActiveCampaignAutomations() {
  const fetchActiveCampaignAutomations = useCallback(async () => {
    const { data } = await axios.get<ActiveCampaignAutomations[]>(
      buildAPIRoute(API_ROUTES.ACTIVECAMPAIGN_AUTOMATIONS)
    );
    return data;
  }, []);

  const { data: acAutomations = [] } = useQuery(
    ["ac_automations"],
    fetchActiveCampaignAutomations
  );

  return {
    acAutomations,
  };
}

export default useActiveCampaignAutomations;
