import { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ArrowSmRightIcon } from "@heroicons/react/outline";
import { FieldArray, Formik } from "formik";
import { Channel } from "@hilos/types/private-schema";
import ChannelBadge from "src/containers/config/channel/ChannelBadge";
import SwitchField from "src/components/Form/SwitchField";
import StateButton from "src/components/StateButton";
import APIErrors from "src/components/base/APIErrors";
import { formatPhone } from "src/Helpers";
import * as meta from "./Meta";

interface UserV1FormProps {
  data: { available_channels: boolean[]; permissions: any };
  channels: Channel[];
  formSubmit: (
    formData: any,
    setSubmitting: any,
    setBackendValidationErrors: any,
    setBackendError: any
  ) => void;
  success: boolean;
  submitted: boolean;
}

export default function UserV1Form({
  data,
  channels,
  formSubmit,
  success,
  submitted,
}: UserV1FormProps) {
  const { t } = useTranslation();
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});

  const getPermissionsByCategory = useCallback((category) => {
    return Object.getOwnPropertyNames(meta.PERMISSIONS).filter(
      (perm) => meta.PERMISSIONS[perm].category === category
    );
  }, []);

  return (
    <Formik
      onSubmit={(values, { setSubmitting }) =>
        formSubmit(
          values,
          setSubmitting,
          setBackendValidationErrors,
          setBackendError
        )
      }
      enableReinitialize={true}
      initialValues={data}
    >
      {(formik) => (
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="my-4">
            <APIErrors
              APIError={backendError}
              APIValidationErrors={backendValidationErrors}
              fieldTranslations={meta.FIELDS}
            />
          </div>

          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <Trans i18nKey="settings:users.update.permissions">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Permissions
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    Select the permissions this user will have.
                  </p>
                </Trans>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="shadow sm:overflow-hidden sm:rounded-md">
                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 space-y-6">
                      {Object.getOwnPropertyNames(
                        meta.PERMISSION_CATEGORIES
                      ).map((category) => (
                        <div key={category}>
                          <h4 className="flex items-center text-tiny font-medium uppercase tracking-wider text-gray-500">
                            {meta.PERMISSION_CATEGORIES[category].icon}
                            {t(meta.PERMISSION_CATEGORIES[category].label)}
                          </h4>
                          <div className="space-y-2 mt-2">
                            {getPermissionsByCategory(category).map(
                              (permission) => (
                                <SwitchField
                                  key={permission}
                                  name={`permissions.${permission}`}
                                  label={t(meta.PERMISSIONS[permission].label)}
                                />
                              )
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="md:grid md:grid-cols-3 md:gap-6 mt-10">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <Trans i18nKey="settings:users.update.channels">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Channels
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    Select the channels this user will have access to.
                  </p>
                </Trans>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="shadow sm:overflow-hidden sm:rounded-md">
                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 space-y-4">
                      <FieldArray
                        name="available_channels"
                        render={(_) => (
                          <>
                            {formik.values.available_channels.map((_, idx) => (
                              <div key={idx}>
                                <SwitchField
                                  name={`available_channels.${idx}`}
                                  label={
                                    <div className="flex items-center space-x-2">
                                      <div className="text-sm text-gray-500">
                                        {channels[idx].channel_type ===
                                          "WHATSAPP" &&
                                          formatPhone(
                                            channels[idx].channel_id,
                                            true,
                                            true
                                          )}
                                      </div>
                                      <ChannelBadge channel={channels[idx]} />
                                    </div>
                                  }
                                />
                              </div>
                            ))}
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <div className="text-right">
              <StateButton
                isSubmitting={formik.isSubmitting}
                submitted={submitted}
                success={success}
                btnClasses={`inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-violet-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2 ${
                  formik.errors &&
                  Object.getOwnPropertyNames(formik.errors).length > 0
                    ? "disabled:opacity-50 cursor-not-allowed"
                    : ""
                }`}
                disabled={
                  formik.errors &&
                  Object.getOwnPropertyNames(formik.errors).length > 0
                }
                submittingText={t("updating")}
                successText={t("updated")}
                initialText={
                  <>
                    {t("update")}
                    <ArrowSmRightIcon
                      className="ml-1 h-5 w-5"
                      aria-hidden="true"
                    />
                  </>
                }
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}
