import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ChatAlt2Icon } from "@heroicons/react/outline";
import axios from "axios";
import HeaderNavigation from "src/components/HeaderNavigation";
import Loading from "src/components/Loading";
import { useChannelAvailability } from "src/hooks/useChannel";
import {
  API_ROUTES,
  BASE_API_URL,
  buildAPIRoute,
  buildRoute,
} from "src/router/router";
import WidgetForm from "./WidgetForm";

export default function WidgetCreate() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { data: channelData, isLoading } = useChannelAvailability();

  const initialValues = useMemo(() => {
    const getInitialValues = () => {
      if (!channelData || channelData.length === 0) {
        return null;
      }

      const defaultChannel = channelData[0]?.channel;
      return {
        phone: defaultChannel.channel_id,
        name: "",
        channel: defaultChannel.id,
        default_text: "",
        button_1_text: "",
        button_2_text: "",
        button_3_text: "",
        bg_color: "#72D66F",
        icon_color: "#FFFFFF",
        padding: 20,
        position: "BOTTOM_RIGHT" as const,
      };
    };

    return getInitialValues();
  }, [channelData]);

  const formSubmit = async (
    formData,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      const response = await axios.post(
        buildAPIRoute(API_ROUTES.WHATSAPP_BUTTON_CREATE),
        formData,
        {
          baseURL: BASE_API_URL,
          headers: {
            "x-module": "widget",
          },
        }
      );
      setSuccess(true);
      setTimeout(() => {
        navigate(buildRoute("wa-widget-edit", { id: response.data.id }), {
          replace: true,
        });
      }, 2000);
    } catch (error) {
      setSuccess(false);
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        setBackendValidationErrors(error.response.data);
      } else {
        setBackendError(t("widget:error-saving", "Error saving widget"));
      }
    } finally {
      setSubmitting(false);
      setSubmitted(true);
      setTimeout(() => {
        setSubmitted(false);
      }, 2000);
    }
  };

  if (isLoading) {
    return (
      <div className="flex flex-col justify-center items-center h-64 space-y-4">
        <Loading />
        <p className="text-sm text-gray-500">
          {t("widget:loading-channels", "Loading channels...")}
        </p>
      </div>
    );
  }

  if (!channelData || channelData.length === 0) {
    return (
      <div className="text-center py-12">
        <p className="text-gray-500">
          {t("widget:no-channels.title", "No channels available")}
        </p>
      </div>
    );
  }

  return (
    <>
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("home"),
              url: buildRoute("dashboard"),
            },
            {
              name: t("widget:list-title", "WhatsApp Widgets"),
              url: buildRoute("wa-widget"),
            },
            {
              name: t("widget:new-title", "New Widget"),
              url: "#",
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <ChatAlt2Icon className="mr-4 h-7 w-7" aria-hidden="true" />
              {t("widget:new-title", "New Widget")}
            </h2>
            <p className="mt-1 text-sm text-gray-600">
              {t(
                "widget:form-description",
                "Customize your widget's appearance and behavior."
              )}
            </p>
          </div>
        </div>
      </div>

      {initialValues && (
        <WidgetForm
          initialValues={initialValues}
          formSubmit={formSubmit}
          success={success}
          submitted={submitted}
          action="create"
        />
      )}
    </>
  );
}
