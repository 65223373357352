import ConfettiExplosion from "react-confetti-explosion";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import { ArrowRightIcon } from "lucide-react";
import { axiosErr } from "@hilos/types/axios";
import HilosIcon from "src/components/icons/HilosIcon";
import useHilosStore from "src/hooks/useHilosStore";
import { API_ROUTES, buildAPIRoute, buildRoute } from "../../router/router";

export default function OnboardingInWAConfirm() {
  const { t } = useTranslation();
  const { session } = useHilosStore();
  const navigate = useNavigate();

  if (session && session.account.status !== "IN_SANDBOX") {
    return null;
  }

  const continueToHome = async () => {
    try {
      await axios.post(buildAPIRoute(API_ROUTES.ONBOARDING_CONFIRM));
      navigate(buildRoute("dashboard"));
    } catch (error) {
      const err = error as axiosErr;
      Sentry.captureException(err);
      console.log("error", err);
    }
  };

  return (
    <div className="flex min-h-full justify-center">
      <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full sm:w-[300px] md:w-[500px]">
          <div className="text-center">
            <ConfettiExplosion
              {...{
                force: 0.6,
                duration: 4000,
                particleCount: 80,
                width: 1600,
              }}
            />
            <div className="flex items-center justify-center">
              <span className="mr-1 rounded-full bg-hilos opacity-70 w-3 h-3"></span>
              <span className="mr-1.5 rounded-full bg-hilos opacity-80 w-4 h-4"></span>
              <span className="mr-2 rounded-full bg-hilos opacity-90 w-6 h-6"></span>
              <span className="mr-3 right-8 rounded-full bg-hilos">
                <HilosIcon
                  aria-hidden="true"
                  className="size-12 text-white p-2"
                />
              </span>
            </div>

            <h1 className="mt-5 mb-1 text-2xl font-bold leading-7 text-gray-900">
              🥳 {t("settings:onboarding.ready", "Your account is ready!")}
            </h1>
            <p className="mt-4 text-sm text-gray-500">
              {t(
                "settings:onboarding.help-button",
                "We've finished setting up your account. Click on the button below to explore what you can do with WhatsApp and Hilos."
              )}
            </p>
            <div className="mt-4">
              <div className="mx-auto w-[280px]">
                <button
                  type="button"
                  rel="noreferrer"
                  className="inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-violet-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
                  onClick={continueToHome}
                >
                  {t("settings:onboarding.confirm-button", "Continue to Hilos")}
                  <ArrowRightIcon className="ml-1" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
