import { useCallback } from "react";
import { UseMutateAsyncFunction, useMutation, useQuery } from "react-query";
import axios from "axios";
import { ActiveCampaignAppConfig } from "@hilos/types/private-schema";
import { queryClient } from "src/HilosProvider";
import { API_ROUTES } from "src/router/router";

export type UpdateActiveCampaignDetailsFn = UseMutateAsyncFunction<
  ActiveCampaignAppConfig | null,
  unknown,
  ActiveCampaignAppConfig,
  unknown
>;

export function useActiveCampaignAppConfig() {
  const fetchActiveCampaign = useCallback(async () => {
    const { data } = await axios.get<ActiveCampaignAppConfig>(
      API_ROUTES.ACTIVECAMPAIGN_SETTINGS
    );
    return data;
  }, []);

  const { data, refetch, isLoading } = useQuery(
    "activecampaign_settings",
    fetchActiveCampaign
  );

  return { data, refetch, isLoading };
}

export default useActiveCampaignAppConfig;

export function useActiveCampaignAppConfigUpdate() {
  const updateActiveCampaignAppDetails = useCallback(
    async (payload: ActiveCampaignAppConfig) => {
      const { data } = await axios.patch<ActiveCampaignAppConfig>(
        API_ROUTES.ACTIVECAMPAIGN_SETTINGS,
        payload
      );
      return data;
    },
    []
  );

  const {
    mutateAsync: handleUpdateActiveCampaignAppConfig,
    isLoading: isSubmittingStatus,
  } = useMutation(updateActiveCampaignAppDetails, {
    onSuccess: (nextData) => {
      let currentAppData = queryClient.getQueryData<ActiveCampaignAppConfig>(
        "activecampaign_settings"
      );
      if (currentAppData) {
        currentAppData = {
          ...currentAppData,
          ...nextData,
        };
      }
      queryClient.setQueryData("activecampaign_settings", currentAppData);
    },
  });

  return {
    handleUpdateActiveCampaignAppConfig,
    isSubmittingStatus,
  };
}
