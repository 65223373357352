import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import MappedValuesField from "src/containers/flow/builder/components/MappedValuesField";
import TextInputFieldWithVariables from "src/containers/flow/builder/components/TextInputFieldWithVariables";
import SelectorField from "src/components/Form/SelectorField";
import LineTabs from "src/components/LineTabs";
import useActiveCampaignAppConfig from "src/hooks/useActiveCampaignAppConfig";
import { useActiveCampaignContactFields } from "src/hooks/useActiveCampaignContactFields";
import { classNames } from "src/Helpers";
import ActiveCampaignCTAInstallApp from "./ActiveCampaignCTAInstallApp";

interface FlowBuilderStepActiveCampaignContactUpsertProps {
  index: number;
}

function FlowBuilderStepActiveCampaignContactUpsert({
  index,
}: FlowBuilderStepActiveCampaignContactUpsertProps) {
  const { t } = useTranslation();
  const { data, fieldsOptions } = useActiveCampaignContactFields();
  const { data: activeCampaignSettings } = useActiveCampaignAppConfig();

  const activecampaignContactPropertiesOptions = useMemo(
    () =>
      data
        .map((property) => ({
          label: property.label,
          value: property.name,
        }))
        .sort((a, b) => a.label.length - b.label.length),
    [data]
  );

  return (
    <div className="mt-2">
      {!activeCampaignSettings?.api_key_ro && (
        <div className="my-4">
          <ActiveCampaignCTAInstallApp />
        </div>
      )}
      <LineTabs
        labels={[
          t(
            "flows:steps.activecampaign-contact-upsert.tabs.default",
            "Default"
          ),
          t("flows:steps.activecampaign-contact-upsert.tabs.custom", "Custom"),
        ]}
        disabled={!activeCampaignSettings?.api_key_ro}
      >
        <div
          className={classNames(
            "space-y-4 mt-2",
            !activeCampaignSettings?.api_key_ro && "opacity-50"
          )}
        >
          <TextInputFieldWithVariables
            type="text"
            path={`steps.${index}`}
            name={"firstname"}
            currentStepIndex={index}
            label={t(
              "flows:steps.activecampaign-contact-upsert.contact-first-name.label",
              "First name"
            )}
            disabled={!activeCampaignSettings?.api_key_ro}
          />
          <TextInputFieldWithVariables
            type="text"
            path={`steps.${index}`}
            name="lastname"
            currentStepIndex={index}
            label={t(
              "flows:steps.activecampaign-contact-upsert.contact-last-name.label",
              "Last name"
            )}
            disabled={!activeCampaignSettings?.api_key_ro}
          />
          <TextInputFieldWithVariables
            type="text"
            path={`steps.${index}`}
            name="email"
            currentStepIndex={index}
            label={t(
              "flows:steps.activecampaign-contact-upsert.contact-email.label",
              "Email"
            )}
            disabled={!activeCampaignSettings?.api_key_ro}
          />
        </div>
        <MappedValuesField
          path={`steps.${index}`}
          objectName={t(
            "flows:steps.activecampaign-contact-upsert.add-custom-property",
            "HubSpot custom property"
          )}
          name={"contact_custom_properties"}
          currentStepIndex={index}
          addButtonLabel={t(
            "flows:steps.activecampaign-contact-upsert.add-custom-property",
            "Add property"
          )}
          fieldsOptions={fieldsOptions}
          customOptionsNoticeMessage={t(
            "flows:steps.activecampaign-contact-upsert.custom-properties-notice",
            "Make sure that the values you send in the variable are one of the following. Without an exact match, ActiveCampaign will not be able to update the contact."
          )}
          KeyComponent={(indexKey) => (
            <SelectorField
              isCreatable={false}
              name={`steps.${index}.contact_custom_properties.${indexKey}.key`}
              options={activecampaignContactPropertiesOptions}
            />
          )}
        />
      </LineTabs>
    </div>
  );
}

export default FlowBuilderStepActiveCampaignContactUpsert;
