import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Node, useReactFlow } from "reactflow";
import { Transition } from "@headlessui/react";
import {
  ExclamationIcon,
  LockClosedIcon,
  XIcon,
} from "@heroicons/react/outline";
import { FlowNodeData } from "@hilos/types/flow";
import useHilosStore from "src/hooks/useHilosStore";
import useWrappedSteps from "src/hooks/useWrappedSteps";
import { classNames } from "src/Helpers";
import {
  STEP_TYPES_AVAILABLE_PLAN_SCALE,
  STEP_TYPES_WITHOUT_NEXT,
  StepTypes,
} from "../constants/steps";
import { SelectedItemState } from "../hooks/useFlowLayout";

interface FlowBuilderAddStepProps {
  selectedItem: SelectedItemState;
  onAddStep: (type: StepTypes) => void;
  onClearSelectItem: () => void;
}

function FlowBuilderAddStep({
  selectedItem,
  onAddStep,
  onClearSelectItem,
}: FlowBuilderAddStepProps) {
  const [t] = useTranslation();
  const { getNode } = useReactFlow();
  const { session } = useHilosStore();
  const [selectedStepType, setSelectedStepType] = useState<StepTypes | null>(
    "TEMPLATE"
  );
  const { STEP_TYPES_LIST, STEP_TYPES_DYNAMIC } = useWrappedSteps();

  const ACTIVE_STEP_TYPES_LIST = useMemo(() => {
    return STEP_TYPES_LIST.filter(
      (step) => !("deprecated" in step && step.deprecated)
    );
  }, [STEP_TYPES_LIST]);

  const [show, hasNextStep] = useMemo(() => {
    if (
      selectedItem &&
      selectedItem.type &&
      selectedItem.type.includes("edge") &&
      !(selectedItem.data && selectedItem.data.isUnreachable)
    ) {
      const targetNode: Node<FlowNodeData> | null =
        // @ts-ignore
        (selectedItem.target && getNode(selectedItem.target)) || null;

      return [
        true,
        Boolean(
          targetNode?.data?.type && targetNode.data.type !== "ADD_STEP_NODE"
        ),
      ];
    }
    return [false, false];
  }, [selectedItem, getNode]);

  const StepDescriptionComponent = useMemo(() => {
    return selectedStepType && STEP_TYPES_DYNAMIC[selectedStepType]
      ? STEP_TYPES_DYNAMIC[selectedStepType].descriptionComponent
      : null;
  }, [STEP_TYPES_DYNAMIC, selectedStepType]);

  useEffect(() => {
    if (!show) {
      setSelectedStepType("TEMPLATE");
    }
  }, [show]);

  const isStepTypeAvailableForAccountPricingPlan = useMemo(() => {
    return !(
      session?.account.pricing_model_version === "v2" &&
      session?.account.pricing_plan_selected === "LAUNCH" &&
      selectedStepType &&
      STEP_TYPES_AVAILABLE_PLAN_SCALE.includes(selectedStepType)
    );
  }, [selectedStepType, session]);

  const canAddStep = useMemo(() => {
    if (!selectedStepType) {
      return false;
    }
    if (hasNextStep && STEP_TYPES_WITHOUT_NEXT.includes(selectedStepType)) {
      return false;
    }

    return isStepTypeAvailableForAccountPricingPlan;
  }, [selectedStepType, isStepTypeAvailableForAccountPricingPlan, hasNextStep]);

  return (
    <Transition
      show={show}
      enter="transition duration-150 ease-out"
      enterFrom="transform scale-90 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-90 opacity-0"
      className="absolute m-auto left-0 right-0 top-20 bottom-0 z-20 h-fit w-5/6 lg:max-w-4xl rounded-lg bg-gray-50 p-4 shadow-xl overflow-auto"
    >
      <div className="flex w-full flex-row items-center justify-between pb-3">
        <div className="flex-col">
          <h3 className="text-md font-semibold">
            {t("flows:editor.add-step.title", "Add step")}
          </h3>
          <h4 className="text-xs">
            {t(
              "flows:editor.add-step.description",
              "Select type of step to be used"
            )}
            .
          </h4>
        </div>
        <button
          type="button"
          className="px-1 text-gray-700 focus:outline-none"
          onClick={onClearSelectItem}
        >
          <XIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="flex h-[28rem] w-full flex-row">
        <div className="flex h-[28rem] w-[13rem] flex-col space-y-2 overflow-y-scroll scroll-py-2 snap-y py-2 pr-2 text-sm">
          {" "}
          {/** 12 - 16 rem */}
          {ACTIVE_STEP_TYPES_LIST.map(
            ({ type, name, icon: RenderIcon }, index) => (
              <button
                key={type}
                type="button"
                onClick={() => setSelectedStepType(type)}
                className={classNames(
                  "relative flex flex-row w-full text-left items-center cursor-pointer rounded-lg p-2",
                  selectedStepType === type
                    ? "bg-blue-100 text-blue-800"
                    : "bg-gray-100 text-gray-800",
                  ACTIVE_STEP_TYPES_LIST.length - 1 === index
                    ? "snap-end"
                    : "snap-start"
                )}
              >
                <RenderIcon className="h-4 w-4 mr-1" />
                {t(name)}
                {session?.account.pricing_model_version === "v2" &&
                  session?.account.pricing_plan_selected === "LAUNCH" &&
                  STEP_TYPES_AVAILABLE_PLAN_SCALE.includes(type) && (
                    <span className="absolute items-center rounded-full bg-indigo-500 px-1.5 py-1 text-xs font-medium text-indigo-50 ring-1 ring-inset ring-indigo-700/10 -top-2 -right-2">
                      <LockClosedIcon className="h-3 w-3 text-white" />
                    </span>
                  )}
              </button>
            )
          )}
        </div>
        <div className="flex flex-1 w-full overflow-y-auto flex-col border-l border-gray-300 px-2 justify-between space-y-2">
          {Boolean(
            hasNextStep &&
              selectedStepType &&
              STEP_TYPES_WITHOUT_NEXT.includes(selectedStepType)
          ) && (
            <div className="rounded-md bg-yellow-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationIcon
                    className="h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <div className="text-sm text-yellow-700">
                    <p>
                      {t(
                        "flows:editor.add-step.has-next-step",
                        "This type of step can only be added at the end of a flow."
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {StepDescriptionComponent && <StepDescriptionComponent />}
          <div className="sticky bottom-0 flex justify-end w-full py-2 bg-gradient-to-t from-gray-50 via-gray-50">
            <button
              type="button"
              disabled={!canAddStep}
              className="inline-flex rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-gray-400 disabled:text-gray-50"
              onClick={() => onAddStep(selectedStepType as StepTypes)}
            >
              {isStepTypeAvailableForAccountPricingPlan
                ? t("flows:editor.add-step.add", "Add")
                : t(
                    "settings:billing.launch.flow-step-notice",
                    "Enable this step by upgrading to the Scale plan."
                  )}
            </button>
          </div>
        </div>
      </div>
    </Transition>
  );
}

export default FlowBuilderAddStep;
