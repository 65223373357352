import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlayIcon } from "@heroicons/react/outline";
import axios from "axios";
import { getIn, useFormikContext } from "formik";
import { cloneDeep } from "lodash";
import SwitchField from "src/components/Form/SwitchField";
import { hasItems } from "src/helpers/utils";
import { API_ROUTES, buildAPIRoute } from "src/router/router";
import HTTPForm from "../../HTTPForm/HTTPForm";
import FlowBuilderStepActionResponse from "./FlowBuilderStepActionResponse";

interface FlowBuilderStepActionProps {
  index: number;
}

function isValidKeyValue(item) {
  return item !== null && item !== undefined && item !== "";
}

function getCleanedValues(data) {
  if (hasItems(data)) {
    return data.filter(
      (item) => isValidKeyValue(item.key) && isValidKeyValue(item.value)
    );
  }
  return [];
}

function FlowBuilderStepAction({ index }: FlowBuilderStepActionProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { values, setFieldValue } = useFormikContext();

  const handleTestAction = useCallback(async () => {
    setLoading(true);
    const originalStep = getIn(values, `steps.${index}`, {});
    const currentStep = cloneDeep(originalStep);
    let originalBody = currentStep.action_request_body;

    if (Array.isArray(currentStep.action_request_body)) {
      currentStep.action_request_body = getCleanedValues(
        currentStep.action_request_body
      );
      originalBody = currentStep.action_request_body;
      currentStep.action_request_body = JSON.stringify(
        currentStep.action_request_body
      );
    }
    currentStep.action_request_headers = getCleanedValues(
      currentStep.action_request_headers
    );
    currentStep.action_request_params = getCleanedValues(
      currentStep.action_request_params
    );

    try {
      const response = await axios.post(
        buildAPIRoute(API_ROUTES.FLOW_V2_STEP_ACTION_TEST),
        currentStep
      );
      if (response.status === 201 && typeof response.data === "object") {
        if (!currentStep["action_responses"]) {
          currentStep["action_responses"] = [];
        }

        currentStep.action_request_body = originalBody;
        currentStep["action_responses"].unshift({
          created_on: new Date(),
          data: response.data,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setFieldValue(`steps.${index}`, currentStep);
      setLoading(false);
    }
  }, [values, index, setFieldValue]);

  return (
    <div className="space-y-4">
      <HTTPForm
        path={`steps.${index}`}
        fieldMethodName="action_request_method"
        fieldBodyName="action_request_body"
        fieldHeadersName="action_request_headers"
        fieldURLName="action_request_url"
        fieldParamsName="action_request_params"
        currentStepIndex={index}
      />
      <button
        type="button"
        className="mt-4 inline-flex w-full items-center justify-center rounded-md border border-green-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-green-500 shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-green-500"
        onClick={handleTestAction}
      >
        <PlayIcon className="mr-2 h-5 w-5" aria-hidden="true" />
        {loading
          ? t("flows:steps.action.requesting", "Requesting...")
          : t("flows:steps.action.test-request", "Test Request")}
      </button>
      <SwitchField
        name={`steps.${index}.action_request_body_escape_variables`}
        label={t(
          "flows:steps.action.action-request-body-escape-variables.label",
          "Format escape characters in variables."
        )}
        help={t(
          "flows:steps.action.action-request-body-escape-variables.help",
          "If enabled, the special characters in the variable text will be formatted."
        )}
      />
      <FlowBuilderStepActionResponse name={`steps.${index}.action_responses`} />
    </div>
  );
}

export default FlowBuilderStepAction;
