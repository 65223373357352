import { useTranslation } from "react-i18next";
import { HILOS_CHANNELS_STATUSES } from "./Meta";

export default function ChannelStatusBadge({ status }) {
  const { t } = useTranslation();
  return (
    <span
      className={`inline-flex items-center rounded-full px-3 py-0.5 text-xs font-medium uppercase ${HILOS_CHANNELS_STATUSES[status]?.className}`}
    >
      {t(HILOS_CHANNELS_STATUSES[status]?.label)}
    </span>
  );
}
