import axios from "axios";
import {
  WorkflowStateRead,
  WorkflowTaskEdit,
  WorkflowTaskRead,
} from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "src/router/router";
import {
  WorkflowBoardFilters,
  WorkflowBoardStore,
  WorkflowTaskItem,
} from "./WorkflowBoardContext";
import { MoveCardParams } from "./board/Board";

export type StoreHelpersParams = {
  set: (
    partial: WorkflowBoardStore | Partial<WorkflowBoardStore>,
    replace?: boolean | undefined
  ) => void;
  get: () => WorkflowBoardStore;
  workflowId: string;
};

export interface MoveCardParamsWithHelpers extends StoreHelpersParams {
  params: MoveCardParams;
}

export interface FilterTasksParamsWithHelpers extends StoreHelpersParams {
  filters: Partial<WorkflowBoardFilters>;
}

export interface SortByParamsWithHelpers extends StoreHelpersParams {
  sortBy: string;
}

export interface UpdateCardByTaskParamsWithHelpers extends StoreHelpersParams {
  task: WorkflowTaskRead;
}

export interface UpdateStateParamsWithHelpers extends StoreHelpersParams {
  state: WorkflowStateRead;
}

export interface UpdateVisibleColumnsParamsWithHelpers
  extends StoreHelpersParams {
  columnIds: string[];
}

export interface TasksByState {
  [key: string]: Map<string, WorkflowTaskItem>;
}

export const getTasksByState = (
  states: WorkflowStateRead[],
  prevTasksByState: TasksByState = {}
) =>
  states.reduce((nextTasksByState, state) => {
    nextTasksByState[state.id] = nextTasksByState[state.id] || new Map();
    return nextTasksByState;
  }, prevTasksByState);

export const getColumnsOfStates = (states: WorkflowStateRead[]) =>
  states.map((state) => state.id);

const getArrayMapSortBy = <K, V>(
  items: Map<K, V>,
  sortBy: string = "last_updated_on"
) =>
  new Map(
    [...items.entries()].sort((a, b) => {
      if (a[1][sortBy] && b[1][sortBy]) {
        return +new Date(b[1][sortBy]) - +new Date(a[1][sortBy]);
      }
      return -1;
    })
  );

const findIn = (value: any, fields: string[], search: string[]) => {
  for (const field of fields) {
    const fieldValue = `${value[field]}`.toLowerCase();
    if (fieldValue && search.some((key) => fieldValue.includes(key))) {
      return true;
    }
  }

  return false;
};

const getTaskWithFiltersValidator = (filters: WorkflowBoardFilters) => {
  const search = filters.search.toLowerCase().split(" ").filter(Boolean);
  const hasSearch = search.length > 0;
  const hasAssigned = filters.assigned && filters.assigned.length > 0;
  const hasChannels = filters.channels && filters.channels.length > 0;
  const hasFilters = Boolean(hasSearch || hasAssigned || hasChannels);

  return (task: WorkflowTaskItem) => {
    if (!hasFilters) {
      return true;
    }

    if (
      hasSearch &&
      !findIn(
        task.inbox_contact.contact,
        ["phone", "first_name", "last_name", "email"],
        search
      )
    ) {
      return false;
    }

    if (
      hasAssigned &&
      task.assigned_users &&
      !task.assigned_users.some((user) =>
        (filters.assigned as number[]).includes(user)
      )
    ) {
      return false;
    }

    if (
      hasChannels &&
      task.inbox_contact.channel &&
      !filters.channels.includes(task.inbox_contact.channel.id)
    ) {
      return false;
    }

    return true;
  };
};

const updateWorkflowTask = async ({
  id,
  workflowId,
  ...values
}: Partial<WorkflowTaskEdit> & {
  id: string;
  workflowId: string;
}) => {
  try {
    await axios.patch(
      buildAPIRoute(API_ROUTES.WORKFLOW_TASK_DETAIL, {
        ":workflow_id": workflowId,
        ":id": id,
      }),
      values
    );
  } catch {}
};

export const moveCard = ({
  set,
  get,
  params,
  workflowId,
}: MoveCardParamsWithHelpers) => {
  const { tasksByState } = get();
  if (!tasksByState) {
    return;
  }

  const {
    id,
    startColumnId,
    finishColumnId,
    itemIndexInFinishColumn = 0,
  } = params;

  const sourceColumn = tasksByState[startColumnId];
  const destinationColumn = tasksByState[finishColumnId];
  const task = sourceColumn.get(id);

  if (!task) {
    return;
  }

  task.state = finishColumnId;
  task.updating = true;

  const destinationItems = Array.from(destinationColumn);
  sourceColumn.delete(id);
  // Going into the first position if no index is provided
  const newIndexInDestination = itemIndexInFinishColumn ?? 0;
  destinationItems.splice(newIndexInDestination, 0, [id, task]);

  const updatedTasksByState = {
    ...tasksByState,
    [startColumnId]: new Map(sourceColumn),
    [finishColumnId]: new Map(destinationItems),
  };

  set({ tasksByState: updatedTasksByState });

  updateWorkflowTask({
    id: task.id,
    workflowId,
    state: task.state,
  });
};

export const updateFilters = ({
  set,
  get,
  filters,
}: FilterTasksParamsWithHelpers) => {
  const { tasks, filters: prevFilters, sortBy, tasksByState } = get();
  if (!tasksByState) {
    return;
  }
  const updatedTasksByState = {
    ...tasksByState,
  };
  const nextFilters = { ...prevFilters, ...filters };

  const validateWithFilters = getTaskWithFiltersValidator(nextFilters);

  for (const task of tasks.values()) {
    const stateId = task.state;
    if (!tasksByState[stateId]) {
      continue;
    }

    if (validateWithFilters(task)) {
      tasksByState[stateId].set(task.id, task);
    } else {
      tasksByState[stateId].delete(task.id);
    }
  }

  for (const stateId in tasksByState) {
    updatedTasksByState[stateId] = getArrayMapSortBy(
      tasksByState[stateId],
      sortBy
    );
  }

  set({ filters: nextFilters, tasksByState: updatedTasksByState });
};

export const updateSortBy = ({ set, get, sortBy }: SortByParamsWithHelpers) => {
  const { tasksByState } = get();
  if (!tasksByState) {
    return;
  }
  const updatedTasksByState = {};

  for (const stateId in tasksByState) {
    updatedTasksByState[stateId] = getArrayMapSortBy(
      tasksByState[stateId],
      sortBy
    );
  }

  set({ sortBy, tasksByState: updatedTasksByState });
};

export const updateTask = ({
  set,
  get,
  task,
}: UpdateCardByTaskParamsWithHelpers) => {
  const { filters, sortBy, tasks, tasksByState } = get();
  if (!tasksByState) {
    return;
  }

  tasks.set(task.id, task);

  const updatedTasksByState = {
    ...tasksByState,
  };

  const isTaskFiltered = getTaskWithFiltersValidator(filters)(task);

  for (const stateId in tasksByState) {
    const column = tasksByState[stateId];
    if (stateId === task.state && isTaskFiltered) {
      column.set(task.id, task);

      updatedTasksByState[stateId] = getArrayMapSortBy(column, sortBy);
    } else if (column.has(task.id)) {
      column.delete(task.id);
      updatedTasksByState[stateId] = new Map(column);
    }
  }

  set({ tasks, tasksByState: updatedTasksByState });
};

export const updateState = ({
  set,
  get,
  state,
}: UpdateStateParamsWithHelpers) => {
  const { states: prevStates, tasksByState: prevTasksByState } = get();
  const states: WorkflowStateRead[] = [];
  let hasUpdatedState = false;

  for (const prevState of prevStates) {
    if (prevState) {
      if (prevState.id === state.id) {
        hasUpdatedState = true;
        if (!state.is_deleted) {
          states.push(state);
        }
      } else {
        states.push(prevState);
      }
    }
  }

  if (!hasUpdatedState && !state.is_deleted) {
    states.push(state);
  }

  states.sort((a, b) => {
    if (typeof a.index !== "number") return 1;
    if (typeof b.index !== "number") return -1;
    return a.index - b.index;
  });

  const columns = getColumnsOfStates(states);
  const tasksByState = getTasksByState(states, prevTasksByState);

  set({
    states,
    columns,
    tasksByState,
  });
};
