import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LinkTracked from "src/components/Posthog/LinkTracked";
import Logo from "src/assets/img/logo512.png";
import { AccountStatus } from "src/types/account";
import useHilosStore from "../../hooks/useHilosStore";
import { buildRoute } from "../../router/router";
import ConnectToSandboxCard from "../config/ConnectToSandboxCard";

export default function ConnectSandbox() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { session, reloadSession } = useHilosStore();

  useEffect(() => {
    const interval = setInterval(() => {
      reloadSession();
    }, 1000 * 3);
    return () => clearInterval(interval);
  }, [reloadSession]);

  useEffect(() => {
    if (
      session &&
      session.account &&
      session.account.status !== AccountStatus.NEW
    ) {
      navigate(buildRoute("dashboard"));
    }
  }, [session, navigate]);

  if (!session?.account) {
    return null;
  }

  return (
    <div className="w-full h-full overflow-y-auto border-hilos bg-gray-50">
      <div className="mx-auto mt-20 block max-w-[500px]">
        <div className="mb-7 flex justify-center">
          <img src={Logo} alt="logo" width="80" />
        </div>
        <Trans i18nKey="settings:onboarding.start-sandbox">
          <p className="mb-10 text-center text-3xl font-medium"></p>
          <p className="text-justify text-gray-500"></p>
        </Trans>
      </div>
      <div className="mt-10 ">
        <ConnectToSandboxCard />
      </div>
      <div className="my-10 flex justify-center">
        <LinkTracked
          to="/"
          className="mx-auto inline-flex items-center self-center px-8 py-3 font-medium text-slate-400 underline underline-offset-4"
          posthogEvent="sandbox skipped"
        >
          {t("skip-do-later", "Skip, I'll do it later")}
        </LinkTracked>
      </div>
    </div>
  );
}
