import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import FileUploadField from "src/components/Form/FileUploadField";
import TextAreaField from "src/components/Form/TextAreaField";
import StateButton from "src/components/StateButton";
import { ERROR_MESSAGES } from "src/constants/errors";
import { WhatsAppProfile } from "src/types/private-schema/models/WhatsAppProfile";

interface ProfileEditFormProps {
  profile: WhatsAppProfile | undefined;
  onSubmit: (values: Partial<WhatsAppProfile>) => void;
  isSubmitting: boolean;
  isSuccess: boolean;
}

const ProfileEditForm: React.FC<ProfileEditFormProps> = ({
  profile,
  onSubmit,
  isSubmitting,
  isSuccess,
}) => {
  const { t } = useTranslation();

  if (!profile) {
    return (
      <div className="text-center py-4">
        <p>{t("channel:profile.loading", "Loading profile information...")}</p>
      </div>
    );
  }

  const validationSchema = yup.object().shape({
    about: yup.string().max(
      139,
      t(ERROR_MESSAGES.MAX_CHARACTERS, {
        max: 139,
      })
    ),
    description: yup.string().max(
      512,
      t(ERROR_MESSAGES.MAX_CHARACTERS, {
        max: 512,
      })
    ),
    email: yup.string().email(t(ERROR_MESSAGES.INVALID_EMAIL)),
    address: yup.string().max(
      256,
      t(ERROR_MESSAGES.MAX_CHARACTERS, {
        max: 256,
      })
    ),
    websites: yup.array().of(yup.string().url(t(ERROR_MESSAGES.INVALID_URL))),
    vertical: yup.string(),
    profile_picture: yup.string(),
  });

  const initialValues = {
    about: profile.about || "",
    description: profile.description || "",
    email: profile.email || "",
    address: profile.address || "",
    websites: profile.websites || ["", ""],
    vertical: profile.vertical || "",
    profile_picture:
      profile.profile_picture || profile.profile_picture_url || "",
  };
  const verticalOptions = [
    {
      value: "AUTOMOTIVE",
      label: t("channel:profile.vertical.automotive", "Automotive"),
    },
    {
      value: "BEAUTY_SPA_SALON",
      label: t("channel:profile.vertical.beauty", "Beauty, Spa & Salon"),
    },
    {
      value: "CLOTHING",
      label: t("channel:profile.vertical.clothing", "Clothing"),
    },
    {
      value: "EDUCATION",
      label: t("channel:profile.vertical.education", "Education"),
    },
    {
      value: "ENTERTAINMENT",
      label: t("channel:profile.vertical.entertainment", "Entertainment"),
    },
    {
      value: "EVENT_PLANNING_SERVICE",
      label: t("channel:profile.vertical.event", "Event Planning"),
    },
    {
      value: "FINANCE",
      label: t("channel:profile.vertical.finance", "Finance"),
    },
    {
      value: "GROCERY_STORE",
      label: t("channel:profile.vertical.grocery", "Grocery Store"),
    },
    {
      value: "GOVERNMENT",
      label: t("channel:profile.vertical.government", "Government"),
    },
    { value: "HOTEL", label: t("channel:profile.vertical.hotel", "Hotel") },
    { value: "HEALTH", label: t("channel:profile.vertical.health", "Health") },
    {
      value: "NONPROFIT",
      label: t("channel:profile.vertical.nonprofit", "Non-profit"),
    },
    {
      value: "PROF_SERVICES",
      label: t(
        "channel:profile.vertical.professional",
        "Professional Services"
      ),
    },
    {
      value: "SHOPPING_RETAIL",
      label: t("channel:profile.vertical.retail", "Shopping & Retail"),
    },
    {
      value: "TRAVEL_TRANSPORTATION",
      label: t("channel:profile.vertical.travel", "Travel & Transportation"),
    },
    {
      value: "RESTAURANT",
      label: t("channel:profile.vertical.restaurant", "Restaurant"),
    },
    { value: "OTHER", label: t("channel:profile.vertical.other", "Other") },
  ];

  const handleSubmit = (values: Partial<WhatsAppProfile>) => {
    if (
      !values.profile_picture ||
      values.profile_picture === profile.profile_picture_url
    ) {
      const { profile_picture, ...restValues } = values;

      if (Array.isArray(values.websites)) {
        const cleanWebsites = values.websites.filter(
          (website) => website && website.trim() !== ""
        );
        restValues.websites =
          cleanWebsites.length > 0 ? cleanWebsites : undefined;
      }

      onSubmit(restValues);
    } else {
      if (Array.isArray(values.websites)) {
        const cleanWebsites = values.websites.filter(
          (website) => website && website.trim() !== ""
        );
        values.websites = cleanWebsites.length > 0 ? cleanWebsites : undefined;
      }

      onSubmit(values);
    }
  };

  return (
    <div className="bg-white shadow rounded-lg text-left">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, isValid }) => (
          <Form className="space-y-6 p-6">
            <div className="space-y-4">
              <div>
                <label
                  htmlFor="profile_picture"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t(
                    "channel:profile.profile_picture_label",
                    "Profile Picture"
                  )}
                </label>
                <div className="mt-1">
                  <FileUploadField name="profile_picture" media="IMAGE" />
                </div>
                <p className="mt-1 text-xs text-gray-500">
                  {t(
                    "channel:profile.profile_picture_help",
                    "Upload a square image in JPEG or PNG format"
                  )}
                </p>
              </div>

              <div>
                <label
                  htmlFor="about"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("channel:profile.about_label", "About")}
                </label>
                <TextAreaField
                  id="about"
                  name="about"
                  rows={2}
                  placeholder={t(
                    "channel:profile.about_placeholder",
                    "Brief description shown on your WhatsApp profile"
                  )}
                />
                <p className="mt-1 text-xs text-gray-500">
                  {t("channel:profile.about_help", "Maximum 139 characters")} -{" "}
                  {values.about?.length || 0}/139
                </p>
              </div>

              <div>
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("channel:profile.description_label", "Description")}
                </label>
                <TextAreaField
                  id="description"
                  name="description"
                  rows={3}
                  placeholder={t(
                    "channel:profile.description_placeholder",
                    "Detailed description of your business"
                  )}
                />
                <p className="mt-1 text-xs text-gray-500">
                  {t(
                    "channel:profile.description_help",
                    "Maximum 512 characters"
                  )}{" "}
                  - {values.description?.length || 0}/512
                </p>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("channel:profile.email_label", "Email")}
                </label>
                <Field
                  id="email"
                  name="email"
                  type="email"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder={t(
                    "channel:profile.email_placeholder",
                    "contact@yourcompany.com"
                  )}
                />
                {errors.email && touched.email && (
                  <p className="mt-2 text-sm text-red-600">{errors.email}</p>
                )}
              </div>

              <div>
                <label
                  htmlFor="address"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("channel:profile.address_label", "Address")}
                </label>
                <TextAreaField
                  id="address"
                  name="address"
                  rows={2}
                  placeholder={t(
                    "channel:profile.address_placeholder",
                    "Your business address"
                  )}
                />
              </div>

              <div>
                <label
                  htmlFor="vertical"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("channel:profile.vertical_label", "Business Category")}
                </label>
                <Field
                  as="select"
                  id="vertical"
                  name="vertical"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder={t(
                    "channel:profile.vertical_select",
                    "Select business type"
                  )}
                >
                  {verticalOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Field>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  {t("channel:profile.websites_label", "Websites")}
                </label>
                <div className="mt-2">
                  <Field
                    type="text"
                    name="websites[0]"
                    className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-full"
                    placeholder={t(
                      "channel:profile.website_placeholder",
                      "https://example.com"
                    )}
                  />
                </div>
                <div className="mt-2">
                  <Field
                    type="text"
                    name="websites[1]"
                    className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-full"
                    placeholder={t(
                      "channel:profile.website_placeholder",
                      "https://example.com"
                    )}
                  />
                </div>

                {errors.websites && touched.websites && (
                  <p className="mt-2 text-sm text-red-600">{errors.websites}</p>
                )}
              </div>
            </div>

            <div className="flex justify-end space-x-3">
              <StateButton
                isSubmitting={isSubmitting}
                submitted={isSuccess}
                success={isSuccess}
                submittingText={t("channel:profile.saving", "Saving...")}
                successText={t("channel:profile.saved", "Saved!")}
                initialText={<>{t("channel:profile.save", "Save Profile")}</>}
                disabled={!isValid}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProfileEditForm;
