import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  CheckIcon,
  PencilAltIcon,
  PlusSmIcon,
  TerminalIcon,
  XIcon,
} from "@heroicons/react/outline";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import { WebhookSubscription } from "@hilos/types/private-schema";
import DeleteObject, { onDeleteFn } from "src/components/DeleteObject";
import HeaderNavigation from "src/components/HeaderNavigation";
import Table from "src/components/Table/Table";
import useCleanTimeout from "src/hooks/useCleanTimeout";
import { API_ROUTES, buildAPIRoute, buildRoute } from "src/router/router";
import { axiosErr } from "src/types/axios";

export default function WebhookSubscriptionList() {
  const { t } = useTranslation();
  const [triggerReload, setTriggerReload] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);
  const timeout = useCleanTimeout();

  const onDelete: onDeleteFn<WebhookSubscription> = async (
    formData,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.delete(
        buildAPIRoute(API_ROUTES.WEBHOOK_SUBSCRIPTION_DETAIL, {
          ":id": formData.obj.id,
        })
      );
      setDeleteSubmitted(false);
      setDeleteSuccess(true);
      setTriggerReload(!triggerReload);
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;
      setDeleteSuccess(false);
      if (errorAxios?.response?.status === 400) {
        Sentry.captureException(err);
        setBackendValidationErrors(errorAxios.response.data);
      } else {
        setBackendError(t("error-general"));
      }
      timeout.current = setTimeout(() => {
        setDeleteSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setDeleteSubmitted(false);
    }
  };

  return (
    <div className="h-screen overflow-y-auto bg-gray-50">
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("home"),
              url: buildRoute("dashboard"),
            },
            {
              name: t(
                "integrations:webhooks.subscriptions.label",
                "Webhook Subscriptions"
              ),
              url: buildRoute("webhook-list"),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <Trans i18nKey="integrations:webhooks.subscriptions.description">
              <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                <TerminalIcon className="mr-2 h-7 w-7" aria-hidden="true" />
                Webhook Subscriptions
              </h2>
              <p className="mt-1 text-sm text-gray-600">
                Here are all your Webhook Subscriptions. Read more on our{" "}
                <a
                  href="https://hilos.io/docs/apidocs"
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-500"
                >
                  API docs
                </a>
                .
              </p>
            </Trans>
          </div>
          <div className="mt-2 flex md:mt-5 lg:mt-0 lg:ml-4">
            <span className="md:ml-3">
              <Link
                to={buildRoute("webhook-create")}
                className="inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-violet-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
              >
                <PlusSmIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                {t(
                  "integrations:webhooks.subscriptions.create",
                  "New Webhook Subscription"
                )}
              </Link>
            </span>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="mx-auto flex flex-col md:mt-6 md:flex-row">
          <Table
            baseURL={buildAPIRoute(API_ROUTES.WEBHOOK_SUBSCRIPTION_LIST_CREATE)}
            paginated={true}
            triggerReload={triggerReload}
            tableHead={
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t("target-url")}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t("active")}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-center text-tiny font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t("events")}
                  </th>
                  <th scope="col" className="px-4 py-3"></th>
                </tr>
              </thead>
            }
            tableRow={(webhook: WebhookSubscription) => (
              <>
                <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-900">
                  <button type="button" className="text-left">
                    <code className="text-indigo-500">{webhook.target}</code>
                    <div className="text-xs text-gray-500">{webhook.id}</div>
                  </button>
                </td>
                <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-900">
                  {webhook.is_active ? (
                    <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-xs font-medium text-green-800">
                      <CheckIcon className="h-4 w-4" aria-hidden="true" />
                    </span>
                  ) : (
                    <span className="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-xs font-medium text-red-800">
                      <XIcon className="h-4 w-4" aria-hidden="true" />
                    </span>
                  )}
                </td>
                <td className="whitespace-nowrap px-4 py-3 text-left text-sm text-gray-500">
                  {webhook.events.map((event) => (
                    <div>
                      <code className="font-mono text-xs font-normal text-indigo-500">
                        {event}
                      </code>
                    </div>
                  ))}
                </td>
                <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-500">
                  <Link
                    className="focus:ring-hilos-500 mr-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100"
                    to={buildRoute("webhook-update", {
                      id: webhook.id,
                    })}
                  >
                    <PencilAltIcon
                      className="-ml-0.5 mr-2 h-4 w-4"
                      aria-hidden="true"
                    />
                    {t("update")}
                  </Link>
                  <DeleteObject
                    obj={webhook}
                    objDescription={webhook.id ?? "this webhook"}
                    actionDescription="When deleting this webhook subscription, you will no longer receive webhooks for these events."
                    onDelete={onDelete}
                    deleteSuccess={deleteSuccess}
                    deleteSubmitted={deleteSubmitted}
                  />
                </td>
              </>
            )}
            dataName={t("webhook-subscriptions", "Webhook subscriptions")}
            noResults={
              <>
                <Trans i18nKey="integrations:webhooks.no-subscriptions">
                  <h4>
                    Looks like you haven't added any webhook subscriptions.
                  </h4>
                  <p className="text-sm text-gray-500">
                    Register a new webhook endpoint to suscribe to specific
                    events and receive them in your systems. You can also read
                    our{" "}
                    <a
                      href="https://hilos.io/docs/apidocs"
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-500"
                    >
                      API docs
                    </a>{" "}
                    to see how to use these webhook endpoints.
                  </p>
                </Trans>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
}
