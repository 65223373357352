import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowSmRightIcon, SupportIcon } from "@heroicons/react/outline";
import { PhoneIcon } from "@heroicons/react/solid";
import { SessionData } from "@hilos/types/hilos";
import { buildRoute } from "src/router/router";
import FlowTemplatesSection from "../FlowTemplatesSection";
import GuidesSection from "../GuidesSection";
import MainDashboardCard from "../MainDashboardCard";

export interface NewDashboardProps {
  session: SessionData;
  QRCode: string | null;
  urlWACode: string;
}

export default function NewDashboard({
  session,
  QRCode,
  urlWACode,
}: NewDashboardProps) {
  const { t } = useTranslation();

  return (
    <div className="h-screen overflow-y-auto w-auto gap-6 overflow-x-clip grid grid-cols-2 bg-gray-50 p-6">
      <div className=" col-span-2 lg:col-span-2 w-full h-20 flex lg:mt-5">
        <div>
          <Trans i18nKey="dashboard:sandbox.main-title">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-neutral-dark  sm:truncate sm:text-3xl">
              Start discovering Hilos!
            </h2>
            <p className="mt-1 text-neutral">
              Take a look at everything Hilos offers to help your company.
            </p>
          </Trans>
        </div>
        <div className="self-center ml-auto shrink-0">
          <Link
            to={buildRoute("config-channel-cloud-api-onboarding")}
            className="z-10 lg:mr-10 inline-flex items-center rounded-md border border-transparent bg-hilos px-4 py-3 text-sm font-semibold text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
          >
            <FontAwesomeIcon
              // @ts-ignore
              icon={faWhatsapp}
              aria-hidden="true"
              className="mr-2 h-5 w-5"
            />
            {t("dashboard:sandbox.connect-number", "Connect your number")}
            <ArrowSmRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />
          </Link>
        </div>
      </div>
      <div className=" col-span-2 lg:col-span-2 mb-6 w-full">
        <MainDashboardCard
          urlWhatsAppCode={urlWACode}
          QRSandboxCode={QRCode}
          session={session}
        />
      </div>
      <div className=" col-span-2 lg:col-span-1 w-full">
        <GuidesSection />
      </div>
      <div className=" col-span-2 lg:col-span-1 w-full">
        <FlowTemplatesSection />
      </div>
      <div className=" col-span-2 lg:col-span-1  w-full">
        <div className="bg-white rounded-md flex items-center p-6">
          <div className=" rounded-full h-14 flex items-center justify-center w-14 bg-hilos-light p-3">
            <PhoneIcon className="h-6 w-6 text-hilos-alt-700" />
          </div>
          <div className="ml-6 w-full">
            <p className="font-semibold">
              {t(
                "dashboard:sandbox.call.title",
                "Do you have any questions about how Hilos can help you?"
              )}
            </p>
            <p className=" text-xs text-gray-500">
              {t(
                "dashboard:sandbox.call.subtitle",
                "Schedule a call with us, we'll be happy to assist you."
              )}
            </p>
            <div className="flex mt-4 w-full justify-end">
              <a
                href="https://hilos.io/docs/guides/new-agent/inbox/empezar-conversacion"
                target="_blank"
                rel="noreferrer"
                className="flex border border-hilos text-hilos-alt-700 px-6 py-2 rounded-md items-center ml-auto text-xs font-medium"
              >
                {t("dashboard:support.button")}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className=" col-span-2 lg:col-span-1 bg-slate-200 w-full h-32">
        <div className="bg-white rounded-md flex items-center p-6">
          <div className=" rounded-full h-14 flex items-center justify-center w-14 bg-hilos-light p-3">
            <SupportIcon className="h-6 w-6 text-hilos-alt-700" />
          </div>
          <div className="ml-6 w-full">
            <p className="font-semibold">
              {t(
                "dashboard:sandbox.call.title",
                "Have any questions about the documentation?"
              )}
            </p>
            <p className=" text-xs text-gray-500">
              {t(
                "dashboard:sandbox.call.subtitle",
                "Contact us via WhatsApp, our support team is here to assist you."
              )}
            </p>
            <div className="flex mt-4 w-full justify-end">
              <a
                href="https://hilos.io/docs/guides/new-agent/inbox/empezar-conversacion"
                target="_blank"
                rel="noreferrer"
                className="flex border border-hilos text-hilos-alt-700 px-6 py-2 rounded-md items-center ml-auto text-xs font-medium"
              >
                {t("dashboard:send-whatsapp")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
