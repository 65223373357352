import { useTranslation } from "react-i18next";
import { FlowStepEdit } from "@hilos/types/private-schema";
import useWhatsAppFlow from "src/hooks/useWhatsAppFlow";

interface FlowStepWhatsAppFlowDetailProps {
  step: FlowStepEdit;
}

export default function FlowStepWhatsAppFlowDetail({
  step,
}: FlowStepWhatsAppFlowDetailProps) {
  const { t } = useTranslation();
  const waFlow = useWhatsAppFlow(step.wa_flow as string);

  if (!waFlow) {
    return null;
  }

  return (
    <div>
      <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
        {t(
          "flow-executions:steps-detail.whatsapp-flow.wa-flow-name",
          "WhatsApp Flow to trigger"
        )}
      </h3>

      <p className="text-blue-500 font-medium">{waFlow?.name}</p>
    </div>
  );
}
