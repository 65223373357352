import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  AdjustmentsIcon,
  ChartPieIcon,
  ChevronRightIcon,
  ClipboardListIcon,
  ClockIcon,
  CogIcon,
  InboxIcon,
  LightningBoltIcon,
  SpeakerphoneIcon,
  TagIcon,
  UserGroupIcon,
  UserIcon,
  ViewListIcon,
} from "@heroicons/react/outline";
import AccountStatusNotice from "src/components/Notice/AccountStatusNotice";
import PermissionsChecker from "src/components/PermissionsCheck";
import WhatsAppIcon from "src/components/icons/WhatsAppIcon";
import logo from "src/assets/img/logo512.png";
import HeaderNavigation from "../../components/HeaderNavigation";
import useHilosStore from "../../hooks/useHilosStore";
import { buildRoute } from "../../router/router";

const MenuItem = ({ title, items }) => {
  const { t } = useTranslation();

  const getMenuItem = (item) => {
    return (
      <div className="flex items-center px-4 py-4 sm:px-6">
        <div className="flex min-w-0 flex-1 grow items-center">
          <div className="flex-shrink-0">{item.icon}</div>
          <div className="min-w-0 flex-1 px-4">
            <div>
              <p className="text-sm font-medium text-indigo-600">
                {t(item.label)}
              </p>
              <p className="mt-1 flex items-center text-xs text-gray-500">
                <span className="truncate">{t(item.help)}</span>
              </p>
            </div>
          </div>
        </div>
        <div>
          <ChevronRightIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
      </div>
    );
  };
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        <li key={title} className="px-4 py-4 sm:px-6">
          {t(title)}
        </li>
        {items
          .filter((item) => item.show)
          .map((item) => (
            <li key={item.label}>
              {item.external ? (
                <a
                  href={item.url}
                  className="block hover:bg-gray-50"
                  target="_blank"
                  rel="noreferrer"
                >
                  {getMenuItem(item)}
                </a>
              ) : (
                <Link to={item.url} className="block hover:bg-gray-50">
                  {getMenuItem(item)}
                </Link>
              )}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default function AccountConfigMenu() {
  const { t } = useTranslation();
  const { session } = useHilosStore();

  const ACCOUNT_MENU_ITEMS = [
    {
      label: t("settings:basic-config.label", "Basic config"),
      icon: <CogIcon className="h-5 w-5 text-gray-500" />,
      url: buildRoute("config-account-basic"),
      help: t(
        "settings:basic-config.help",
        "Change your account name or default timezone."
      ),
      external: false,
      show: true,
    },
    {
      label: t("settings:whatsapp-accounts.label", "WhatsApp accounts"),
      icon: <WhatsAppIcon className="h-5 w-5 text-gray-500" />,
      url: buildRoute("config-channel"),
      help: t(
        "settings:whatsapp-accounts.help",
        "Connect one or more WhatsApp numbers to Hilos."
      ),
      external: false,
      show: true,
    },
    {
      label: t("settings:teams.label", "Teams"),
      url: buildRoute("config-team-list"),
      icon: <UserGroupIcon className="h-5 w-5 text-gray-500" />,
      help: t(
        "settings:teams.help",
        "Organize your users into teams with custom assignment rules."
      ),
      external: false,
      show: true,
    },
    {
      label: t("settings:users.label", "Users & Invites"),
      url: buildRoute("account-members"),
      icon: <UserIcon className="h-5 w-5 text-gray-500" />,
      help: t(
        "settings:users.help",
        "Invite your teammates to your Hilos account and set permissions."
      ),
      external: false,
      show: true,
    },
    {
      label: t("settings:roles.label", "Roles & Permissions"),
      url: buildRoute("role-list"),
      icon: <AdjustmentsIcon className="h-5 w-5 text-gray-500" />,
      help: t("settings:roles.help", "Add roles or change their permissions."),
      external: false,
      show: session?.account.pricing_model_version === "v2",
    },
    {
      label: t("settings:working-hours.label", "Working hours"),
      icon: <ClockIcon className="h-5 w-5 text-gray-500" />,
      url: buildRoute("config-account-hours"),
      help: t(
        "settings:working-hours.help",
        "Set working hours so your customers know when you're unavailable."
      ),
      external: false,
      show: true,
    },
    {
      label: t("settings:notifications.label", "Notification settings"),
      icon: <SpeakerphoneIcon className="h-5 w-5 text-gray-500" />,
      url: buildRoute("config-notifications"),
      help: t(
        "settings:notifications.help",
        "Get notified when events happen in Hilos."
      ),
      external: false,
      show: true,
    },
    {
      label: t("settings:template.label", "Template settings"),
      icon: <ClipboardListIcon className="h-5 w-5 text-gray-500" />,
      url: buildRoute("config-templates"),
      help: t(
        "settings:template.help",
        "Hide sample templates from your template list"
      ),
      external: false,
      show: true,
    },
  ];

  const INBOX_MENU_ITEMS = [
    {
      label: t("settings:inbox.label", "Inbox config"),
      icon: <InboxIcon className="h-5 w-5 text-gray-500" />,
      url: buildRoute("config-account-inbox"),
      help: t("settings:inbox.help", "Everything related to Hilos' inbox."),
      external: false,
      show: true,
    },
    {
      label: t("settings:conversation-list.label", "Conversation list"),
      icon: <ViewListIcon className="h-5 w-5 text-gray-500" />,
      url: buildRoute("config-inbox-conversation-list"),
      help: t(
        "settings:conversation-list.help",
        "Set the info that you want to see in the conversation list."
      ),
      external: false,
      show: true,
    },
    {
      label: t("settings:inbox-actions.label", "Inbox actions"),
      icon: <LightningBoltIcon className="h-5 w-5 text-gray-500" />,
      url: buildRoute("config-inbox-action-list"),
      help: t(
        "settings:inbox-actions.help",
        "Configure available inbox actions."
      ),
      external: false,
      show: true,
    },
    {
      label: t("settings:contact-tags.label", "Contact tags"),
      icon: <TagIcon className="h-5 w-5 text-gray-500" />,
      url: buildRoute("config-inbox-contact-tag-list"),
      help: t(
        "settings:contact-tags.help",
        "View and edit the available contact tags."
      ),
      external: false,
      show: true,
    },
    {
      label: t("settings:conversation-tags.label", "Conversation tags"),
      icon: <TagIcon className="h-5 w-5 text-gray-500" />,
      url: buildRoute("config-inbox-conversation-tag-list"),
      help: t(
        "settings:conversation-tags.help",
        "View and edit the available conversation tags."
      ),
      external: false,
      show: true,
    },
  ];

  const BILLING_MENU_ITEMS = [
    {
      label: t("settings:wallet.label", "WhatsApp Wallet"),
      icon: <WhatsAppIcon className="h-5 w-5 text-green-500" />,
      url: buildRoute("config-account-wallet"),
      help: t(
        "settings:wallet.help",
        "Your wallet for paying for WhatsApp conversation fees."
      ),
      external: false,
      show: false,
    },
    {
      label: t("settings:subscription.label", "My Hilos subscription"),
      icon: <img src={logo} className="h-5 w-5" alt="Hilos logo" />,
      url: buildRoute("config-account-subscription"),
      help: t("settings:subscription.help", "Check your current subscription."),
      external: false,
      show: true,
    },
    {
      label: t("settings:payment-history.label", "Past invoices"),
      icon: <ClipboardListIcon className="h-5 w-5 text-gray-500" />,
      url: buildRoute("config-payment-history"),
      help: t("settings:payment-history.help", "See all your past invoices."),
      external: false,
      show: true,
    },
    {
      label: t("settings:usage-report.label", "This month's usage"),
      icon: <ChartPieIcon className="h-5 w-5 text-gray-500" />,
      url: buildRoute("config-usage-report"),
      help: t("settings:usage-report.help", "Check your Hilos usage."),
      external: false,
      show: true,
    },
    {
      label: "Facturación electrónica (México solamente)",
      icon: <span className="h-5 w-5 text-gray-500">🇲🇽</span>,
      url: "https://portal.gigstack.pro/hilos",
      help: "Configura tus datos para facturación electrónica.",
      external: true,
      show: session?.account.payment_currency === "mxn",
    },
  ];

  return (
    <div className="h-screen overflow-y-auto bg-gray-100 pb-4">
      <AccountStatusNotice />
      {/* Header */}
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("settings"),
              url: buildRoute("dashboard"),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <CogIcon className="mr-4 h-7 w-7 rotate-90" aria-hidden="true" />
              {session &&
                t("settings:account-config.title", "{{user}}'s settings", {
                  user: session.account.name,
                })}
            </h2>
          </div>
        </div>
      </div>

      <PermissionsChecker
        permission="change_account"
        action={t("settings:permissions.change_account", "update the team")}
      >
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto mt-6 max-w-5xl">
            <div className="grid items-start gap-6 sm:grid-cols-2">
              <MenuItem
                title={t("settings:account-config.title", "Account config")}
                items={ACCOUNT_MENU_ITEMS}
              />
              <MenuItem
                title={t("settings:inbox-config.label", "Inbox config")}
                items={INBOX_MENU_ITEMS}
              />
              {!session?.account.feature_flags.includes(
                "hide-subscription"
              ) && (
                <MenuItem
                  title={t("settings:billing-config.label", "Billing config")}
                  items={BILLING_MENU_ITEMS}
                />
              )}
            </div>
          </div>
        </div>
      </PermissionsChecker>
    </div>
  );
}
