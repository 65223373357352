import { Fragment, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import { ArrowSmRightIcon } from "@heroicons/react/outline";
import axios from "axios";
import { Formik } from "formik";
import StateButton from "src/components/StateButton";
import APIErrors from "src/components/base/APIErrors";
import useCleanTimeout from "src/hooks/useCleanTimeout";
import useHilosStore from "src/hooks/useHilosStore";
import { useStripeSubscription } from "src/hooks/useStripe";
import { capitalize } from "src/Helpers";
import { API_ROUTES, buildRoute } from "src/router/router";
import { axiosErr } from "src/types/axios";

interface SubscriptionV2ChangePlanModalProps {
  selectedPlan: string;
  handleHide: () => void;
}

export default function SubscriptionV2ChangePlanModal({
  selectedPlan,
  handleHide,
}: SubscriptionV2ChangePlanModalProps) {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});
  const timeout = useCleanTimeout();
  const cancelButtonRef = useRef(null);
  const { refetch } = useStripeSubscription();
  const { reloadSession } = useHilosStore();
  const navigate = useNavigate();

  const FIELDS = {
    plan: {
      key: "plan",
      label: t("settings:billing.change-plan.plan.label", "Selected plan"),
    },
  };

  const initialValues = {
    plan: selectedPlan,
  };

  const onSubmit = async (formData, setSubmitting) => {
    setBackendValidationErrors({});
    setBackendError("");
    setSubmitting(true);

    try {
      const response = await axios.post(
        API_ROUTES.STRIPE_SUBSCRIPTION_PLAN_UPDATE,
        formData
      );
      console.log(response);
      setSubmitting(false);
      setSuccess(true);
      refetch();
      reloadSession();
      navigate(buildRoute("config-account-subscription"));
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;
      setSuccess(false);
      if (errorAxios?.response?.status === 400) {
        console.log("error", errorAxios);
        setBackendValidationErrors(errorAxios.response.data);
      } else {
        setBackendError(
          t(
            "settings:billing.error-stripe",
            "Couldn't start a Stripe secure session, please try again later."
          )
        );
      }
      timeout.current = setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Transition.Root show={selectedPlan !== null} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={handleHide}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block transform rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
                <div className="flex text-gray-800 items-center font-bold">
                  <div className="h-10 w-10 rounded-full flex items-center justify-center text-hilos mr-4 bg-red-100">
                    <CheckIcon className="h-6 w-6" />
                  </div>
                  <div>
                    <div className="text-lg">
                      {t(
                        "settings:billing.v2.change-plan.title",
                        "Awesome! Let's go with the {{selectedPlan}} plan.",
                        { selectedPlan: capitalize(selectedPlan) }
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <Formik
                    onSubmit={(values, { setSubmitting }) =>
                      onSubmit(values, setSubmitting)
                    }
                    initialValues={initialValues}
                  >
                    {(formik) => (
                      <form
                        noValidate
                        onSubmit={formik.handleSubmit}
                        className="space-y-8"
                      >
                        <APIErrors
                          APIError={backendError}
                          APIValidationErrors={backendValidationErrors}
                          fieldTranslations={FIELDS}
                        />

                        <div className="text-sm text-gray-800 font-normal">
                          {selectedPlan === "SCALE"
                            ? t(
                                "settings:billing.v2.change-plan-upgrade.description",
                                "We'll adjust your subscription right now, which might result in a credit being applied to your next invoice."
                              )
                            : t(
                                "settings:billing.v2.change-plan-downgrade.description",
                                "We'll adjust your subscription right now, which might result in charging your account for the extras that won't be included in your plan."
                              )}
                        </div>

                        <div className="sm:flex sm:flex-row-reverse">
                          <StateButton
                            isSubmitting={formik.isSubmitting}
                            submitted={submitted}
                            success={success}
                            disabled={formik.isSubmitting}
                            submittingText={t("processing")}
                            successText={t("success", "Success!")}
                            btnClasses="inline-flex w-full justify-center rounded-md bg-gradient-to-r from-hilos to-violet-400 text-white hover:bg-hilos px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-500 sm:ml-3 sm:w-auto"
                            btnClassesSuccess="w-full inline-flex items-center justify-center rounded-md border border-transparent bg-green-100 px-5 py-3 text-base font-medium text-green-800 shadow-sm hover:bg-green-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                            btnClassesError="w-full inline-flex items-center justify-center rounded-md border border-transparent bg-yellow-100 px-5 py-3 text-base font-medium text-yellow-800 shadow-sm hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2"
                            initialText={
                              <>
                                {selectedPlan === "SCALE"
                                  ? t(
                                      "settings:billing.upgrade-plan-button",
                                      "Upgrade your plan"
                                    )
                                  : t(
                                      "settings:billing.downgrade-plan-button",
                                      "Downgrade your plan"
                                    )}
                                <ArrowSmRightIcon
                                  className="ml-1 h-5 w-5"
                                  aria-hidden="true"
                                />
                              </>
                            }
                          />

                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            onClick={handleHide}
                            ref={cancelButtonRef}
                          >
                            {t("cancel", "Cancel")}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
