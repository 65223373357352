import { Trans } from "react-i18next";
import * as yup from "yup";
import { ActiveCampaignAppConfig } from "@hilos/types/private-schema";
import { getTranslationPayload as t } from "src/i18n";

export const getInitialValues = (): ActiveCampaignAppConfig => {
  return {
    id: "",
    base_url: "",
    api_key: "",
    api_key_ro: "",
  };
};

export const FIELDS = {
  base_url: {
    key: "base_url",
    label: t(
      "integrations:activecampaign.settings.base_url.label",
      "API Base URL"
    ),
    optional: false,
  },
  api_key: {
    key: "api_key",
    label: t("integrations:activecampaign.settings.api-key.label", "API Key"),
    optional: false,
    help: (
      <Trans i18nKey="integrations:activecampaign.settings.api-key.help">
        In your{" "}
        <a
          href="https://activecampaign.com"
          target="_blank"
          rel="noreferrer"
          className="text-indigo-500"
        >
          ActiveCampaign dashboard
        </a>
        , go to Settings {">"} Developer to get this information.
      </Trans>
    ),
  },
  api_key_ro: {
    key: "api_key_ro",
    label: t("integrations:chatgpt.settings.api-key-ro", "API Key"),
    optional: false,
  },
};

export const schema = yup.object().shape({
  id: yup.string(),
});
