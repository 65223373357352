import {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import { ArrowSmRightIcon, CodeIcon } from "@heroicons/react/outline";
import { Formik } from "formik";
import SwitchField from "src/components/Form/SwitchField";
import TextInputField from "src/components/Form/TextInputField";
import StateButton from "src/components/StateButton";
import APIErrors from "src/components/base/APIErrors";
import useHilosStore from "src/hooks/useHilosStore";
import * as meta from "./Meta";

export type WebhookFormSubmit = (
  formData: meta.WebhookSubscriptionFormValues,
  setSubmitting: Dispatch<SetStateAction<boolean>>,
  setBackendValidationErrors: Dispatch<SetStateAction<{}>>,
  setBackendError: Dispatch<SetStateAction<string>>
) => void;

export interface WebhookFormProps {
  webhook: meta.WebhookSubscriptionFormValues;
  formSubmit: WebhookFormSubmit;
  success: boolean;
  submitted: boolean;
  action: "create" | "update";
}

export default function WebhookForm({
  webhook,
  formSubmit,
  success,
  submitted,
  action,
}: WebhookFormProps) {
  const { t } = useTranslation();
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});
  const { session } = useHilosStore();

  const onSubmit = (
    values: meta.WebhookSubscriptionFormValues,
    setSubmitting: (boolean) => void
  ) => {
    // Keep the selected events with their proper name with dots
    const transformedEvents = Object.getOwnPropertyNames(values.events)
      .filter((eventName) => values.events[eventName])
      .map((eventName) => meta.WEBHOOK_EVENTS_TRANSLATION[eventName]);
    const formData = { ...values, ...{ events: transformedEvents } };
    setSubmitting(false);
    formSubmit(
      formData,
      setSubmitting,
      setBackendValidationErrors,
      setBackendError
    );
  };

  const getEventsByCategory = useCallback((category) => {
    return Object.getOwnPropertyNames(meta.WEBHOOK_EVENTS).filter(
      (perm) => meta.WEBHOOK_EVENTS[perm].category === category
    );
  }, []);

  return (
    <Formik
      validationSchema={meta.schema}
      onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
      enableReinitialize={true}
      initialValues={webhook}
    >
      {(formik) => (
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="my-4">
            <APIErrors
              APIError={backendError}
              APIValidationErrors={backendValidationErrors}
              fieldTranslations={meta.FIELDS}
            />
          </div>

          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <Trans i18nKey="integrations:webhooks.subscription.description">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Webhook Endpoint
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    Configure your webhook subscription
                  </p>
                </Trans>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="shadow sm:overflow-hidden sm:rounded-md">
                <div className="bg-white px-4 py-5 sm:p-6">
                  <div className="space-y-4 ">
                    <TextInputField
                      placeholder={t(meta.FIELDS.target.placeholder)}
                      label={t(meta.FIELDS.target.label)}
                      name={meta.FIELDS.target.key}
                      help={t(meta.FIELDS.target.help)}
                      type="url"
                      icon={CodeIcon}
                    />

                    <SwitchField
                      label={t(meta.FIELDS.is_active.label)}
                      name={meta.FIELDS.is_active.key}
                      optional={meta.FIELDS.is_active.optional}
                      help={t(meta.FIELDS.is_active.help)}
                    />
                  </div>

                  <p className="text-lg font-medium text-gray-700 mt-6">
                    {t(meta.FIELDS.events.label)}
                  </p>
                  <p className="text-sm font-light text-gray-600">
                    {t(meta.FIELDS.events.help)}
                  </p>

                  <div className="space-y-4 mt-4">
                    {Object.getOwnPropertyNames(meta.WEBHOOK_CATEGORIES).map(
                      (category) => (
                        <div key={category}>
                          <h4 className="flex items-center text-tiny font-medium uppercase tracking-wider text-gray-500">
                            {meta.WEBHOOK_CATEGORIES[category].icon}
                            {t(meta.WEBHOOK_CATEGORIES[category].label)}
                          </h4>
                          <div className="space-y-2 mt-2">
                            {getEventsByCategory(category)
                              .filter((event) =>
                                meta.WEBHOOK_EVENTS[event].feature_flag
                                  ? session?.account.feature_flags.includes(
                                      meta.WEBHOOK_EVENTS[event].feature_flag
                                    )
                                  : true
                              )
                              .map((event) => (
                                <Fragment key={event}>
                                  <SwitchField
                                    name={`events.${meta.WEBHOOK_EVENTS[event].value}`}
                                    label={t(meta.WEBHOOK_EVENTS[event].label)}
                                    help={t(
                                      meta.WEBHOOK_EVENTS[event].description
                                    )}
                                  />
                                </Fragment>
                              ))}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <div className="text-right">
              <StateButton
                isSubmitting={formik.isSubmitting}
                submitted={submitted}
                success={success}
                btnClasses={`inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-violet-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2 ${
                  formik.errors &&
                  Object.getOwnPropertyNames(formik.errors).length > 0
                    ? "disabled:opacity-50 cursor-not-allowed"
                    : ""
                }`}
                disabled={
                  formik.errors &&
                  Object.getOwnPropertyNames(formik.errors).length > 0
                }
                submittingText={
                  action === "create" ? t("creating") : t("updating")
                }
                successText={action === "create" ? t("created") : t("updated")}
                initialText={
                  <>
                    {action === "create" ? t("create") : t("update")}
                    <ArrowSmRightIcon
                      className="ml-1 h-5 w-5"
                      aria-hidden="true"
                    />
                  </>
                }
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}
