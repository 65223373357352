import { Trans, useTranslation } from "react-i18next";
import { MailIcon, PlusSmIcon, XIcon } from "@heroicons/react/outline";
import axios from "axios";
import { DateTime } from "luxon";
import { UserInvitation } from "@hilos/types/private-schema";
import UserAvatar from "src/containers/inbox/UserAvatar";
import CopyToClipboardButton from "src/components/Form/CopyToClipboardButton";
import Table from "src/components/Table/Table";
import useHilosStore from "src/hooks/useHilosStore";
import { userToString } from "../../../Helpers";
import { API_ROUTES, buildAPIRoute } from "../../../router/router";

export default function UserInvitationList({
  setOpen,
  reloadInvitations,
  reloadToggle,
}) {
  const { t } = useTranslation();
  const { session } = useHilosStore();

  const cancelInvitation = async (invitation) => {
    try {
      await axios.patch(
        buildAPIRoute(API_ROUTES.USER_INVITATION_DETAIL, {
          ":id": invitation.id,
        }),
        { status: "CANCELED" }
      );
      reloadInvitations();
      console.log("reloaded");
    } catch (err) {
      console.log(err);
    }
  };

  const resendInvitation = async (invitation) => {
    try {
      await axios.patch(
        buildAPIRoute(API_ROUTES.USER_INVITATION_DETAIL, {
          ":id": invitation.id,
        }),
        { status: "PENDING" }
      );
      reloadInvitations();
      console.log("reloaded");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="h-full bg-gray-50 sm:px-6 sm:pt-8 lg:px-8">
      <Table<UserInvitation>
        baseURL={buildAPIRoute(API_ROUTES.USER_INVITATION_LIST_CREATE_LEGACY)}
        initialSearchParams={{
          status__in: "PENDING,SENT",
        }}
        paginated={false}
        tableHead={
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              >
                {t("email")}
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              >
                {t("settings:team.invited-by", "Invited by")}
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              >
                {t("settings:team.invited-on", "Invited on")}
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              >
                {t("status")}
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              ></th>
            </tr>
          </thead>
        }
        tableRow={(invitation) => (
          <>
            <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-900">
              {invitation.email}
            </td>
            <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
              <div className="flex items-center">
                <UserAvatar
                  data={invitation.invited_by}
                  className="h-6 w-6 text-xs"
                />
                <div className="ml-2">
                  {invitation.invited_by.first_name ||
                  invitation.invited_by.last_name ? (
                    <>
                      <div className="text-sm font-medium text-gray-900">
                        {userToString(invitation.invited_by)}
                      </div>
                      <div className="text-sm text-gray-500">
                        {invitation.invited_by.email}
                      </div>
                    </>
                  ) : (
                    <>{invitation.invited_by.email}</>
                  )}
                </div>
              </div>
            </td>
            <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
              <time className="text-sm text-gray-500" dateTime="2020-03-22">
                {DateTime.fromISO(
                  invitation.created_on as string
                ).toLocaleString(DateTime.DATETIME_SHORT)}
              </time>
            </td>
            <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
              {invitation.status === "PENDING" && (
                <span className="inline-flex rounded-full bg-gray-100 px-2 text-xs font-semibold uppercase leading-5 text-gray-800">
                  {t("pending")}
                </span>
              )}
              {invitation.status === "SENT" && (
                <span className="inline-flex rounded-full bg-indigo-100 px-2 text-xs font-semibold uppercase leading-5 text-indigo-800">
                  {t("sent")}
                </span>
              )}
              {invitation.status === "ACCEPTED" && (
                <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold uppercase leading-5 text-green-800">
                  {t("accepted")}
                </span>
              )}
              {invitation.status === "EXPIRED" && (
                <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold uppercase leading-5 text-yellow-800">
                  {t("expired")}
                </span>
              )}
              {invitation.status === "CANCELED" && (
                <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold uppercase leading-5 text-red-800">
                  {t("canceled")}
                </span>
              )}
            </td>

            <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
              {["EXPIRED", "CANCELED"].includes(
                invitation.status as string
              ) && (
                <button
                  type="button"
                  className="flex w-full items-center justify-center rounded-md border border-indigo-300 bg-white px-3 py-2 text-sm font-medium text-indigo-700 shadow-sm hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-indigo-100"
                  onClick={(_) => resendInvitation(invitation)}
                >
                  <MailIcon className="-ml-1 mr-2 h-4 w-4" aria-hidden="true" />
                  {t("resend")}
                </button>
              )}
              {["PENDING", "SENT"].includes(invitation.status as string) && (
                <div className="flex items-center gap-2">
                  <CopyToClipboardButton
                    text={t("Copy invitation URL")}
                    value={
                      window.location.host + "/invitation/" + invitation.id
                    }
                  />
                  <button
                    type="button"
                    className="flex items-center text-sm font-medium text-red-700 ml-2"
                    onClick={(_) => cancelInvitation(invitation)}
                  >
                    <XIcon className="-ml-1 mr-1 h-4 w-4" aria-hidden="true" />
                    {t("cancel")}
                  </button>
                </div>
              )}
            </td>
          </>
        )}
        dataName={t("invitations", "Invitations")}
        noResults={
          <>
            <Trans i18nKey="settings:team.no-results-with-filters">
              <h4>Couldn't find any invitation with these filters.</h4>
              <p className="text-muted">Remove filters to try again.</p>
            </Trans>
          </>
        }
        createObject={
          session?.account.pricing_model_version === "v1" ? (
            <div>
              <Trans i18nKey="settings:team.no-invites-yet">
                <h4>Looks like you haven't invited any team members yet.</h4>
                <p className="text-sm text-gray-500">
                  Why don't you invite one now?
                </p>
              </Trans>
              <button
                type="button"
                className="inline-flex mt-4 items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-violet-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
                onClick={(_) => setOpen(true)}
              >
                <PlusSmIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                {t("settings:team.invite-team-members.label")}
              </button>
            </div>
          ) : (
            <div className="text-gray-500">
              <h4>
                {t(
                  "settings:team.no-pending-invites-yet",
                  "Looks like you don't have any pending invitations yet."
                )}
              </h4>
            </div>
          )
        }
        triggerReload={reloadToggle}
      />
    </div>
  );
}
