import { useTranslation } from "react-i18next";
import FlowTag from "src/components/FlowTag";
import SelectorField from "src/components/Form/SelectorField";
import SwitchField from "src/components/Form/SwitchField";
import {
  contactTagsColorClasses,
  conversationTagsColorClasses,
} from "src/components/Tags";
import useAccount from "src/hooks/useAccount";
import { useContactTags, useConversationTags } from "src/hooks/useTags";
import { userToString } from "src/Helpers";
import FormatOptionLabelUser from "../../FormatOptionLabelUser";

interface FlowBuilderStepConversationProps {
  index: number;
}

function FlowBuilderStepConversation({
  index,
}: FlowBuilderStepConversationProps) {
  const { t } = useTranslation();
  const { members } = useAccount();
  const { tags: availableContactTags } = useContactTags();
  const { tags: availableConversationTags } = useConversationTags();

  return (
    <div className="space-y-4">
      <SelectorField
        isMulti
        isCreatable={false}
        name={`steps.${index}.assign_to_users`}
        label={t(
          "flows:steps.conversation.assign-to-users.label",
          "User(s) to assign this conversation to"
        )}
        help={t(
          "flows:steps.conversation.assign-to-users.help",
          "We'll auto assign the contact to this user and mark the conversation as both IN PROGRESS and unread, so they'll be notified."
        )}
        formatOptionLabel={FormatOptionLabelUser}
        options={members.map((user) => ({
          label: userToString(user),
          value: user.id,
          user: user,
        }))}
      />

      <SwitchField
        name={`steps.${index}.append_tags`}
        label={t("flows:steps.conversation.append-tags.label", "Append tags?")}
        help={t(
          "flows:steps.conversation.append-tags.help",
          "If set these tags will be appended instead of replacing existing ones."
        )}
      />
      <FlowTag
        name={`steps.${index}.conversation_tags`}
        title={t(
          "flows:steps.conversation.conversation-tags.description",
          "Add conversation tags"
        )}
        availableTags={availableConversationTags}
        {...conversationTagsColorClasses}
      />
      <FlowTag
        name={`steps.${index}.contact_tags`}
        title={t(
          "flows:steps.conversation.contact-tags.description",
          "Add contact tags"
        )}
        availableTags={availableContactTags}
        {...contactTagsColorClasses}
      />
    </div>
  );
}

export default FlowBuilderStepConversation;
