import AlertIconWithIndicator from "src/components/AlertIconWithIndicator";
import { SidebarTrigger } from "src/components/ui/sidebar";
import logo from "../assets/img/hilos_logo.png";

function MobileMenu({ onSwitchShowNotifications }) {
  return (
    <div className="md:hidden">
      <div className="flex items-center justify-between border-b border-gray-200 bg-gray-50 px-4 py-1.5">
        <div>
          <img className="h-8 w-auto" src={logo} alt="Hilos" />
        </div>
        <div>
          <button
            type="button"
            className="inline-flex text-gray-700 h-12 w-12 items-center justify-center rounded-md hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink-600"
            onClick={onSwitchShowNotifications}
          >
            <AlertIconWithIndicator />
          </button>
          <SidebarTrigger className="rotate-180" />
        </div>
      </div>
    </div>
  );
}

export default MobileMenu;
