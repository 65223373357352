import { useTranslation } from "react-i18next";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { ArrowDownNarrowWide } from "lucide-react";
import { Button } from "src/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from "src/components/ui/dropdown-menu";

interface WorkflowBoardSortProps {
  sortBy: string;
  onUpdateSortBy?: (sortBy: string) => void;
}

export function WorkflowBoardSort({
  sortBy,
  onUpdateSortBy,
}: WorkflowBoardSortProps) {
  const [t] = useTranslation();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="sm" className="h-8 flex self-end">
          <ArrowDownNarrowWide />
          {t("sort-by", "Sort by")}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        <DropdownMenuRadioGroup value={sortBy} onValueChange={onUpdateSortBy}>
          <DropdownMenuRadioItem value="created_on">
            {t("workflows:created", "Created")}
          </DropdownMenuRadioItem>
          <DropdownMenuRadioItem value="last_message_on">
            {t("workflows:last-message", "Last message")}
          </DropdownMenuRadioItem>
          <DropdownMenuRadioItem value="last_updated_on">
            {t("workflows:last-update", "Last update")}
          </DropdownMenuRadioItem>
          <DropdownMenuRadioItem value="last_state_updated_on">
            {t("workflows:last-state-update", "Last state update")}
          </DropdownMenuRadioItem>
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
