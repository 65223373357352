import { useTranslation } from "react-i18next";
import HeaderNavigation from "src/components/HeaderNavigation";
import Loading from "src/components/Loading";
import useActiveCampaignAppConfig, {
  useActiveCampaignAppConfigUpdate,
} from "src/hooks/useActiveCampaignAppConfig";
import logo from "src/assets/img/integrations/AC_Glyph_Blue.svg";
import { buildRoute } from "src/router/router";
import ActiveCampaignAppConfigForm from "./ActiveCampaignAppConfigForm";

export default function ActiveCampaignAppConfig() {
  const { t } = useTranslation();
  const { data: openAISettings, isLoading: openAILoading } =
    useActiveCampaignAppConfig();
  const { handleUpdateActiveCampaignAppConfig } =
    useActiveCampaignAppConfigUpdate();

  return (
    <div className="h-screen overflow-y-auto bg-gray-50">
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("home"),
              url: buildRoute("dashboard"),
            },
            {
              name: t(
                "integrations:external-apps.header-navigation-title",
                "External apps"
              ),
              url: buildRoute("external-apps"),
            },
            {
              name: t(
                "integrations:activecampaign.title",
                "ActiveCampaign Settings"
              ),
              url: buildRoute("integrations-activecampaign"),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <img src={logo} className="h-9 w-9" alt="ActiveCampaign logo" />
              {t(
                "integrations:activecampaign.title",
                "ActiveCampaign Settings"
              )}
            </h2>
          </div>
        </div>
      </div>

      <div className="mx-auto mt-6 max-w-7xl px-4 sm:px-6 lg:px-8">
        <div>
          {openAILoading || openAISettings == null ? (
            <Loading />
          ) : (
            <ActiveCampaignAppConfigForm
              currentSettings={openAISettings}
              onUpdateActiveCampaignAppConfig={
                handleUpdateActiveCampaignAppConfig
              }
              disabled={false}
            />
          )}
        </div>
      </div>
    </div>
  );
}
