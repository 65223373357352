import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PlusSmIcon } from "@heroicons/react/outline";
import axios from "axios";
import { AccountStatus } from "src/types/account";
import StateButton from "../../components/StateButton";
import { API_ROUTES, buildAPIRoute, buildRoute } from "../../router/router";

function WhatsAppTemplateCreateButton({ accountStatus }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [actionSuccess, setActionSuccess] = useState(false);
  const [actionSubmitted, setActionSubmitted] = useState(false);
  const [actionSubmitting, setActionSubmitting] = useState(false);

  const handleCreate = async () => {
    setActionSubmitting(true);
    try {
      const response = await axios.post(
        buildAPIRoute(API_ROUTES.WHATSAPP_TEMPLATE_CREATE)
      );
      setActionSuccess(true);
      navigate(
        buildRoute("whatsapp-template", {
          id: response.data.id,
        })
      );
    } catch (err) {
      console.log("error", err);
    } finally {
      setActionSubmitted(false);
      setActionSubmitting(false);
    }
  };

  return (
    <StateButton
      isSubmitting={actionSubmitting}
      submitted={actionSubmitted}
      success={actionSuccess}
      submittingText={t("creating", "Creating...")}
      successText={t("redirecting", "Redirecting...")}
      initialText={
        <>
          <PlusSmIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          {accountStatus === AccountStatus.NEW ||
          accountStatus === AccountStatus.IN_SANDBOX ? (
            <>{t("templates:try-template-builder", "Try Template Builder")}</>
          ) : (
            <>{t("templates:create-template", "Create Template")}</>
          )}
        </>
      }
      btnClasses="inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-violet-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
      btnClassesSuccess="inline-flex items-center rounded-md border border-transparent bg-green-100 px-4 py-3 text-sm font-medium leading-4 text-green-800 text-white shadow-sm hover:bg-green-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
      btnClassesError="inline-flex items-center rounded-md border border-transparent bg-yellow-50 px-4 py-3 text-sm font-medium leading-4 text-yellow-800 shadow-sm hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2"
      onClick={handleCreate}
    ></StateButton>
  );
}

export default WhatsAppTemplateCreateButton;
